<script setup>
import { ref, computed, toRefs, defineProps } from "vue";
import axios from 'axios';
import { useFastWay } from "@/state/modules/fastway";
import Dialog from '@/views/components/Dialog.vue';
import {AutoCompleteComponent} from '@syncfusion/ej2-vue-dropdowns';
import {Query} from '@syncfusion/ej2-data';
import _ from 'lodash';

// eslint-disable-next-line no-undef
const emit = defineEmits(['change']);

const props = defineProps({
  partner: {
      type: Object,
      required: true,
      default: () => ({
        id: 0,
        code: '',
        name: '',
        searchName: '',
        partnerId: 0,
        partnertName: '',
        searchData: []
      })
    },
    id: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: -1
    },
    enabled: {
      type: Boolean,
      default: false
    },
    floatLabelType: {
      type: String,
      default: 'Never'
    },
    limit: {
      type: Number,
      default: 50
    },
    placeholder: {
      type: String,
      default: 'Seleccione persona'
    }
});
const store = useFastWay();
const {partner, enabled, limit, index} = toRefs(props);

if (partner.value.id == 0) {
  partner.value.id = partner.value.partnerId;
}
if (typeof partner.value.name == 'undefined' || partner.value.name == '') {
  partner.value.name = partner.value.partnerName.trim();
}
let autocomplete = ref(null);
let dialog = ref(null);
let query = ref(new Query().select(['name', 'id']).take(limit.value));
let data = ref([]);

const api = computed({
    get() {
      return store.parameters.apiURL;
    }
});

const suggestionCount = computed({
    get() {
      return data.value.length;
    }
});

const filteringData = e => {
  const data = {
    userId: store.userInfo.userId,
    token: store.userInfo.token,
    limit: limit.value,
    startPage: 1,
    searchPhrase: e.text,
    order: 'name'
  }
  partner.value.searchData = [];
  axios.post(`${api.value}/sale/partners/list`, data)
  .then(response => {
    if (response.data.success) {
      if (response.data.data) {
        partner.value.searchData = JSON.parse(JSON.stringify(response.data.data));
      }
      if (e.text == '') {
        e.updateData(partner.value.searchData);
      } else {
        let query = new Query().select(['name', 'id']);
        query = (e.text !== '') ? query.where('name', 'contains', e.text, true, true) : query;
        e.updateData(partner.value.searchData, query);
      }
      setTimeout(() => {
        autocomplete.value.showPopup();
      }, 100)
    } else {
      dialog.value.show('error', 'Error interno al cargar listado de personas', response.data.message);
    }
  })
  .catch(error => {
    let errorMessage = '';
    if (error.message) {
      errorMessage = error.message;
    } else if (error.response) {
      errorMessage = error.response.data.ErrorMessage
    } else {
      errorMessage = 'Error de conectividad'
    }
    dialog.value.show('error', 'Error al cargar listado de personas', errorMessage);
  });
}
let filtering = ref(_.debounce(filteringData, 400));

const onPartnerChange = args => {
  if (args.itemData) {
    if ('name' in args.itemData) {
      partner.value.id = args.itemData.id;
      partner.value.partnerId = args.itemData.id;
      partner.value.name = args.itemData.name;
      partner.value.partnerName = args.itemData.name;
    } else if ('id' in args.itemData) {
      let result = [];
      if ('searchData' in partner.value) {
        result = partner.value.searchData.filter(item => item.id == args.itemData.id);
      }
      if (result.length > 0) {
        partner.value.id = result[0].id;
        partner.value.partnerId = result[0].id;
        partner.value.name = result[0].name;
        partner.value.partnerName = result[0].name;
      }
    }
  } else {
    partner.value.partnerId = 0;
    partner.value.name = '';
  }
  partner.value.index = index.value;
  emit('change', partner.value);
}
</script>

<template>
  <AutoCompleteComponent
    ref="autocomplete"
    :value="partner.name"
    :placeholder="placeholder"
    :enabled="enabled"
    :dataSource='partner.searchData'
    :fields="{text: 'name', value: 'id'}"
    :query="query"
    allowFiltering=true
    :filtering='filtering'
    :suggestionCount="suggestionCount"
    :floatLabelType="floatLabelType"
    highlight=true
    noRecordsTemplate="No se encontraron personas"
    minLength=3
    zIndex=5000
    @change="onPartnerChange"
  />
  <Dialog ref="dialog"/>
</template>

<style>
@import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import "../../../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
</style>
