<script setup>
import { ref, computed, onMounted } from "vue";
import { useRouter, useRoute } from 'vue-router';
import axios from 'axios';
import { useLoading } from 'vue-loading-overlay'
import Layout from "@/layouts/mainfw.vue";
import PageHeader from "@/components/page-header";
import { useFastWay } from "@/state/modules/fastway";
import Dialog from '@/views/components/Dialog.vue';
import Confirm from '@/views/components/Confirm.vue';
import { Modal } from 'bootstrap';
import { TextBoxComponent } from "@syncfusion/ej2-vue-inputs";
import { DropDownListComponent } from "@syncfusion/ej2-vue-dropdowns";
import { MultiSelectComponent } from "@syncfusion/ej2-vue-dropdowns";
import { Query } from '@syncfusion/ej2-data';

const router = useRouter();
const route = useRoute();
const store = useFastWay();
const loader = useLoading();
let id = ref(0);
let loaded = ref(false);
let editing = ref(false);
let dialog = ref(null);
let dialogDelete = ref(null);
let changePasswordDialog = ref(null);
let changePasswordDialogObj = null;
let confirm = ref(null);
let data = ref(null);
let organizations = ref([]);
let statuses = ref([]);
let roles = ref([]);
let query = ref(new Query().select(['name', 'id']).take(50));
let data_local = ref({
  id: 0,
  user: '',
  name: '',
  email: '',
  comments: '',
  organizationId: 0,
  statusId: 'ACT',
  rolList: []
});
let newPassword = ref('');
let confirmPassword = ref('');
const title = "Usuarios";
const items = [
  {
    text: "Infraestructura",
    href: "/",
  },
  {
    text: "Usuarios",
    href: "/infra/users",
  },
  {
    text: "Usuario",
    active: true,
  }
];

const api = computed({
    get() {
      return store.parameters.apiURL;
    }
});

onMounted(async () => {
  changePasswordDialogObj = new Modal(changePasswordDialog.value);
  id.value = parseInt(route.params.id);
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Cargando catálogos'});
  const params = {
    userId: store.userInfo.userId,
    token: store.userInfo.token,
    limit: 100000,
    startPage: 1,
    order: 'name',
    orderDirection: 'ascending'
  };
  await axios
  .post(`${api.value}/organizations/list`, params)
  .then(response => {
    if (response.data.success) {
      organizations.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar empresas', response.data.message);
    }
    return axios
      .post(`${api.value}/lists/get_list`, {
        userId: store.userInfo.userId,
        token: store.userInfo.token,
        list: 'ESTADOUSUARIO'
      });
  })
  .then(response => {
    if (response.data.success) {
      statuses.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar estados de usuario', response.data.message);
    }
    return axios
      .post(`${api.value}/roles/list`, params);
  })
  .then(response => {
    if (response.data.success) {
      roles.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar roles', response.data.message);
    }
    getData(id.value);
    loading.hide();
  })
  .catch(error => {
      let errorMessage = '';
      if (error.message) {
        errorMessage = error.message;
      } else if (error.response) {
        errorMessage = error.response.data.ErrorMessage;
      } else {
        errorMessage = 'Error de conectividad al cargar catálogo';
      }
      loading.hide();
      dialog.value.show('error', 'Error al cargar catálogos', errorMessage);
  });
});

const getData = async (id) => {
  if (id == '0') {
    loaded.value = true;
    editing.value = true;
  } else {
    const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Cargando datos de usuario'});
    await axios
    .post(`${api.value}/users/data`, {id, context: store.userInfo})
    .then(response => {
      if (response.data.success) {
        if (response.data.data) {
          data.value = response.data.data;
          data_local.value = JSON.parse(JSON.stringify(response.data.data));
        }
      } else {
        dialog.value.show('error', 'Error al cargar usuario', response.data.message);
      }
      loaded.value = true;
      loading.hide();
    })
    .catch(error => {
      loaded.value = true;
      loading.hide();
      dialog.value.show('error', `Error al cargar usuario ${id}`, error.message);
    });
  }
};

const resetData = () => {
  if (id.value == '0') {
    router.push({name: 'users'});
  } else {
    data_local.value = JSON.parse(JSON.stringify(data.value));
    editing.value = false;
  }
};

const organizationFiltering = e => {
  let query = new Query().select(['name', 'id']);
  query = (e.text !== '') ? query.where('name', 'contains', e.text, true, true) : query;
  e.updateData(organizations.value, query);
}

const showChangePassword = () => {
  changePasswordDialogObj.show();
}

const acceptChangePassword = () => {
  if (newPassword.value.length >= 6 && newPassword.value == confirmPassword.value) {
    const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Asignando clave a usuario'});
    data_local.value.id = id.value;
    data_local.value.password = newPassword.value;
    data_local.value.context = store.userInfo;
    data_local.value.createdBy = store.userInfo.userId;
    axios
    .post(`${api.value}/users/change_password`, data_local.value)
    .then(response => {
      loading.hide();
      if (response.data.success) {
        dialog.value.show('info', `Cambio de clave`, 'La clave para el usuario ha sido cambiada');
      } else {
        dialog.value.show('error', 'Error al cambiar clave', response.data.message);
      }
    })
    .catch(error => {
      loading.hide();
      dialog.value.show('error',  `Error al cambiar clave para el usuario`, error.message);
    });
  }
}

const validateForm = () => {
  let validated = false;
  if (!data_local.value.user || data_local.value.user == '') {
    dialog.value.show('error', 'Error al guardar usuario', 'Debe especificar un código');
  } else if (!data_local.value.name || data_local.value.name == '') {
    dialog.value.show('error', 'Error al guardar usuario', 'Debe especificar nombre del usuario');
  } else if (!data_local.value.rolList || data_local.value.rolList.length <= 0) {
    dialog.value.show('error', 'Debe indicar por lo menos un rol para el usuario');
  } else {
    validated = true;
  }
  return validated;
};

const save = async () => {
  if (validateForm()) {
    const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Guardando datos de usuario'});
    data_local.value.id = id.value;
    data_local.value.context = store.userInfo;
    data_local.value.createdBy = store.userInfo.userId;
    await axios
    .post(`${api.value}/users/save`, data_local.value)
    .then(response => {
      loading.hide();
      if (response.data.success) {
        data.value = JSON.parse(JSON.stringify(data_local.value));
        editing.value = false;
        dialog.value.show('success', 'Información', 'Datos han sido actualizados');
        if (id.value== 0) {
          router.push({
            name: 'user',
            params: {id: response.data.data.id}
          });
        }
      } else {
        dialog.value.show('error', 'Error al actualizar', response.data.message);
      }
    })
    .catch(error => {
      loading.hide();
      dialog.value.show('error',  `Error al actualizar usuario ${id.value}`, error.message);
    });
  }
};

const remove = async () => {
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Eliminando usuario'});
  const parms = {
    id: id.value,
    context: store.userInfo,
    createdBy: store.userInfo.userId,
    updateFunction: 1
  };
  await axios
  .post(`${api.value}/users/delete`, parms)
  .then(response => {
    loading.hide();
    if (response.data.success) {
      dialogDelete.value.show('success', 'Información', 'Usuario fue eliminado correctamente');
    } else {
      dialog.value.show('error', 'Error al eliminar', response.data.message);
    }
  })
  .catch(error => {
    loading.hide();
    dialog.value.show('error', `Error al eliminar usuario ${id.value}`, error.message);
  });
};

const add = () => {
  router.push({
    name: 'user',
    params: {id: 0}
  });
  id.value = 0;
  data_local.value = {
    id: 0,
    user: '',
    name: '',
    email: '',
    comments: '',
    storeId: '',
    statusId: 'ACT',
    rolList: ''
  };
  editing.value = true;
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
     <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center align-middle row ms-0 me-0 mb-2">
                        <div v-if="!editing" class="col d-flex justify-content-start">
                          <b-button variant="success" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Agregar usuario" @click="add"><i class="las la-plus label-icon"></i> Agregar</b-button>
                        </div>
                        <div class="col"/>
                        <div class="input-group col justify-content-end">
                          <b-button v-if="editing" variant="primary" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Guardar cambios efectuados" @click="save"><i class="las la-save label-icon"></i> Guardar</b-button>
                          <b-button v-if="editing" variant="warning" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Descartar cambios efectuados" @click="resetData"><i class="las la-undo label-icon"></i> Cancelar</b-button>
                          <b-button v-if="!editing" variant="primary" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Editar información" @click="editing = true"><i class="las la-edit label-icon"></i> Editar</b-button>
                          <b-button v-if="!editing" variant="danger" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Eliminar usuario" @click="confirm.show('Confirme', '¿Está seguro de eliminar este usuario?')"><i class="las la-trash label-icon"></i> Eliminar</b-button>
                          <b-button v-if="!editing" variant="success" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Asignar clave" @click="showChangePassword"><i class="las la-key label-icon"></i> Asignar clave</b-button>
                        </div>
                    </div>
                    <hr>
                    <div class="row mb-4">
                      <div class="col-4">
                        <div class="form-floating">
                          <input type="text" class="form-control form-control-lg" :class="{'bg-light': !editing, 'bg-white': editing}" id="role" v-model="data_local.user" placeholder="Código" :disabled="!editing"/>
                          <label for="role">Código</label>
                        </div>
                      </div>
                    </div>
                    <div class="mb-1>">
                      <TextBoxComponent v-model:value="data_local.name" floatLabelType="Auto" placeholder="Nombre" :disabled="!editing"/>
                    </div>
                    <div class="mb-1>">
                      <TextBoxComponent v-model:value="data_local.email" floatLabelType="Auto" placeholder="Dirección de correo electrónico" :disabled="!editing"/>
                    </div>
                    <div class="row mb-3">
                      <div class="col-8">
                        <DropDownListComponent
                            v-model:value="data_local.organizationId"
                            floatLabelType="Auto"
                            placeholder="Empresa"
                            highlight=true
                            :dataSource="organizations"
                            :fields="{text: 'name', value: 'id'}"
                            :query='query'
                            allowFiltering=true
                            :filtering='organizationFiltering'
                            :showClearButton='true'
                            :enabled="editing"
                          />
                      </div>
                      <div class="col">
                        <DropDownListComponent
                          v-model:value="data_local.statusId"
                          floatLabelType="Auto"
                          placeholder="Estado"
                          highlight=true
                          :dataSource="statuses"
                          :fields="{text: 'value', value: 'code'}"
                          :enabled="editing"
                        />
                      </div>
                    </div>
                    <div>
                      <MultiSelectComponent
                        v-model:value="data_local.rolList"
                        floatLabelType="Auto"
                        placeholder="Roles asignados"
                        :dataSource="roles"
                        mode="Box"
                        :fields="{text: 'searchField', value: 'id'}"
                        :enabled="editing"
                      />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Dialog ref="dialog"/>
    <Dialog ref="dialogDelete" @accept="router.push({name: 'users'});"/>
    <Confirm ref="confirm" @accept="remove"/>
    <div
      class="modal fade"
      ref="changePasswordDialog"
      id="changePasswordDialog"
      tabindex="-1"
      aria-labelledby="changePasswordDialogLabel"
      aria-hidden="true"
      style="display: none"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="changePasswordDialogLabel">Cambio de clave para usuario {{ data_local.user }}</h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="form-floating mb-2">
              <input type="password" class="form-control" :class="{'is-invalid': newPassword.length < 6}" v-model="newPassword" id="newPassword" placeholder="Nueva clave">
              <label for="newPassword">Nueva clave</label>
              <div v-if="newPassword.length < 6" class="invalid-feedback">
                Debe especificar una clave de por lo menos 6 caracteres
              </div>
            </div>
            <div class="form-floating">
              <input type="password" class="form-control" :class="{'is-invalid': confirmPassword != newPassword}" v-model="confirmPassword" id="confirmPassword" placeholder="Confirme clave">
              <label for="newPasconfirmPasswordword">Confirme clave</label>
              <div v-if="confirmPassword != newPassword" class="invalid-feedback">
                Nueva clave y confirmación no coinciden
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button v-if="newPassword.length >= 6 && confirmPassword == newPassword" type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="acceptChangePassword">
              Aceptar
            </button>
            <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-inputs/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";

.e-input-group .e-input[disabled], .e-input-group.e-control-wrapper .e-input[disabled], .e-input-group.e-disabled, .e-input-group.e-control-wrapper.e-disabled, .e-float-input input[disabled], .e-float-input.e-control-wrapper input[disabled], .e-float-input textarea[disabled], .e-float-input.e-control-wrapper textarea[disabled], .e-float-input.e-disabled, .e-float-input.e-control-wrapper.e-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.75);
    color: rgba(0, 0, 0, 0.75);
}
</style>