<script setup>
import { ref, computed, onMounted } from "vue";
import { useRouter, useRoute } from 'vue-router';
import axios from 'axios';
import { useLoading } from 'vue-loading-overlay'
import Layout from "@/layouts/mainfw.vue";
import PageHeader from "@/components/page-header";
import { useFastWay } from "@/state/modules/fastway";
import Dialog from '@/views/components/Dialog.vue';
import Confirm from '@/views/components/Confirm.vue';
import { TextBoxComponent } from "@syncfusion/ej2-vue-inputs";
import { NumericTextBoxComponent } from "@syncfusion/ej2-vue-inputs";
import { DropDownListComponent } from "@syncfusion/ej2-vue-dropdowns";
import { CheckBoxComponent } from "@syncfusion/ej2-vue-buttons";
import { Query } from '@syncfusion/ej2-data';

const router = useRouter();
const route = useRoute();
const store = useFastWay();
const loader = useLoading();
let title = "Personas";
let titleS = "Persona";
let urlSection = 'partners';
let urlSectionItem = 'partner';
if ('partnerType' in route.meta) {
  if (route.meta.partnerType == 'c') {
    title = 'Clientes';
    titleS = 'Cliente';
    urlSection = 'customers';
    urlSectionItem = 'customer';
  } else if (route.meta.partnerType == 'v') {
    title = 'Vendedores';
    titleS = 'Vendedor';
    urlSection = 'salesmen';
    urlSectionItem = 'salesman';
  } else if (route.meta.partnerType == 'p') {
    title = 'Proveedores';
    titleS = 'Proveedor';
    urlSection = 'suppliers';
    urlSectionItem = 'supplier';
  }
}
let id = ref(0);
let loaded = ref(false);
let editing = ref(false);
let dialog = ref(null);
let dialogDelete = ref(null);
let confirm = ref(null);
let data = ref(null);
let personTypes = ref([]);
let customerTypes = ref([]);
let supplierTypes = ref([]);
let users = ref([]);
let priceLists = ref([]);
let countrySelector = ref(null);
let stateSelector = ref(null);
let queryCountry = ref(null);
let queryState = ref(null);
let data_local = ref({
  id: 0,
  partnerType: '',
  customerType: '',
  supplierType: '',
  name: '',
  address: '',
  countryId: 1,
  stateId: 0,
  cityId: 0,
  zipCode: '',
  email: '',
  phones: '',
  vat: 'CF',
  isCustomer: false,
  isSalesman: false,
  isSupplier: false,
  customerCode: '',
  salesmanCode: '',
  suppliercode: '',
  salesmanUserId: 0,
  priceListId: 0,
  withholdISR: false,
  withholdIVA: false,
  smallTaxPayer: false,
  creditDays: 0,
  creditLimit: 0,
  comments: ''
});
const viewParms = computed({
  get() {
    return store.viewParameters.partners;
  }
});
const items = viewParms.value.breadcrumb ? viewParms.value.breadcrumb : [
  {
    text: "Catálogos",
    href: "/",
  },
  {
    text: title,
    href: `/sales/catalogs/${urlSection}`,
  },
  {
    text: titleS,
    active: true,
  }
];

const api = computed({
    get() {
      return store.parameters.apiURL;
    }
});

const countries = computed({
    get() {
      return store.countries;
    }
});

const states = computed({
    get() {
      return store.states;
    }
});

const cities = computed({
    get() {
      return store.cities;
    }
});

onMounted(async () => {
  id.value = parseInt(route.params.id);
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Cargando catálogos'});
  await axios
  .post(`${api.value}/lists/get_list`, {
    userId: store.userInfo.userId,
    token: store.userInfo.token,
    list: 'TIPOPERSONA'
  })
  .then(response => {
    if (response.data.success) {
      personTypes.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar tipos de personas', response.data.message);
    }
    return axios
      .post(`${api.value}/lists/get_list`, {
        userId: store.userInfo.userId,
        token: store.userInfo.token,
        list: 'TIPOCLIENTE'
      });
  })
  .then(response => {
    if (response.data.success) {
      customerTypes.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar tipos de cliente', response.data.message);
    }
    return axios
      .post(`${api.value}/lists/get_list`, {
        userId: store.userInfo.userId,
        token: store.userInfo.token,
        list: 'TIPOPROVEEDOR'
      });
  })
  .then(response => {
    if (response.data.success) {
      supplierTypes.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar tipos de proveedor', response.data.message);
    }
    return axios
      .post(`${api.value}/users/organization_list`, {userId: store.userInfo.userId, token: store.userInfo.token, organizationId: store.userInfo.organizationId});
  })
  .then(response => {
    if (response.data.success) {
      users.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar lista de usuarios', response.data.message);
    }
    return axios
      .post(`${api.value}/sale/price_lists/list`, {
        userId: store.userInfo.userId,
        token: store.userInfo.token
      });
  })
  .then(response => {
    if (response.data.success) {
      priceLists.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar listas de precios', response.data.message);
    }
    getData(id.value);
    loading.hide();
  })
  .catch(error => {
      let errorMessage = '';
      if (error.message) {
        errorMessage = error.message;
      } else if (error.response) {
        errorMessage = error.response.data.ErrorMessage;
      } else {
        errorMessage = 'Error de conectividad al cargar catálogo';
      }
      loading.hide();
      dialog.value.show('error', 'Error al cargar catálogos', errorMessage);
  });
});

const getData = async (id) => {
  if (id == '0') {
    loaded.value = true;
    editing.value = true;
  } else {
    const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Cargando datos de persona'});
    await axios
    .post(`${api.value}/sale/partners/data`, {id, context: store.userInfo})
    .then(response => {
      if (response.data.success) {
        if (response.data.data) {
          data.value = response.data.data;
          data_local.value = JSON.parse(JSON.stringify(response.data.data));
        }
      } else {
        dialog.value.show('error', 'Error interno al cargar persona', response.data.message);
      }
      loaded.value = true;
      loading.hide();
    })
    .catch(error => {
      loaded.value = true;
      loading.hide();
      dialog.value.show('error', `Error al cargar persona ${id}`, error.message);
    });
  }
};

const resetData = () => {
  if (id.value == '0') {
    router.push({name: 'partner'});
  } else {
    data_local.value = JSON.parse(JSON.stringify(data.value));
    editing.value = false;
  }
};

const onCountryChange = () => {
  queryCountry.value = new Query().where('countryId', 'equal', countrySelector.value.ej2Instances.value);
}

const onStateChange = () => {
  queryState.value = new Query().where('stateId', 'equal', stateSelector.value.ej2Instances.value);
}

const validateForm = () => {
  let validated = false;
  if (!data_local.value.vat || data_local.value.vat == '') {
    dialog.value.show('error', 'Error al guardar persona', 'Debe especificar un NIT');
  } else if (!data_local.value.name || data_local.value.name == '') {
    dialog.value.show('error', 'Error al guardar persona', 'Debe especificar nombre de la persona');
  } else {
    validated = true;
  }
  return validated;
};

const save = async () => {
  if (validateForm()) {
    const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Guardando datos de persona'});
    data_local.value.id = id.value;
    data_local.value.context = store.userInfo;
    data_local.value.createdBy = store.userInfo.userId;
    await axios
    .post(`${api.value}/sale/partners/save`, data_local.value)
    .then(response => {
      loading.hide();
      if (response.data.success) {
        data.value = JSON.parse(JSON.stringify(data_local.value));
        editing.value = false;
        dialog.value.show('success', 'Información', 'Datos han sido actualizados');
        if (id.value== 0) {
          router.push({
            name: urlSectionItem,
            params: {id: response.data.data.id}
          });
        }
      } else {
        dialog.value.show('error', 'Error al actualizar', response.data.message);
      }
    })
    .catch(error => {
      loading.hide();
      dialog.value.show('error',  `Error al actualizar persona ${id.value}`, error.message);
    });
  }
};

const remove = async () => {
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Eliminando persona'});
  const parms = {
    id: id.value,
    context: store.userInfo,
    createdBy: store.userInfo.userId
  };
  await axios
  .post(`${api.value}/sale/partners/delete`, parms)
  .then(response => {
    loading.hide();
    if (response.data.success) {
      dialogDelete.value.show('success', 'Información', 'Persona fue eliminada correctamente');
    } else {
      dialog.value.show('error', 'Error al eliminar', response.data.message);
    }
  })
  .catch(error => {
    loading.hide();
    dialog.value.show('error', `Error al eliminar persona ${id.value}`, error.message);
  });
};

const add = () => {
  router.push({
    name: urlSectionItem,
    params: {id: 0}
  });
  id.value = 0;
  data_local.value = {
    id: 0,
    partnerType: '',
    customerType: '',
    supplierType: '',
    name: '',
    address: '',
    countryId: 0,
    stateId: 0,
    cityId: 0,
    zipCode: '',
    email: '',
    phones: '',
    vat: 'CF',
    isCustomer: false,
    isSalesman: false,
    isSupplier: false,
    customerCode: '',
    salesmanCode: '',
    suppliercode: '',
    salesmanUserId: 0,
    priceListId: 0,
    withholdISR: false,
    withholdIVA: false,
    smallTaxPayer: false,
    creditDays: 0,
    creditLimit: 0,
    comments: ''
  };
  editing.value = true;
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
     <div class="row">
        <div class="col-lg-12">
          <div class="card">
              <div class="card-body">
                  <div class="d-flex justify-content-between align-items-center align-middle row ms-0 me-0 mb-2">
                      <div v-if="!editing" class="col d-flex justify-content-start">
                        <b-button variant="success" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Agregar usuario" @click="add"><i class="las la-plus label-icon"></i> Agregar</b-button>
                      </div>
                      <div class="col"/>
                      <div class="input-group col justify-content-end">
                        <b-button v-if="editing" variant="primary" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Guardar cambios efectuados" @click="save"><i class="las la-save label-icon"></i> Guardar</b-button>
                        <b-button v-if="editing" variant="warning" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Descartar cambios efectuados" @click="resetData"><i class="las la-undo label-icon"></i> Cancelar</b-button>
                        <b-button v-if="!editing" variant="primary" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Editar información" @click="editing = true"><i class="las la-edit label-icon"></i> Editar</b-button>
                        <b-button v-if="!editing" variant="danger" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Eliminar producto" @click="confirm.show('Confirme', '¿Está seguro de eliminar este producto?')"><i class="las la-trash label-icon"></i> Eliminar</b-button>
                      </div>
                  </div>
                  <hr>
                  <div class="row mb-1">
                    <div class="col-2 me-1">
                      <DropDownListComponent
                        v-model:value="data_local.personType"
                        floatLabelType="Auto"
                        placeholder="Tipo de persona"
                        highlight=true
                        :dataSource="personTypes"
                        :fields="{text: 'value', value: 'code'}"
                        :enabled="editing"
                      />
                    </div>
                    <div class="col-3 me-1">
                      <TextBoxComponent v-model:value="data_local.vat" floatLabelType="Auto" placeholder="NIT" :disabled="!editing"/>
                    </div>
                  </div>
                  <div class="mb-1>">
                    <TextBoxComponent v-model:value="data_local.name" floatLabelType="Auto" placeholder="Nombre" :disabled="!editing"/>
                  </div>
                  <div class="row mb-1">
                    <div class="col-2 mt-3 me-1">
                      <CheckBoxComponent label="Cliente" v-model="data_local.isCustomer" :checked="data_local.isCustomer" :disabled="!editing" />
                    </div>
                    <div class="col-2 mt-3 me-1">
                      <CheckBoxComponent label="Vendedor" v-model="data_local.isSalesman" :checked="data_local.isSalesman" :disabled="!editing" />
                    </div>
                    <div class="col-2 mt-3 me-1">
                      <CheckBoxComponent label="Proveedor" v-model="data_local.isSupplier" :checked="data_local.isSupplier" :disabled="!editing" />
                    </div>
                  </div>
                  <ul class="nav nav-pills arrow-navtabs nav-success bg-light mt-3 mb-2" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active" data-bs-toggle="tab" href="#general-information" role="tab">
                        Datos generales
                      </a>
                    </li>
                    <li class="nav-item" v-show="data_local.isCustomer">
                      <a class="nav-link" data-bs-toggle="tab" href="#customer-information" role="tab">
                        Datos de cliente
                      </a>
                    </li>
                    <li class="nav-item" v-show="data_local.isSalesman">
                      <a class="nav-link" data-bs-toggle="tab" href="#salesman-information" role="tab">
                        Datos de vendedor
                      </a>
                    </li>
                    <li class="nav-item" v-show="data_local.isSupplier">
                      <a class="nav-link" data-bs-toggle="tab" href="#supplier-information" role="tab">
                        Datos de proveedor
                      </a>
                    </li>
                  </ul>
                  <div class="tab-content text-muted">
                    <div class="tab-pane active px-2" id="general-information" role="tabpanel">
                      <div class="mb-1>">
                        <TextBoxComponent v-model:value="data_local.address" floatLabelType="Auto" placeholder="Dirección" :disabled="!editing"/>
                      </div>
                      <div class="row mb-1">
                        <div class="col-3 me-1">
                          <DropDownListComponent
                            ref="countrySelector"
                            v-model:value="data_local.countryId"
                            floatLabelType="Auto"
                            placeholder="Pais"
                            highlight=true
                            :dataSource="countries"
                            :fields="{text: 'name', value: 'id'}"
                            :enabled="editing"
                            :change="onCountryChange"
                          />
                        </div>
                        <div class="col-3 me-1">
                          <DropDownListComponent
                            ref="stateSelector"
                            v-model:value="data_local.stateId"
                            floatLabelType="Auto"
                            placeholder="Departamento"
                            highlight=true
                            :dataSource="states"
                            :fields="{text: 'name', value: 'id'}"
                            :enabled="editing"
                            :query="queryCountry"
                            :change="onStateChange"
                          />
                        </div>
                        <div class="col-3 me-1">
                          <DropDownListComponent
                            v-model:value="data_local.cityId"
                            floatLabelType="Auto"
                            placeholder="Municipio"
                            highlight=true
                            :dataSource="cities"
                            :fields="{text: 'name', value: 'id'}"
                            :enabled="editing"
                            :query="queryState"
                          />
                        </div>
                        <div class="col-2">
                          <TextBoxComponent v-model:value="data_local.zipCode" floatLabelType="Auto" placeholder="Código postal" :disabled="!editing"/>
                        </div>
                      </div>
                      <div class="row mb-1">
                        <div class="col me-1">
                          <TextBoxComponent v-model:value="data_local.email" floatLabelType="Auto" placeholder="Correo electrónico" :disabled="!editing"/>
                        </div>
                        <div class="col me-1">
                          <TextBoxComponent v-model:value="data_local.phones" floatLabelType="Auto" placeholder="Teléfonos" :disabled="!editing"/>
                        </div>
                        <div class="col me-1">
                          <TextBoxComponent v-model:value="data_local.dpi" floatLabelType="Auto" placeholder="DPI" :disabled="!editing"/>
                        </div>
                        <div class="mb-3">
                          <TextBoxComponent v-model:value="data_local.comments" floatLabelType="Auto" placeholder="Comentarios" :enabled="editing" :multiline="true"/>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane px-2" id="customer-information" role="tabpanel">
                      <div class="row mb-1">
                        <div class="col-2 me-1">
                          <DropDownListComponent
                            v-model:value="data_local.customerType"
                            floatLabelType="Auto"
                            placeholder="Tipo de cliente"
                            highlight=true
                            :dataSource="customerTypes"
                            :fields="{text: 'value', value: 'code'}"
                            :enabled="editing"
                          />
                        </div>
                        <div class="col-2 me-1">
                          <TextBoxComponent v-model:value="data_local.customerCode" floatLabelType="Auto" placeholder="Código de cliente" :disabled="!editing"/>
                        </div>
                        <div class="col-2 me-1">
                          <NumericTextBoxComponent v-model:value="data_local.creditDays" floatLabelType="Auto" placeholder="Días de crédito" format="N0" decimals=0 min=0 :enabled="editing"/>
                        </div>
                        <div class="col-2 me-1">
                          <NumericTextBoxComponent v-model:value="data_local.creditLimit" floatLabelType="Auto" placeholder="Límite de crédito" format="N2" decimals=2 min=0 :enabled="editing"/>
                        </div>
                        <div class="col-3">
                          <DropDownListComponent
                            v-model:value="data_local.priceListId"
                            floatLabelType="Auto"
                            placeholder="Lista de precios"
                            highlight=true
                            :dataSource="priceLists"
                            :fields="{text: 'name', value: 'id'}"
                            :enabled="editing"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane px-2" id="salesman-information" role="tabpanel">
                      <div class="row mb-1">
                        <div class="col-3 me-1">
                          <TextBoxComponent v-model:value="data_local.salesmanCode" floatLabelType="Auto" placeholder="Código de vendedor" :disabled="!editing"/>
                        </div>
                        <div class="col-3">
                          <DropDownListComponent
                            v-model:value="data_local.salesmanUserId"
                            floatLabelType="Auto"
                            placeholder="Usuario asignado"
                            highlight=true
                            :dataSource="users"
                            :fields="{text: 'name', value: 'id'}"
                            :enabled="editing"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane px-2" id="supplier-information" role="tabpanel">
                      <div class="row mb-1">
                        <div class="col-3 me-1">
                          <DropDownListComponent
                            v-model:value="data_local.supplierType"
                            floatLabelType="Auto"
                            placeholder="Tipo de proveedor"
                            highlight=true
                            :dataSource="supplierTypes"
                            :fields="{text: 'value', value: 'code'}"
                            :enabled="editing"
                          />
                        </div>
                        <div class="col-3 me-1">
                          <TextBoxComponent v-model:value="data_local.supplierCode" floatLabelType="Auto" placeholder="Código de proveedor" :disabled="!editing"/>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
    <Dialog ref="dialog"/>
    <Dialog ref="dialogDelete" @accept="router.push({name: 'products'});"/>
    <Confirm ref="confirm" @accept="remove"/>
  </Layout>
</template>

<style>
@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-inputs/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";

.e-input-group .e-input[disabled], .e-input-group.e-control-wrapper .e-input[disabled], .e-input-group.e-disabled, .e-input-group.e-control-wrapper.e-disabled, .e-float-input input[disabled], .e-float-input.e-control-wrapper input[disabled], .e-float-input textarea[disabled], .e-float-input.e-control-wrapper textarea[disabled], .e-float-input.e-disabled, .e-float-input.e-control-wrapper.e-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.75);
    color: rgba(0, 0, 0, 0.75);
}
</style>