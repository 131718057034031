<script setup>
import { ref, computed, onMounted } from "vue";
import { useRouter, useRoute } from 'vue-router';
import axios from 'axios';
import { useLoading } from 'vue-loading-overlay'
import Layout from "@/layouts/mainfw.vue";
import PageHeader from "@/components/page-header";
import { useFastWay } from "@/state/modules/fastway";
import Dialog from '@/views/components/Dialog.vue';
import Confirm from '@/views/components/Confirm.vue';
import { TextBoxComponent } from "@syncfusion/ej2-vue-inputs";
import { DropDownListComponent } from "@syncfusion/ej2-vue-dropdowns";

const router = useRouter();
const route = useRoute();
const store = useFastWay();
const loader = useLoading();
let id = ref(0);
let loaded = ref(false);
let editing = ref(false);
let dialog = ref(null);
let dialogDelete = ref(null);
let confirm = ref(null);
let data = ref(null);
let statuses = ref([]);
let data_local = ref({
  id: 0,
  code: '',
  name: '',
  dbName: '',
  dwhName: '',
  comments: '',
  statusId: 'ACT'
});
const title = "Empresas";
const items = [
  {
    text: "Infraestructura",
    href: "/",
  },
  {
    text: "Empresas",
    href: "/infra/organizations",
  },
  {
    text: "Empresa",
    active: true,
  }
];

const api = computed({
    get() {
      return store.parameters.apiURL;
    }
});

onMounted(async () => {
  id.value = parseInt(route.params.id);
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Cargando catálogos'});
  await axios
  .post(`${api.value}/lists/get_list`, {
        userId: store.userInfo.userId,
        token: store.userInfo.token,
        list: 'ESTADOEMPRESA'
  })
  .then(response => {
    if (response.data.success) {
      statuses.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar estados de empresa', response.data.message);
    }
    getData(id.value);
    loading.hide();
  })
  .catch(error => {
      let errorMessage = '';
      if (error.message) {
        errorMessage = error.message;
      } else if (error.response) {
        errorMessage = error.response.data.ErrorMessage;
      } else {
        errorMessage = 'Error de conectividad al cargar catálogo';
      }
      loading.hide();
      dialog.value.show('error', 'Error al cargar catálogos', errorMessage);
  });
});

const getData = async (id) => {
  if (id == '0') {
    loaded.value = true;
    editing.value = true;
  } else {
    const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Cargando datos de empresa'});
    await axios
    .post(`${api.value}/organizations/data`, {id, context: store.userInfo})
    .then(response => {
      if (response.data.success) {
        if (response.data.data) {
          data.value = response.data.data;
          data_local.value = JSON.parse(JSON.stringify(response.data.data));
        }
      } else {
        dialog.value.show('error', 'Error al cargar empresa', response.data.message);
      }
      loaded.value = true;
      loading.hide();
    })
    .catch(error => {
      loaded.value = true;
      loading.hide();
      dialog.value.show('error', `Error al cargar empresa ${id}`, error.message);
    });
  }
};

const resetData = () => {
  if (id.value == '0') {
    router.push({name: 'organizations'});
  } else {
    data_local.value = JSON.parse(JSON.stringify(data.value));
    editing.value = false;
  }
};

const validateForm = () => {
  let validated = false;
  if (!data_local.value.code || data_local.value.code == '') {
    dialog.value.show('error', 'Error al guardar empresa', 'Debe especificar un código');
  } else if (!data_local.value.name || data_local.value.name == '') {
    dialog.value.show('error', 'Error al guardar empresa', 'Debe especificar nombre de la empresa');
  } else if (!data_local.value.dbName || data_local.value.dbName == '') {
    dialog.value.show('error', 'Debe indicar el nombre de la base de datos de la empresa');
  } else {
    validated = true;
  }
  return validated;
};

const save = async () => {
  if (validateForm()) {
    const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Guardando datos de empresa'});
    data_local.value.id = id.value;
    data_local.value.context = store.userInfo;
    data_local.value.createdBy = store.userInfo.userId;
    await axios
    .post(`${api.value}/organizations/save`, data_local.value)
    .then(response => {
      loading.hide();
      if (response.data.success) {
        data.value = JSON.parse(JSON.stringify(data_local.value));
        editing.value = false;
        dialog.value.show('success', 'Información', 'Datos han sido actualizados');
        if (id.value== 0) {
          router.push({
            name: 'organization',
            params: {id: response.data.data.id}
          });
        }
      } else {
        dialog.value.show('error', 'Error al actualizar', response.data.message);
      }
    })
    .catch(error => {
      loading.hide();
      dialog.value.show('error',  `Error al actualizar empresa ${id.value}`, error.message);
    });
  }
};

const remove = async () => {
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Eliminando empresa'});
  const parms = {
    id: id.value,
    context: store.userInfo,
    createdBy: store.userInfo.userId,
    updateFunction: 1
  };
  await axios
  .post(`${api.value}/organizations/delete`, parms)
  .then(response => {
    loading.hide();
    if (response.data.success) {
      dialogDelete.value.show('success', 'Información', 'Empresa fue eliminada correctamente');
    } else {
      dialog.value.show('error', 'Error al eliminar', response.data.message);
    }
  })
  .catch(error => {
    loading.hide();
    dialog.value.show('error', `Error al eliminar empresa ${id.value}`, error.message);
  });
};

const add = () => {
  router.push({
    name: 'organization',
    params: {id: 0}
  });
  id.value = 0;
  data_local.value = {
    id: 0,
    code: '',
    name: '',
    dbName: '',
    dwhName: '',
    comments: '',
    statusId: 'ACT'
  };
  editing.value = true;
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
     <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center align-middle row ms-0 me-0 mb-2">
                        <div v-if="!editing" class="col d-flex justify-content-start">
                          <b-button variant="success" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Agregar usuario" @click="add"><i class="las la-plus label-icon"></i> Agregar</b-button>
                        </div>
                        <div class="col"/>
                        <div class="input-group col justify-content-end">
                          <b-button v-if="editing" variant="primary" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Guardar cambios efectuados" @click="save"><i class="las la-save label-icon"></i> Guardar</b-button>
                          <b-button v-if="editing" variant="warning" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Descartar cambios efectuados" @click="resetData"><i class="las la-undo label-icon"></i> Cancelar</b-button>
                          <b-button v-if="!editing" variant="primary" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Editar información" @click="editing = true"><i class="las la-edit label-icon"></i> Editar</b-button>
                          <b-button v-if="!editing" variant="danger" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Eliminar empresa" @click="confirm.show('Confirme', '¿Está seguro de eliminar esta empresa?')"><i class="las la-trash label-icon"></i> Eliminar</b-button>
                        </div>
                    </div>
                    <hr>
                    <div class="row mb-4">
                      <div class="col-4">
                        <div class="form-floating">
                          <input type="text" class="form-control form-control-lg" :class="{'bg-light': !editing, 'bg-white': editing}" id="role" v-model="data_local.code" placeholder="Código" :disabled="!editing"/>
                          <label for="role">Código</label>
                        </div>
                      </div>
                    </div>
                    <div class="mb-2">
                      <TextBoxComponent v-model:value="data_local.name" floatLabelType="Auto" placeholder="Nombre" :disabled="!editing"/>
                    </div>
                    <div class="row mb-1">
                      <div class="col-4">
                        <TextBoxComponent v-model:value="data_local.dbName" floatLabelType="Auto" placeholder="Base de datos" :disabled="!editing"/>
                      </div>
                      <div class="col-4">
                        <TextBoxComponent v-model:value="data_local.dwhName" floatLabelType="Auto" placeholder="Almacén de datos" :disabled="!editing"/>
                      </div>
                      <div class="col">
                        <DropDownListComponent
                          v-model:value="data_local.statusId"
                          floatLabelType="Auto"
                          placeholder="Estado"
                          highlight=true
                          :dataSource="statuses"
                          :fields="{text: 'value', value: 'code'}"
                          :enabled="editing"
                        />
                      </div>
                    </div>
                    <div class="mb-3">
                      <TextBoxComponent v-model:value="data_local.comments" floatLabelType="Auto" placeholder="Comentarios" :enabled="editing" :multiline="true"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Dialog ref="dialog"/>
    <Dialog ref="dialogDelete" @accept="router.push({name: 'organizations'});"/>
    <Confirm ref="confirm" @accept="remove"/>
  </Layout>
</template>

<style>
@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-inputs/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";

.e-input-group .e-input[disabled], .e-input-group.e-control-wrapper .e-input[disabled], .e-input-group.e-disabled, .e-input-group.e-control-wrapper.e-disabled, .e-float-input input[disabled], .e-float-input.e-control-wrapper input[disabled], .e-float-input textarea[disabled], .e-float-input.e-control-wrapper textarea[disabled], .e-float-input.e-disabled, .e-float-input.e-control-wrapper.e-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.75);
    color: rgba(0, 0, 0, 0.75);
}
</style>