<script setup>
// eslint-disable-next-line no-undef
const props = defineProps({ params: Object })

const variant = item => {
  let variant = 'info';
  if (item.trim().toLowerCase().substring(0, 5) == 'activ') {
    variant = 'success';
  } else if (item.trim().toLowerCase().substring(0, 5) == 'inact') {
    variant = 'danger';
  }
  return variant;
}
</script>

<template>
  <div>
    <b-badge :variant="variant(props.params.value)">{{ props.params.value }}</b-badge>
  </div>
</template>
