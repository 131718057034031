<script setup>
import { ref, computed, onMounted } from "vue";
import { useRouter, useRoute } from 'vue-router';
import axios from 'axios';
import { useLoading } from 'vue-loading-overlay'
import Layout from "@/layouts/mainfw.vue";
import PageHeader from "@/components/page-header";
import { useFastWay } from "@/state/modules/fastway";
import Dialog from '@/views/components/Dialog.vue';
import Confirm from '@/views/components/Confirm.vue';
import { TextBoxComponent } from "@syncfusion/ej2-vue-inputs";
import { NumericTextBoxComponent } from "@syncfusion/ej2-vue-inputs";
import {L10n, loadCldr, setCulture} from '@syncfusion/ej2-base';
import numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import gregorian from 'cldr-data/main/es-GT/ca-gregorian.json';
import numbers from 'cldr-data/main/es-GT/numbers.json';
import timeZoneNames from 'cldr-data/main/es-GT/timeZoneNames.json';
import weekData from 'cldr-data/supplemental/weekData.json';
import {DatePickerComponent} from "@syncfusion/ej2-vue-calendars";
import { DropDownListComponent } from "@syncfusion/ej2-vue-dropdowns";
import Pagination from '@/views/components/Pagination.vue';
import ProductSelect from '@/views/components/ProductSelect';

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData);
setCulture('es-GT');
L10n.load({
  'es-GT': {
    datepicker: {
      placeholder: 'Seleccione fecha',
      applyText: 'Seleccionar',
      cancelText: 'Cancelar',
      today: 'Hoy'
    }
  }
});

const router = useRouter();
const route = useRoute();
const store = useFastWay();
const loader = useLoading();
let pagination = ref(null);
let id = ref(0);
let loaded = ref(false);
let editing = ref(false);
let dialog = ref(null);
let dialogDelete = ref(null);
let dialogDuplicate = ref(null);
let confirm = ref(null);
let data = ref(null);
let currencies = ref([]);
let statuses = ref([]);
let data_local = ref({
  id: 0,
  code: '',
  name: '',
  currencyId: 0,
  initialDate: new Date(),
  finalDate: new Date(),
  comments: '',
  statusId: 'ACT',
  products: []
});
let currentPage = ref(1);
let pageSize = ref(15);
let indexDuplicate = -1;

const title = "Lista de precios";
const items = [
  {
    text: "Ventas",
    href: "/",
  },
  {
    text: "Listas de precios",
    href: "/sales/catalogs/price_lists",
  },
  {
    text: "Lista de precios",
    active: true,
  }
];

const api = computed({
    get() {
      return store.parameters.apiURL;
    }
});

const initialItem = computed({
  get() {
    let item = currentPage.value * pageSize.value - (pageSize.value - 1);
    if (item > data_local.value.products.length) {
      item = Math.floor(data_local.value.products.length / pageSize.value);
    }
    return item;
  }
})

const finalItem = computed({
  get() {
    return initialItem.value + pageSize.value - 1;
  }
})

onMounted(async () => {
  id.value = parseInt(route.params.id);
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Cargando catálogos'});
  await axios
  .post(`${api.value}/lists/get_list`, {
    userId: store.userInfo.userId,
    token: store.userInfo.token,
    list: 'ESTADOLISTAPRECIOS'
  })
  .then(response => {
    if (response.data.success) {
      statuses.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar estados de lista de precios', response.data.message);
    }
    return axios
      .post(`${api.value}/infra/currencies/list`, {
        userId: store.userInfo.userId,
        token: store.userInfo.token
      });
  })
  .then(response => {
    if (response.data.success) {
      currencies.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar monedas', response.data.message);
    }
    getData(id.value);
    loading.hide();
  })
  .catch(error => {
      let errorMessage = '';
      if (error.message) {
        errorMessage = error.message;
      } else if (error.response) {
        errorMessage = error.response.data.ErrorMessage;
      } else {
        errorMessage = 'Error de conectividad al cargar catálogo';
      }
      loading.hide();
      dialog.value.show('error', 'Error al cargar catálogos', errorMessage);
  });
});

const getData = async (id) => {
  if (id == '0') {
    loaded.value = true;
    editing.value = true;
  } else {
    const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Cargando datos de lista de precios'});
    await axios
    .post(`${api.value}/sale/price_lists/data`, {id, context: store.userInfo})
    .then(response => {
      if (response.data.success) {
        if (response.data.data) {
          data.value = response.data.data;
          data_local.value = JSON.parse(JSON.stringify(response.data.data));
          if (data_local.value.products == null) {
            data_local.value.products = [];
          }
          pagination.value.setTotalItems(data_local.value.products.length);
        }
      } else {
        dialog.value.show('error', 'Error interno al cargar lista de precios', response.data.message);
      }
      loaded.value = true;
      loading.hide();
    })
    .catch(error => {
      loaded.value = true;
      loading.hide();
      dialog.value.show('error', `Error al cargar lista de precios ${id}`, error.message);
    });
  }
};

const resetData = () => {
  if (id.value == '0') {
    router.push({name: 'priceLists'});
  } else {
    data_local.value = JSON.parse(JSON.stringify(data.value));
    editing.value = false;
  }
};

const deleteLine = index => {
  if (editing.value) {
    if (index < data_local.value.products.length) {
      data_local.value.products.splice(index, 1);
    }
    pagination.value.setTotalItems(data_local.value.products.length);
    const page = Math.ceil(data_local.value.products.length / pageSize.value);
    if (page < currentPage.value) {
      currentPage.value = page;
    }
  }
}

const addLine = () => {
  if (editing.value) {
    let id = 1;
    if (data_local.value.products == null) {
      data_local.value.products = [];
    }
    if (data_local.value.products && data_local.value.products > 0) {
      const max = data_local.value.products.reduce((prev, current) => ((prev.id > current.id) ? prev : current));
      if (max) {
        id = max.id + 1;
      }
    }
    data_local.value.products.push({
      id,
      name: '',
      productId: 0,
      productType: '',
      productTypeName: '',
      productCode: '',
      productName: '',
      productSearch: '',
      searchName: '',
      price: 0,
      searchData: [],
      productList: []
    });
    pagination.value.setTotalItems(data_local.value.products.length);
    const page = Math.ceil(data_local.value.products.length / pageSize.value);
    if (page > currentPage.value) {
      currentPage.value = page;
    }
  }
}

const onProductChange = product => {
  if (product.productId > 0) {
    const line = data_local.value.products.find(p => p.productId == product.productId && p.index != product.index);
    if (line) {
      indexDuplicate = product.index;
      dialogDuplicate.value.show('error', 'Error seleccionando producto', `Producto ${product.productSearch} ya se encuentra en listado`);
    }
  }
}

const errorDuplicate = () => {
  data_local.value.products[indexDuplicate] = {
        id,
        name: '',
        productId: 0,
        productType: '',
        productTypeName: '',
        productCode: '',
        productName: '',
        productSearch: '',
        searchName: '',
        price: 0,
        searchData: [],
        productList: []
      };
}

const pageSizeChange = ps => {
  currentPage.value = 1;
  pageSize.value = ps;
}

const setPage = page => {
  currentPage.value = page;
}

const openProduct = productId => {
  store.viewParameters.products.breadcrumb = [
    {
      text: "Ventas",
      href: "/",
    },
    {
      text: "Listas de precios",
      href: "/sales/catalogs/price_lists",
    },
    {
      text: `Lista de precios ${data_local.value.code}`,
      href: `/sales/catalogs/price_lists/${id.value}`,
    },
    {
      text: "Producto",
      active: true,
    }
  ];
  router.push({name: 'product', params: {id: `${productId}`}})
}

const validateForm = () => {
  let validated = false;
  if (!data_local.value.code || data_local.value.code == '') {
    dialog.value.show('error', 'Error al guardar lista de precios', 'Debe especificar un código');
  } else if (!data_local.value.name || data_local.value.name == '') {
    dialog.value.show('error', 'Error al guardar lista de precios', 'Debe especificar nombre de la lista de precios');
  } else {
    validated = true;
  }
  return validated;
};

const save = async () => {
  if (validateForm()) {
    const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Guardando datos de lista de precios'});
    data_local.value.id = id.value;
    data_local.value.code = data_local.value.code.toUpperCase();
    data_local.value.context = store.userInfo;
    data_local.value.createdBy = store.userInfo.userId;
    await axios
    .post(`${api.value}/sale/price_lists/save`, data_local.value)
    .then(response => {
      loading.hide();
      if (response.data.success) {
        data.value = JSON.parse(JSON.stringify(data_local.value));
        editing.value = false;
        dialog.value.show('success', 'Información', 'Datos han sido actualizados');
        if (id.value== 0) {
          router.push({
            name: 'priceList',
            params: {id: response.data.data.id}
          });
        }
      } else {
        dialog.value.show('error', 'Error al actualizar', response.data.message);
      }
    })
    .catch(error => {
      loading.hide();
      dialog.value.show('error',  `Error al actualizar lista de precios ${id.value}`, error.message);
    });
  }
};

const remove = async () => {
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Eliminando lista de precios'});
  const parms = {
    id: id.value,
    context: store.userInfo,
    createdBy: store.userInfo.userId
  };
  await axios
  .post(`${api.value}/sale/price_lists/delete`, parms)
  .then(response => {
    loading.hide();
    if (response.data.success) {
      dialogDelete.value.show('success', 'Información', 'Lista de precios fue eliminada correctamente');
    } else {
      dialog.value.show('error', 'Error al eliminar', response.data.message);
    }
  })
  .catch(error => {
    loading.hide();
    dialog.value.show('error', `Error al eliminar lista de precios ${id.value}`, error.message);
  });
};

const add = () => {
  router.push({
    name: 'priceList',
    params: {id: 0}
  });
  id.value = 0;
  data_local.value = {
    id: 0,
    code: '',
    name: '',
    currencyId: 0,
    initialDate: new Date(),
    finalDate: new Date(),
    comments: '',
    statusId: 'ACT',
    products: []
  };
  editing.value = true;
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
     <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center align-middle row ms-0 me-0 mb-2">
                        <div v-if="!editing" class="col d-flex justify-content-start">
                          <b-button variant="success" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Agregar usuario" @click="add"><i class="las la-plus label-icon"></i> Agregar</b-button>
                        </div>
                        <div class="col"/>
                        <div class="input-group col justify-content-end">
                          <b-button v-if="editing" variant="primary" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Guardar cambios efectuados" @click="save"><i class="las la-save label-icon"></i> Guardar</b-button>
                          <b-button v-if="editing" variant="warning" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Descartar cambios efectuados" @click="resetData"><i class="las la-undo label-icon"></i> Cancelar</b-button>
                          <b-button v-if="!editing" variant="primary" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Editar información" @click="editing = true"><i class="las la-edit label-icon"></i> Editar</b-button>
                          <b-button v-if="!editing" variant="danger" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Eliminar lista de precios" @click="confirm.show('Confirme', '¿Está seguro de eliminar esta lista de precios?')"><i class="las la-trash label-icon"></i> Eliminar</b-button>
                        </div>
                    </div>
                    <hr>
                    <div class="row mb-4">
                      <div class="col-4">
                        <div class="form-floating">
                          <input type="text" class="form-control form-control-lg" :class="{'bg-light': !editing, 'bg-white': editing}" id="role" v-model="data_local.code" placeholder="Código" :disabled="!editing" style="text-transform: uppercase"/>
                          <label for="role">Código</label>
                        </div>
                      </div>
                    </div>
                    <div class="mb-2>">
                      <TextBoxComponent v-model:value="data_local.name" floatLabelType="Auto" placeholder="Nombre" :disabled="!editing"/>
                    </div>
                    <div class="row mb-1">
                      <div class="col-3 me-1">
                        <DropDownListComponent
                          v-model:value="data_local.currencyId"
                          floatLabelType="Auto"
                          placeholder="Moneda"
                          highlight=true
                          :dataSource="currencies"
                          :fields="{text: 'name', value: 'id'}"
                          :enabled="editing"
                        />
                      </div>
                      <div class="col-3 me-1" style="max-width: 260px;">
                        <DatePickerComponent v-model:value="data_local.initialDate" floatLabelType="Auto" placeholder="Fecha inicial"/>
                      </div>
                      <div class="col-3 me-1" style="max-width: 260px;">
                        <DatePickerComponent v-model:value="data_local.finalDate" floatLabelType="Auto" placeholder="Fecha inicial"/>
                      </div>
                      <div class="col-1">
                        <DropDownListComponent
                          v-model:value="data_local.statusId"
                          floatLabelType="Auto"
                          placeholder="Estado"
                          highlight=true
                          :dataSource="statuses"
                          :fields="{text: 'value', value: 'code'}"
                          :enabled="editing"
                        />
                      </div>
                    </div>
                    <ul class="nav nav-pills arrow-navtabs nav-success bg-light mt-2 mb-3" role="tablist">
                      <li class="nav-item">
                        <a class="nav-link active" data-bs-toggle="tab" href="#products" role="tab">
                          Productos
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#other-info" role="tab">
                          Información adicional
                        </a>
                      </li>
                    </ul>
                    <div class="tab-content text-muted">
                      <div class="tab-pane active px-2" id="products" role="tabpanel">
                        <div class="row mb-3 border bg-light">
                          <div class="col">
                            <div class="row">
                              <div class="col-1 p-2 fs-13 bg-light fw-medium text-center" style="width: 60px;">#</div>
                              <div class="col p-2 border-start fs-12 bg-light fw-medium">Producto</div>
                              <div class="col-2 p-2 border-start fs-12 bg-light fw-medium text-end">Precio</div>
                              <div v-show="editing" class="col-1 border-start border-bottom fs-12 bg-light" style="width: 45px;"></div>
                            </div>
                            <div v-for="(line, index) in data_local.products" :key="line.id">
                              <div v-if="(index + 1) >= initialItem && (index + 1) <= finalItem" class="row">
                                <div class="col-1 px-2 pt-3 fs-13 bg-white align-middle text-end" style="width: 60px;">{{ index + 1 }}</div>
                                <div v-show="editing" class="col p-2 bg-white border-start">
                                  <ProductSelect
                                    :product="data_local.products[index]"
                                    :index="index"
                                    :limit="20"
                                    :enabled="editing"
                                    @change="onProductChange"
                                  />
                                </div>
                                <div v-show="!editing" class="col p-2 bg-white border-start">
                                  <b-button class="mt-1" variant="link" size="sm" v-b-tooltip.hover title="Ver producto" @click="openProduct(line.productId)">
                                    {{ line.searchName }}
                                  </b-button>
                                </div>
                                <div class="col-2 p-2 bg-white border-start">
                                  <NumericTextBoxComponent
                                    v-model:value="line.price"
                                    format="N2"
                                    :enabled="editing"
                                    :showSpinButton='false'
                                    textAlign='right'
                                    :min="0"
                                    cssClass="numericalign"
                                  />
                                </div>
                                <div v-show="editing" class="col-1 border-start bg-light" style="width: 45px;">
                                  <b-button variant="danger" size="sm" class="btn-icon waves-effect waves-light mt-2" v-b-tooltip.hover title="Eliminar línea" @click="deleteLine(index)"><i class="las la-trash"></i></b-button>
                                </div>
                              </div>
                            </div>
                            <div v-show="editing" class="row border-top justify-content-between">
                              <b-button class="col-1" variant="link" v-b-tooltip.hover title="Agregar línea al final de listado" @click="addLine">
                                Agregar línea
                              </b-button>
                              <div v-show="data_local.products.length > 10">
                                <Pagination class="col mt-1 d-flex justify-content-end" ref="pagination" :pageSize="pageSize" :currentPage="currentPage" @pageSizeChange="pageSizeChange" @pageChange="setPage"/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane px-2" id="other-info" role="tabpanel">
                        <div class="mb-3">
                          <TextBoxComponent v-model:value="data_local.comments" floatLabelType="Auto" placeholder="Comentarios" :enabled="editing" :multiline="true"/>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Dialog ref="dialog"/>
    <Dialog ref="dialogDelete" @accept="router.push({name: 'priceLists'});"/>
    <Dialog ref="dialogDuplicate" @accept="errorDuplicate"/>
    <Confirm ref="confirm" @accept="remove"/>
  </Layout>
</template>

<style>
@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-inputs/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";

.e-input-group .e-input[disabled], .e-input-group.e-control-wrapper .e-input[disabled], .e-input-group.e-disabled, .e-input-group.e-control-wrapper.e-disabled, .e-float-input input[disabled], .e-float-input.e-control-wrapper input[disabled], .e-float-input textarea[disabled], .e-float-input.e-control-wrapper textarea[disabled], .e-float-input.e-disabled, .e-float-input.e-control-wrapper.e-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.75);
    color: rgba(0, 0, 0, 0.75);
}
</style>