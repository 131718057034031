<script setup>
import { ref, computed, onMounted } from "vue";
import { useRouter, useRoute } from 'vue-router';
import axios from 'axios';
import { useLoading } from 'vue-loading-overlay'
import Layout from "@/layouts/mainfw.vue";
import PageHeader from "@/components/page-header";
import { useFastWay } from "@/state/modules/fastway";
import Dialog from '@/views/components/Dialog.vue';
import Confirm from '@/views/components/Confirm.vue';
import {L10n, loadCldr, setCulture} from '@syncfusion/ej2-base';
import numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import gregorian from 'cldr-data/main/es-GT/ca-gregorian.json';
import numbers from 'cldr-data/main/es-GT/numbers.json';
import timeZoneNames from 'cldr-data/main/es-GT/timeZoneNames.json';
import weekData from 'cldr-data/supplemental/weekData.json';
import { TextBoxComponent } from "@syncfusion/ej2-vue-inputs";
import { NumericTextBoxComponent } from "@syncfusion/ej2-vue-inputs";
import { DateTimePickerComponent } from "@syncfusion/ej2-vue-calendars";
import { DropDownListComponent } from "@syncfusion/ej2-vue-dropdowns";
import Pagination from '@/views/components/Pagination.vue';
import ProductSelect from '@/views/components/ProductSelect';

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData);
setCulture('es-GT');
L10n.load({
  'es-GT': {
    datetimepicker: {
      placeholder: 'Seleccione período',
      startLabel: 'Fecha inicial',
      endLabel: 'Fecha final',
      applyText: 'Seleccionar',
      cancelText: 'Cancelar',
      selectedDays: 'Seleccione día inicial y final',
      days: 'Días',
      today: 'Hoy'
    }
  }
});

const router = useRouter();
const route = useRoute();
const store = useFastWay();
const loader = useLoading();
let pagination = ref(null);
let id = ref(0);
let loaded = ref(false);
let editing = ref(false);
let dialog = ref(null);
let dialogDelete = ref(null);
let confirmDelete = ref(null);
let confirmVoid = ref(null);
let confirmConfirm = ref(null);
let data = ref(null);
let documentTypes = ref([]);
let warehouses = ref([]);
let statuses = ref([]);
let data_local = ref({
  id: 0,
  document: '',
  date: new Date(),
  documentTypeId: 0,
  warehouseId: 0,
  originWarehouseId: 0,
  destinationWarehouseId: 0,
  productId: 0,
  description: '',
  comments: '',
  statusId: 'BOR',
  statusName: 'Borrador',
  products: []
});
let currentPage = ref(1);
let pageSize = ref(10);
const title = "Transacción de inventario";
const viewParms = computed({
  get() {
    return store.viewParameters.stockTransactions;
  }
});
const items = viewParms.value.breadcrumb ? viewParms.value.breadcrumb : [
  {
    text: "Inventario",
    href: "/",
  },
  {
    text: "Transacciones",
    href: "/stock/operations/transactions",
  },
  {
    text: "Transacción",
    active: true,
  }
];

const api = computed({
    get() {
      return store.parameters.apiURL;
    }
});

const documentFunction = computed({
  get() {
    let documentFunction = '';
    const documentType = documentTypes.value.filter(item => item.id == data_local.value.documentTypeId);
    if (documentType.length > 0) {
      documentFunction = documentType[0].functionId;
    }
    return documentFunction;
  }
})

const initialItem = computed({
  get() {
    let item = currentPage.value * pageSize.value - (pageSize.value - 1);
    if (item > data_local.value.products.length) {
      item = Math.floor(data_local.value.products.length / pageSize.value);
    }
    return item;
  }
})

const finalItem = computed({
  get() {
    return initialItem.value + pageSize.value - 1;
  }
})

onMounted(async () => {
  id.value = parseInt(route.params.id);
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Cargando catálogos'});
  await axios
  .post(`${api.value}/lists/get_list`, {
    userId: store.userInfo.userId,
    token: store.userInfo.token,
    list: 'ESTADODOCUMENTO'
  })
  .then(response => {
    if (response.data.success) {
      statuses.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar estados de documento', response.data.message);
    }
    return axios.post(`${api.value}/stock/document_types/transaction_list`, {
      userId: store.userInfo.userId,
      token: store.userInfo.token
    });
  })
  .then(response => {
    if (response.data.success) {
      documentTypes.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar tipos de documento', response.data.message);
    }
    return axios.post(`${api.value}/stock/warehouses/list`, {
      userId: store.userInfo.userId,
      token: store.userInfo.token
    });
  })
  .then(response => {
    if (response.data.success) {
      warehouses.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar bodegas', response.data.message);
    }
    loading.hide();
    getData(id.value);
  })
  .catch(error => {
      let errorMessage = '';
      if (error.message) {
        errorMessage = error.message;
      } else if (error.response) {
        errorMessage = error.response.data.ErrorMessage;
      } else {
        errorMessage = 'Error de conectividad al cargar catálogo';
      }
      loading.hide();
      dialog.value.show('error', 'Error al cargar catálogos', errorMessage);
  });
});

const getData = async (id) => {
  if (id == '0') {
    loaded.value = true;
    editing.value = true;
  } else {
    const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Cargando datos de transacción'});
    // const apivalue = 'http://localhost:8080/v2';
    await axios
    .post(`${api.value}/stock/transactions/data`, {id, context: store.userInfo})
    .then(response => {
      if (response.data.success) {
        if (response.data.data) {
          data.value = response.data.data;
          if (typeof data.value.date == "string") {
            data.value.date = new Date(data.value.date);
          }
          data_local.value = JSON.parse(JSON.stringify(data.value));
          pagination.value.setTotalItems(data_local.value.products.length);
        }
      } else {
        dialog.value.show('error', 'Error interno al cargar transacción', response.data.message);
      }
      loaded.value = true;
      loading.hide();
    })
    .catch(error => {
      loaded.value = true;
      loading.hide();
      dialog.value.show('error', `Error al cargar transacción ${id}`, error.message);
    });
  }
};

const resetData = () => {
  if (id.value == '0') {
    router.push({name: 'stockTransactions'});
  } else {
    data_local.value = JSON.parse(JSON.stringify(data.value));
    editing.value = false;
  }
};

const calculateValue = (quantity, cost, index) => {
  data_local.value.products[index].value = Math.round(quantity * cost * 100) / 100;
}

const calculateValueFromQ = (event, index) => {
  calculateValue(event.value, data_local.value.products[index].cost, index);
}

const deleteLine = index => {
  if (editing.value) {
    if (index < data_local.value.products.length) {
      data_local.value.products.splice(index, 1);
    }
    pagination.value.setTotalItems(data_local.value.products.length);
    const page = Math.ceil(data_local.value.products.length / pageSize.value);
    if (page < currentPage.value) {
      currentPage.value = page;
    }
  }
}

const addLine = () => {
  if (editing.value) {
    let id = 1;
    if (data_local.value.products == null) {
      data_local.value.products = [];
    }
    if (data_local.value.products && data_local.value.products > 0) {
      const max = data_local.value.products.reduce((prev, current) => ((prev.id > current.id) ? prev : current));
      if (max) {
        id = max.id + 1;
      }
    }
    data_local.value.products.push({
      id,
      description: '',
      function: documentFunction.value,
      productId: 0,
      productType: '',
      productTypeName: '',
      productCode: '',
      productName: '',
      productSearch: '',
      searchName: '',
      quantity: 1,
      warehouseId: data_local.value.originWarehouseId,
      cost: 0,
      value: 0,
      stock: 0,
      searchData: [],
      productList: []
    });
    pagination.value.setTotalItems(data_local.value.products.length);
    const page = Math.ceil(data_local.value.products.length / pageSize.value);
    if (page > currentPage.value) {
      currentPage.value = page;
    }
  }
}

const pageSizeChange = ps => {
  currentPage.value = 1;
  pageSize.value = ps;
}

const setPage = page => {
  currentPage.value = page;
}

const openProduct = productId => {
  store.viewParameters.products.breadcrumb = [
    {
      text: "Inventario",
      href: "/",
    },
    {
      text: "Transacciones",
      href: "/stock/operations/transactions",
    },
    {
      text: data_local.value.document,
      href: `/stock/operations/transactions/${id.value}`,
    },
    {
      text: "Producto",
      active: true,
    }
  ];
  router.push({name: 'product', params: {id: `${productId}`}})
}

const validateForm = () => {
  let message = '';
  if (!data_local.value.documentTypeId || data_local.value.documentTypeId == 0) {
    message = 'Debe seleccionar un tipo de documento';
  } else if (['-', '>'].includes(documentFunction.value) && (!data_local.value.originWarehouseId || data_local.value.originWarehouseId == 0)) {
    message = 'Debe seleccionar una bodega de origen';
  } else if (['+', '*', '>'].includes(documentFunction.value) && (!data_local.value.destinationWarehouseId || data_local.value.destinationWarehouseId == 0)) {
    message = 'Debe seleccionar una bodega de destino';
  } else if (data_local.value.products.length == 0) {
    message = 'Debe seleccionar por lo menos un producto en transacción';
  } else {
    let i = 0;
    while (i < data_local.value.products.length && message == '') {
      if (data_local.value.products[i].quantity <= 0) {
        message = `Debe especificar una cantidad en la línea ${i + 1}`;
      } else if (data_local.value.products[i].productId == 0) {
        message = `Debe seleccionar un producto válido en la línea ${i + 1}`;
      }
      i++;
    }
  }
  if (message != '') {
    dialog.value.show('error', 'Error al guardar transacción', message);
  }
  return (message == '');
};

const save = async () => {
  if (validateForm()) {
    const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Guardando datos de transación'});
    data_local.value.id = id.value;
    data_local.value.documentTypeFunction = documentFunction.value;
    data_local.value.context = store.userInfo;
    data_local.value.createdBy = store.userInfo.userId;
    for (let i = 0; i < data_local.value.products.length; i++) {
      if (data_local.value.products[i].productId > 0) {
        data_local.value.products[i].function = ['-', '>'].includes(documentFunction.value) ? '-' : documentFunction.value;
        data_local.value.products[i].warehouseId = ['-', '>'].includes(documentFunction.value) ? data_local.value.originWarehouseId : data_local.value.destinationWarehouseId
      }
    }
    await axios
    .post(`${api.value}/stock/transactions/save`, data_local.value)
    .then(response => {
      loading.hide();
      if (response.data.success) {
        data.value = JSON.parse(JSON.stringify(data_local.value));
        editing.value = false;
        dialog.value.show('success', 'Información', 'Datos han sido actualizados');
        if (id.value == 0) {
          router.push({
            name: 'stockTransaction',
            params: {id: response.data.data.id}
          });
          getData(response.data.data.id);
        }
      } else {
        dialog.value.show('error', 'Error interno al actualizar transacción', response.data.message);
      }
    })
    .catch(error => {
      loading.hide();
      dialog.value.show('error',  `Error al actualizar transacción ${id.value}`, error.message);
    });
  }
};

const remove = async () => {
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Eliminando transacción'});
  const parms = {
    id: id.value,
    context: store.userInfo,
    createdBy: store.userInfo.userId
  };
  await axios
  .post(`${api.value}/stock/transactions/delete`, parms)
  .then(response => {
    loading.hide();
    if (response.data.success) {
      dialogDelete.value.show('success', 'Información', 'Transación fue eliminada correctamente');
    } else {
      dialog.value.show('error', 'Error al eliminar', response.data.message);
    }
  })
  .catch(error => {
    loading.hide();
    dialog.value.show('error', `Error al eliminar transacción ${id.value}`, error.message);
  });
};

const changeStatus = async (title, statusId, message) => {
  if (statusId == 'CONF' && ['-', '>'].includes(documentFunction.value)) {
    const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Validando existencias de productos en transacción'});
    const parms = {
      id: id.value,
      context: store.userInfo,
      createdBy: store.userInfo.userId
    };
    await axios
    .post(`${api.value}/stock/transactions/validate_stock`, parms)
    .then(response => {
      loading.hide();
      if (response.data.success) {
        setStatus(title, statusId, message);
      } else {
        let messages = [];
        const errors = response.data.message.split('<br>');
        if (errors.length > 3) {
          messages.push(errors[0])
          messages.push(errors[1])
          messages.push(errors[2])
          messages.push(`${errors.length - 4} líneas más con error`)
        } else {
          errors.forEach(line => {
            messages.push(line);
          });
        }
        dialog.value.show('error', 'Error al validar existencias', messages);
      }
    })
    .catch(error => {
      loading.hide();
      dialog.value.show('error', `Error al actualiar transacción ${id.value}`, error.message);
    });
  } else {
    setStatus(title, statusId, message);
  }
};

const setStatus = async (title, statusId, message) => {
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => title});
  const parms = {
    id: id.value,
    statusId,
    context: store.userInfo,
    createdBy: store.userInfo.userId
  };
  await axios
  .post(`${api.value}/stock/transactions/change_status`, parms)
  .then(response => {
    loading.hide();
    if (response.data.success) {
      dialog.value.show('success', 'Transación actualizada', message);
      getData(id.value);
    } else {
      dialog.value.show('error', 'Error interno al actualizar transacción', response.data.message);
    }
  })
  .catch(error => {
    loading.hide();
    dialog.value.show('error', `Error al actualiar transacción ${id.value}`, error.message);
  });
};

const add = () => {
  router.push({
    name: 'stockTransaction',
    params: {id: 0}
  });
  id.value = 0;
  data_local.value = {
    id: 0,
    document: '',
    date: new Date(),
    documentTypeId: 0,
    warehouseId: 0,
    originWarehouseId: 0,
    destinationWarehouseId: 0,
    productId: 0,
    description: '',
    comments: '',
    statusId: 'BOR',
    statusName: 'Borrador',
    products: []
  };
  editing.value = true;
};

</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
     <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center align-middle row ms-0 me-0 mb-2">
                        <div v-if="!editing" class="col d-flex justify-content-start">
                          <b-button variant="success" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Agregar transacción" @click="add"><i class="las la-plus label-icon"></i> Agregar</b-button>
                        </div>
                        <div class="col"/>
                        <div class="input-group col justify-content-end">
                          <b-button v-if="editing" variant="primary" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Guardar cambios efectuados" @click="save"><i class="las la-save label-icon"></i> Guardar</b-button>
                          <b-button v-if="editing" variant="warning" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Descartar cambios efectuados" @click="resetData"><i class="las la-undo label-icon"></i> Cancelar</b-button>
                          <b-button v-if="!editing && data_local.statusId == 'BOR'" variant="success" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Confirmar transacción" @click="confirmConfirm.show('Confirme', '¿Está seguro de confirmar esta transacción?')"><i class="las la-check-circle label-icon"></i> Confirmar</b-button>
                          <b-button v-if="!editing && data_local.statusId == 'BOR'" variant="primary" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Editar información" @click="editing = true"><i class="las la-edit label-icon"></i> Editar</b-button>
                          <b-button v-if="!editing && data_local.statusId == 'BOR'" variant="danger" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Eliminar transacción" @click="confirmDelete.show('Confirme', '¿Está seguro de eliminar esta transacción?')"><i class="las la-trash label-icon"></i> Eliminar</b-button>
                          <b-button v-if="!editing && data_local.statusId == 'CONF'" variant="warning" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Anular transacción" @click="confirmVoid.show('Confirme', '¿Está seguro de anular esta transacción?')"><i class="las la-window-close label-icon"></i> Anular</b-button>
                        </div>
                    </div>
                    <hr>
                    <div class="row mb-4">
                      <div class="col-4">
                        <div class="form-floating">
                          <input type="text" class="form-control form-control-lg" :class="{'bg-light': !editing, 'bg-white': editing}" id="role" v-model="data_local.document" placeholder="Número de documento" :disabled="true"/>
                          <label for="role">Número de documento</label>
                        </div>
                      </div>
                      <div class="col-2 text-center">
                        <h4 class="p-3 border rounded bg-secondary text-white">
                          {{ data_local.statusName }}
                        </h4>
                      </div>
                    </div>
                    <div class="row mb-1">
                      <div class="col-2 me-1">
                        <DropDownListComponent
                          v-model:value="data_local.documentTypeId"
                          floatLabelType="Auto"
                          placeholder="Tipo de documento"
                          highlight=true
                          :dataSource="documentTypes"
                          :fields="{text: 'name', value: 'id'}"
                          :enabled="editing"
                        />
                      </div>
                      <div v-if="['-', '>'].includes(documentFunction)" class="col-3 me-1">
                        <DropDownListComponent
                          v-model:value="data_local.originWarehouseId"
                          floatLabelType="Auto"
                          placeholder="Bodega origen"
                          highlight=true
                          :dataSource="warehouses"
                          :fields="{text: 'name', value: 'id'}"
                          :enabled="editing"
                        />
                      </div>
                      <div v-if="['+', '*', '>'].includes(documentFunction)" class="col-3 me-1">
                        <DropDownListComponent
                          v-model:value="data_local.destinationWarehouseId"
                          floatLabelType="Auto"
                          placeholder="Bodega destino"
                          highlight=true
                          :dataSource="warehouses"
                          :fields="{text: 'name', value: 'id'}"
                          :enabled="editing"
                        />
                      </div>
                      <div class="col me-1">
                        <DateTimePickerComponent v-model:value="data_local.date" floatLabelType="Auto" placeholder="Fecha" locale="es-GT" :enabled="editing" width="250"/>
                      </div>
                    </div>
                    <ul class="nav nav-pills arrow-navtabs nav-uccess bg-light mt-2 mb-3" role="tablist">
                      <li class="nav-item">
                        <a class="nav-link active" data-bs-toggle="tab" href="#products" role="tab">
                          Productos
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#other-info" role="tab">
                          Información adicional
                        </a>
                      </li>
                    </ul>
                    <div class="tab-content text-muted">
                      <div class="tab-pane active px-2" id="products" role="tabpanel">
                        <div class="row mb-3 border bg-light">
                          <div class="col">
                            <div class="row">
                              <div class="col-1 p-2 fs-13 bg-light fw-medium text-center" style="width: 60px;">#</div>
                              <div class="col p-2 border-start fs-12 bg-light fw-medium">Producto</div>
                              <div class="col-2 p-2 border-start fs-12 bg-light fw-medium text-end">Cantidad</div>
                              <div v-show="documentFunction == '*'" class="col-2 p-2 border-start fs-12 bg-light fw-medium text-end">Costo unitario</div>
                              <div v-show="documentFunction == '*'" class="col-2 p-2 border-start fs-12 bg-light fw-medium text-end">Valor</div>
                              <div v-show="editing" class="col-1 border-start border-bottom fs-12 bg-light" style="width: 45px;"></div>
                            </div>
                            <div v-for="(line, index) in data_local.products" :key="line.id">
                              <div v-if="(index + 1) >= initialItem && (index + 1) <= finalItem" class="row">
                                <div class="col-1 px-2 pt-3 fs-13 bg-white align-middle text-end" style="width: 60px;">{{ index + 1 }}</div>
                                <div v-show="editing" class="col p-2 bg-white border-start">
                                  <ProductSelect
                                    :product="data_local.products[index]"
                                    :index="index"
                                    :limit="20"
                                    :enabled="editing"
                                  />
                                </div>
                                <div v-show="!editing" class="col p-2 bg-white border-start">
                                  <b-button class="mt-1" variant="link" size="sm" v-b-tooltip.hover title="Ver producto" @click="openProduct(line.productId)">
                                    {{ line.productCode }} | {{ line.productName }}
                                  </b-button>
                                </div>
                                <div class="col-2 p-2 bg-white border-start">
                                  <NumericTextBoxComponent
                                    v-model:value="line.quantity"
                                    format="N4"
                                    :enabled="editing"
                                    :showSpinButton='false'
                                    textAlign='right'
                                    :min="0.0001"
                                    cssClass="numericalign"
                                    :change="e => calculateValueFromQ(e, index)"
                                  />
                                </div>
                                <div v-show="documentFunction == '*'" class="col-2 p-2 bg-white border-start">
                                  <NumericTextBoxComponent
                                    v-model:value="line.cost"
                                    format="N6"
                                    :enabled="editing"
                                    :showSpinButton='false'
                                    textAlign='right'
                                    :min="0.0001"
                                    cssClass="numericalign"
                                    :change="e => calculateValueFromQ(e, index)"
                                  />
                                </div>
                                <div v-show="documentFunction == '*'" class="col-2 px-2 pt-3 bg-white border-start fs-13 fw-medium text-end">
                                  {{
                                    line.value.toLocaleString('en', {
                                      style: 'decimal',
                                      minimumIntegerDigits: 1,
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    })
                                  }}
                                </div>
                                <div v-show="editing" class="col-1 border-start bg-light" style="width: 45px;">
                                  <b-button variant="danger" size="sm" class="btn-icon waves-effect waves-light mt-2" v-b-tooltip.hover title="Eliminar línea" @click="deleteLine(index)"><i class="las la-trash"></i></b-button>
                                </div>
                              </div>
                            </div>
                            <div v-show="editing" class="row border-top justify-content-between">
                              <b-button class="col-1" variant="link" v-b-tooltip.hover title="Agregar línea al final de listado" @click="addLine">
                                Agregar línea
                              </b-button>
                              <div v-show="data_local.products.length > 10">
                                <Pagination class="col mt-1 d-flex justify-content-end" ref="pagination" :pageSize="pageSize" :currentPage="currentPage" @pageSizeChange="pageSizeChange" @pageChange="setPage"/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane px-2" id="other-info" role="tabpanel">
                        <div class="mb-3">
                          <TextBoxComponent v-model:value="data_local.comments" floatLabelType="Auto" placeholder="Comentarios" :enabled="editing" :multiline="true"/>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Dialog ref="dialog"/>
    <Dialog ref="dialogDelete" @accept="router.push({name: 'stockTransactions'});"/>
    <Confirm ref="confirmDelete" @accept="remove"/>
    <Confirm ref="confirmVoid" @accept="changeStatus('Anulando transacción', 'ANU', `Transacción ${data_local.document} ha sido anulada`)"/>
    <Confirm ref="confirmConfirm" @accept="changeStatus('Confirmando transacción', 'CONF', `Transacción ${data_local.document} ha sido confirmada`)"/>
  </Layout>
</template>

<style>
@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-buttons/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-inputs/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";

.e-input-group .e-input[disabled], .e-input-group.e-control-wrapper .e-input[disabled], .e-input-group.e-disabled, .e-input-group.e-control-wrapper.e-disabled, .e-float-input input[disabled], .e-float-input.e-control-wrapper input[disabled], .e-float-input textarea[disabled], .e-float-input.e-control-wrapper textarea[disabled], .e-float-input.e-disabled, .e-float-input.e-control-wrapper.e-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.75);
    color: rgba(0, 0, 0, 0.75);
}

.numericalign .e-numerictextbox {
  text-align: right;
}
</style>