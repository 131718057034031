<script setup>
import { ref, onMounted, computed } from "vue";
import { useRouter } from 'vue-router';
import axios from 'axios';
import { useLoading } from 'vue-loading-overlay'
import Layout from "@/layouts/mainfw.vue";
import PageHeader from "@/components/page-header";
import { useFastWay } from "@/state/modules/fastway";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { AgGridVue } from "ag-grid-vue3";
import Dialog from '@/views/components/Dialog.vue';
import Pagination from '@/views/components/Pagination.vue';
import CellRendererStatus from '@/views/components/CRStatusDoc.vue';
import {L10n, loadCldr, setCulture} from '@syncfusion/ej2-base';
import numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import gregorian from 'cldr-data/main/es-GT/ca-gregorian.json';
import numbers from 'cldr-data/main/es-GT/numbers.json';
import timeZoneNames from 'cldr-data/main/es-GT/timeZoneNames.json';
import weekData from 'cldr-data/supplemental/weekData.json';
import {DateRangePickerComponent} from "@syncfusion/ej2-vue-calendars";
import {DropDownListComponent} from "@syncfusion/ej2-vue-dropdowns";
import PartnerSelect from '@/views/components/PartnerSelect';
import CellRendererLink from '@/views/components/CRLinkS';

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData);
setCulture('es-GT');
L10n.load({
  'es-GT': {
    daterangepicker: {
      placeholder: 'Seleccione período',
      startLabel: 'Fecha inicial',
      endLabel: 'Fecha final',
      applyText: 'Seleccionar',
      cancelText: 'Cancelar',
      selectedDays: 'Seleccione día inicial y final',
      days: 'Días'
    }
  }
});

const router = useRouter();
const store = useFastWay();
const loader = useLoading();
let dialog = ref(null);
let pagination = ref(null);
let gridColumnApi = null;
let loaded = ref(false);
let data = ref([]);
let salesmen = ref([]);
let statuses = ref([]);
let documentTypes = ref([]);
let totalRowCount = ref(0);
let partner = ref({
  id: 0,
  name: '',
  partnerId: 0,
  partnerName: '',
  searchData: []
});
const title = "Facturas";
const items = [
  {
    text: "Ventas",
    href: "/",
  },
  {
    text: "Facturas",
    active: true,
  }
];

const api = computed({
    get() {
      return store.parameters.apiURL;
    }
});

const viewParms = computed({
  get() {
    return store.viewParameters.invoices;
  }
});

viewParms.value.breadcrumb = [
  {
    text: "Ventas",
    href: "/",
  },
  {
    text: "Facturas",
    href: '/sales/transactions/invoices',
  },
  {
    text: "Factura",
    active: true,
  }
];

const gridHeight = computed({
  get() {
    let height = '600px'
    if (viewParms.value.pageSize < 20) {
      height = `${viewParms.value.pageSize * 29 + 25}px`;
    }
    return height;
  }
});

const startDate = computed({
  get() {
    let date = null;
    if (viewParms.value.period != null) {
      date = viewParms.value.period[0];
    }
    return date;
  }
});

const endDate = computed({
  get() {
    let date = null;
    if (viewParms.value.period != null) {
      date = viewParms.value.period[1];
    }
    return date;
  }
});

const columnDefs = [
  { headerName: 'ID', field: 'id', width: 90, sortable: true, cellRenderer: CellRendererLink, cellRendererParams: {route: 'invoice', title: 'Ver factura'}},
  { headerName: 'Documento', field: 'document', width: 200, sortable: true, cellRenderer: CellRendererLink, cellRendererParams: {route: 'invoice', title: 'Ver factura'}},
  { headerName: 'Fecha', field: 'date', width: 150, sortable: true, cellRenderer: row => { return formatDate(row.data.date)}},
  { headerName: 'Tipo', field: 'documentType', width: 60, sortable: true},
  { headerName: 'Cliente', field: 'partnerName', flex: 1, sortable: true},
  { headerName: 'Vendedor', field: 'salesmanName', width: 125, sortable: true},
  { headerName: 'Moneda', field: 'currencyCode', width: 75, sortable: true},
  { headerName: 'Monto', field: 'amount', width: 95, sortable: true, valueFormatter: params => params.data.amount.toLocaleString('es-GT', {maximumFractionDigits: 2, minimumFractionDigits: 2}), type: 'rightAligned'},
  { headerName: 'Estado', field: 'statusName', width: 80, sortable: true, cellRenderer: CellRendererStatus}
];

onMounted(async () => {
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Cargando catálogos'});
  partner.value.id = viewParms.value.partnerId;
  partner.value.partnerId = viewParms.value.partnerId;
  partner.value.name = viewParms.value.partnerName;
  partner.value.partnerName = viewParms.value.partnerName;
  await axios
  .post(`${api.value}/lists/get_list`, {
    userId: store.userInfo.userId,
    token: store.userInfo.token,
    list: 'ESTADODOCUMENTO'
  })
  .then(response => {
    if (response.data.success) {
      statuses.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar estados de documento', response.data.message);
    }
    return axios.post(`${api.value}/sale/document_types/invoice_list`, {
      userId: store.userInfo.userId,
      token: store.userInfo.token
    });
  })
  .then(response => {
    if (response.data.success) {
      documentTypes.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar tipos de documento', response.data.message);
    }
    return axios.post(`${api.value}/sale/partners/list`, {
      userId: store.userInfo.userId,
      token: store.userInfo.token,
      partnerType: 'V',
      order: 'name'
    });
  })
  .then(response => {
    if (response.data.success) {
      salesmen.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar vendedores', response.data.message);
    }
    loading.hide();
    getData();
  })
  .catch(error => {
      let errorMessage = '';
      if (error.message) {
        errorMessage = error.message;
      } else if (error.response) {
        errorMessage = error.response.data.ErrorMessage;
      } else {
        errorMessage = 'Error de conectividad al cargar catálogo';
      }
      loading.hide();
      dialog.value.show('error', 'Error al cargar catálogos', errorMessage);
  });
});

const getData = async () => {
  data.value = [];
  totalRowCount.value = 0;
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Cargando facturas'});
  const startDate = viewParms.value.period == null ? new Date(1800, 0, 1, 0, 0, 0, 0) : viewParms.value.period[0];
  const endDate = viewParms.value.period == null ? new Date(2200, 0, 1, 0, 0, 0, 0) : viewParms.value.period[1];
  const params = {
    userId: store.userInfo.userId,
    token: store.userInfo.token,
    limit: viewParms.value.pageSize,
    startPage: viewParms.value.currentPage,
    searchPhrase: viewParms.value.search,
    order: viewParms.value.order,
    orderDirection: viewParms.value.orderDirection,
    startDate,
    endDate,
    partnerId: viewParms.value.partnerId,
    salesmanId: viewParms.value.salesmanId,
    documentTypeId: viewParms.value.documentTypeId,
    statusId: viewParms.value.statusId
  };
  await axios
  .post(`${api.value}/sale/invoices/list`, params)
  .then(response => {
    if (response.data.success) {
      if (response.data.data) {
        data.value = response.data.data;
        if (response.data.data.length > 0) {
          totalRowCount.value = response.data.data[0].totalRowCount;
        }
      }
      if (gridColumnApi && viewParms.value.order != '') {
        gridColumnApi.applyColumnState({
          state: [{ colId: viewParms.value.order, sort: viewParms.value.orderDirection }],
          defaultState: { sort: null }
        });
      }
    } else {
      dialog.value.show('error', 'Error interno al cargar facturas', response.data.message);
    }
    pagination.value.setTotalItems(totalRowCount.value);
    loading.hide();
    loaded.value = true;
  })
  .catch(error => {
    loaded.value = true;
    loading.hide();
    dialog.value.show('error', 'Error al cargar facturas', error.message);
  });
}

const formatDate = date => {
  const d = new Date(date.replace('Z', ''));
  return `${d.getDate()
    .toString()
    .padStart(2, '0')}/${(d.getMonth() + 1).toString()
    .padStart(2, '0')}/${d.getFullYear()} ${d.getHours()
    .toString()
    .padStart(2, '0')}:${d.getMinutes()
    .toString()
    .padStart(2, '0')}:${d.getSeconds()
    .toString()
    .padStart(2, '0')}`;
}

const onFilterChange = () => {
  viewParms.value.currentPage = 1;
  getData();
}

const onPartnerChange = partner => {
  if (typeof partner.partnerId == 'number') {
    viewParms.value.partnerId = partner.partnerId > 0 ? partner.partnerId : 0;
    viewParms.value.partnerName = partner.partnerId > 0 ? partner.partnerName : '';
    onFilterChange();
  }
}

const clearSearchPhrase = () => {
    viewParms.value.currentPage = 1;
    viewParms.value.search = '';
    searchPhrase();
}

const searchPhrase = () => {
    if (loaded.value) {
      viewParms.value.currentPage = 1;
      getData();
    }
}

const postSortRows = params => {
  let sortChanged = false;
  for (const col of params.columnApi.getColumnState()) {
    if (col.sort) {
      if (viewParms.value.order != col.colId) {
        viewParms.value.order = col.colId;
        sortChanged = true;
      }
      if (viewParms.value.orderDirection != col.sort) {
        viewParms.value.orderDirection = col.sort;
        sortChanged = true;
      }
    }
  }
  if (sortChanged && loaded.value) {
    getData();
  }
}

const pageSizeChange = pageSize => {
  viewParms.value.currentPage = 1;
  viewParms.value.pageSize = pageSize;
  getData();
}

const setPage = page => {
  viewParms.value.currentPage = page;
  getData();
}

const onGridReady = params => {
  gridColumnApi = params.columnApi;
}

const add = () => {
  router.push({
    name: 'invoice',
    params: {id: 0}
  });
}

</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
     <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center align-middle row mb-2">
                        <div class="col d-flex justify-content-start">
                          <b-button variant="outline-success" class="btn-icon waves-effect waves-light me-1" v-b-tooltip.hover title="Recargar" @click="getData">
                              <i class="las la-sync"></i>
                          </b-button>
                          <b-button variant="success" class="btn-icon waves-effect waves-light" v-b-tooltip.hover title="Agregar" @click="add">
                              <i class="las la-plus"></i>
                          </b-button>
                        </div>
                        <div class="col"/>
                        <div class="d-inline-block mr-1" style="width: 210px;">
                          <DateRangePickerComponent v-model="viewParms.period" :startDate="startDate" :endDate="endDate" placeholder="Seleccione período" :change="onFilterChange"/>
                        </div>
                        <div class="d-inline-block mr-1" style="width: 240px;">
                          <PartnerSelect
                            :partner="partner"
                            :index="0"
                            :limit="30"
                            :enabled="true"
                            placeholder="Cliente"
                            @change="onPartnerChange"
                          />
                        </div>
                        <div class="d-inline-block mr-1" style="width: 190px;">
                          <DropDownListComponent v-model:value="viewParms.salesmanId" placeholder="Vendedor" highlight=true :dataSource="salesmen" :fields="{text: 'name', value: 'id'}" :showClearButton='true' :change="onFilterChange"/>
                        </div>
                        <div class="d-inline-block mr-1" style="width: 150px;">
                          <DropDownListComponent v-model:value="viewParms.documentTypeId" placeholder="Tipo de documento" highlight=true :dataSource="documentTypes" :fields="{text: 'name', value: 'id'}" :showClearButton='true' :change="onFilterChange"/>
                        </div>
                        <div class="d-inline-block mr-1" style="width: 140px;">
                          <DropDownListComponent v-model:value="viewParms.statusId" placeholder="Estado" highlight=true :dataSource="statuses" :fields="{text: 'value', value: 'code'}" :showClearButton='true' :change="onFilterChange"/>
                        </div>
                        <div class="d-inline-block" style="width: 200px;">
                          <div class="e-input-group">
                            <input type="text" v-model="viewParms.search" class="e-input" showClearButton="true" placeholder="Buscar" @keyup.enter="searchPhrase"/>
                            <span v-if="viewParms.search != ''" class="e-input-group-icon e-input-clear" @click="clearSearchPhrase"/>
                            <span class="e-input-group-icon e-input-search" @click="searchPhrase"/>
                          </div>
                        </div>
                    </div>
                    <ag-grid-vue
                        style="width: 100%;" :style="{height: gridHeight}"
                        class="ag-theme-balham"
                        :columnDefs="columnDefs"
                        :rowData="data"
                        :accentedSort="true"
                        :postSortRows="postSortRows"
                        @grid-ready="onGridReady"
                    >
                    </ag-grid-vue>
                    <Pagination ref="pagination" class="mt-2" :pageSize="viewParms.pageSize" :currentPage="viewParms.currentPage" @pageSizeChange="pageSizeChange" @pageChange="setPage"/>
                </div>
            </div>
        </div>
    </div>
    <Dialog ref="dialog"/>
  </Layout>
</template>

<style>
@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-buttons/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-inputs/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";

#input-container .e-input-group {
  margin: 30px 0;
}

.e-input-group-icon:before {
  font-family: e-icons;
}

.e-input-group .e-input-group-icon.e-input-clear .e-input-group-icon.e-input-search {
  font-size: 12px;
}

.e-input-group.e-small .e-input-group-icon.e-input-clear .e-input-group-icon.e-input-search {
  font-size: 12px;
}

.e-input-group-icon.e-input-clear:before {
  content: "\e7a7";
}

.e-input-group-icon.e-input-search:before {
  content: "\e993";
}
</style>