<script setup>
import { toRefs, defineProps, computed } from "vue";
import { useRouter } from 'vue-router';

const router = useRouter();
const props = defineProps({
  params: {
    type: Object
  }
});
const {params} = toRefs(props);
const id = computed({
    get() {
      let targetId = 0;
      if ('targetId' in params.value) {
        targetId = params.value.data[params.value.targetId];
      } else {
        targetId = params.value.data.id;
      }
      return targetId;
    }
});
const click = () => {
  if ('onClick' in params.value) {
    params.value.onClick(params.value.title);
  }
  router.push({name: params.value.route, params: {id: id.value}});
}

</script>

<template>
  <b-button variant="link" size="sm" v-b-tooltip.hover :title="params.title" @click="click">{{params.value}}</b-button>
</template>
