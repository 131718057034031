<script setup>
import { ref, onMounted, computed } from "vue";
import axios from 'axios';
import { useLoading } from 'vue-loading-overlay'
import Layout from "@/layouts/mainfw.vue";
import PageHeader from "@/components/page-header";
import { useFastWay } from "@/state/modules/fastway";
import Dialog from '@/views/components/Dialog.vue';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { AgGridVue } from "ag-grid-vue3";
import Pagination from '@/views/components/Pagination.vue';
import {L10n, loadCldr, setCulture} from '@syncfusion/ej2-base';
import numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import gregorian from 'cldr-data/main/es-GT/ca-gregorian.json';
import numbers from 'cldr-data/main/es-GT/numbers.json';
import timeZoneNames from 'cldr-data/main/es-GT/timeZoneNames.json';
import weekData from 'cldr-data/supplemental/weekData.json';
import {DateRangePickerComponent} from "@syncfusion/ej2-vue-calendars";
import {DropDownListComponent} from "@syncfusion/ej2-vue-dropdowns";
import ProductSelect from '@/views/components/ProductSelect';
import CellRendererLink from '@/views/components/CRLinkS';

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData);
setCulture('es-GT');
L10n.load({
  'es-GT': {
    daterangepicker: {
      placeholder: 'Seleccione período',
      startLabel: 'Fecha inicial',
      endLabel: 'Fecha final',
      applyText: 'Seleccionar',
      cancelText: 'Cancelar',
      selectedDays: 'Seleccione día inicial y final',
      days: 'Días'
    }
  }
});

const store = useFastWay();
const loader = useLoading();
let dialog = ref(null);
let pagination = ref(null);
let gridColumnApi = null;
let loaded = ref(false);
let data = ref([]);
let warehouses = ref([]);
let totalRowCount = ref(0);
const title = "Kardex";
const items = [
  {
    text: "Inventario",
    href: "/",
  },
  {
    text: "Kardex",
    active: true,
  },
];

const api = computed({
    get() {
      return store.parameters.apiURL;
    }
});

const viewParms = computed({
  get() {
    return store.viewParameters.kardex;
  }
});
if (typeof viewParms.value.productId != 'object') {
    viewParms.value.productId = {
      id: 0,
      code: '',
      name: '',
      productId: 0,
      productCode: '',
      productName: ''
    };
  }

const viewParmsTransactions = computed({
  get() {
    return store.viewParameters.stockTransactions;
  }
});

viewParmsTransactions.value.breadcrumb = [
  {
    text: "Inventario",
    href: "/",
  },
  {
    text: "Kardex",
    href: "/stock/reports/kardex",
  },
  {
    text: "Transacción",
    active: true,
  }
];

const startDate = computed({
  get() {
    let date = null;
    if (viewParms.value.period != null) {
      date = viewParms.value.period[0];
    }
    return date;
  }
});

const endDate = computed({
  get() {
    let date = null;
    if (viewParms.value.period != null) {
      date = viewParms.value.period[1];
    }
    return date;
  }
});

const gridHeight = computed({
  get() {
    let height = '600px'
    if (viewParms.value.pageSize < 20) {
      height = `${viewParms.value.pageSize * 29 + 25}px`;
    }
    return height;
  }
});

const formatNumber = (value, date) => {
  let number = '';
  if (value != 0 || date == '') {
    number = value.toLocaleString('es-GT', {
      minimumFractionDigits: 4,
      maximumFractionDigits: 4,
      useGrouping: true
    });
  }
  return number;
}

const columnDefs = [
  { headerName: 'Fecha', field: 'date', width: 140, sortable: false },
  { headerName: 'Documento', field: 'document', width: 300, sortable: false, cellRenderer: CellRendererLink, cellRendererParams: {route: 'stockTransaction', title: 'Ver transacción'}},
  { headerName: 'Bodega', field: 'warehouseName', flex: 1, sortable: false, cellStyle: params => {
    if (params.data.id == 0 || params.data.id >= 99999999) {
      return {'font-weight': 'bold', 'text-align': 'right'};
    }
    return null;
  }},
  { headerName: 'Entradas', field: 'in', width: 170, sortable: false, valueFormatter: params => formatNumber(params.data.in, params.data.date), type: 'rightAligned'},
  { headerName: 'Salidas', field: 'out', width: 170, sortable: false, valueFormatter: params => formatNumber(params.data.out, params.data.date), type: 'rightAligned'},
  { headerName: 'Saldo', field: 'balance', width: 170, sortable: false, valueFormatter: params => formatNumber(params.data.balance, params.data.date), type: 'rightAligned'}
];

onMounted(async () => {
  if (viewParms.value.date == null) {
    viewParms.value.date = new Date();
  } else if (viewParms.value.date == null || typeof viewParms.value.date == 'string') {
    viewParms.value.date = new Date(viewParms.value.date);
  }
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Cargando catálogos'});
  await axios
  .post(`${api.value}/stock/warehouses/list`, {
    userId: store.userInfo.userId,
    token: store.userInfo.token
  })
  .then(response => {
    if (response.data.success) {
      warehouses.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar bodegas', response.data.message);
    }
    loading.hide();
    getData();
  })
  .catch(error => {
      let errorMessage = '';
      if (error.message) {
        errorMessage = error.message;
      } else if (error.response) {
        errorMessage = error.response.data.ErrorMessage;
      } else {
        errorMessage = 'Error de conectividad al cargar catálogo';
      }
      loading.hide();
      dialog.value.show('error', 'Error al cargar catálogos', errorMessage);
  });
});

const getData = async () => {
  data.value = [];
  totalRowCount.value = 0;
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Calculando kardex'});
  const startDate = viewParms.value.period == null ? new Date(1800, 0, 1, 0, 0, 0, 0) : viewParms.value.period[0];
  const endDate = viewParms.value.period == null ? new Date(2200, 0, 1, 0, 0, 0, 0) : viewParms.value.period[1];
  const productId = viewParms.value.productId == null ? 0 : viewParms.value.productId.productId;
  const warehouseId = viewParms.value.warehouseId == null ? 0 : viewParms.value.warehouseId;
  const params = {
    userId: store.userInfo.userId,
    token: store.userInfo.token,
    limit: viewParms.value.pageSize,
    startDate,
    endDate,
    productId,
    warehouseId
  };
  await axios
  .post(`${api.value}/stock/reports/kardex`, params)
  .then(response => {
    if (response.data.success) {
      if (response.data.data) {
        data.value = response.data.data;
        if (response.data.data.length > 0) {
          totalRowCount.value = response.data.data[0].totalRowCount;
        }
      }
      if (gridColumnApi && viewParms.value.order != '') {
        gridColumnApi.applyColumnState({
          state: [{ colId: viewParms.value.order, sort: viewParms.value.orderDirection }],
          defaultState: { sort: null }
        });
      }
    } else {
      dialog.value.show('error', 'Error interno al consultar kardex', response.data.message);
    }
    pagination.value.setTotalItems(totalRowCount.value);
    loading.hide();
    loaded.value = true;
  })
  .catch(error => {
    loaded.value = true;
    loading.hide();
    dialog.value.show('error', 'Error al consultar kardex', error.message);
  });
}

const onFilterChange = () => {
  viewParms.value.currentPage = 1;
  getData();
}

const pageSizeChange = pageSize => {
  viewParms.value.currentPage = 1;
  viewParms.value.pageSize = pageSize;
  getData();
}

const setPage = page => {
  viewParms.value.currentPage = page;
  getData();
}

const onGridReady = params => {
  gridColumnApi = params.columnApi;
}

</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
     <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center align-middle row mb-2">
                        <div class="col d-flex justify-content-start">
                          <b-button variant="outline-success" class="btn-icon waves-effect waves-light me-1" v-b-tooltip.hover title="Recargar" @click="getData">
                              <i class="las la-sync"></i>
                          </b-button>
                        </div>
                        <div class="col"/>
                        <div class="d-inline-block mr-1" style="width: 600px;">
                          <ProductSelect :product="viewParms.productId" :limit="20" :enabled="true" />
                        </div>
                        <div class="d-inline-block mr-1" style="width: 200px;">
                          <DateRangePickerComponent v-model="viewParms.period" :startDate="startDate" :endDate="endDate" placeholder="Seleccione período" :change="onFilterChange"/>
                        </div>
                        <div class="d-inline-block mr-1" style="width: 320px;">
                          <DropDownListComponent v-model:value="viewParms.warehouseId" placeholder="Bodega" highlight=true :dataSource="warehouses" :fields="{text: 'name', value: 'id'}" :showClearButton='true' :change="onFilterChange"/>
                        </div>
                    </div>
                    <ag-grid-vue
                        style="width: 100%;" :style="{height: gridHeight}"
                        class="ag-theme-balham"
                        :columnDefs="columnDefs"
                        :rowData="data"
                        :accentedSort="true"
                        @grid-ready="onGridReady"
                    >
                    </ag-grid-vue>
                    <Pagination ref="pagination" class="mt-2" :pageSize="viewParms.pageSize" :currentPage="viewParms.currentPage" @pageSizeChange="pageSizeChange" @pageChange="setPage"/>
                </div>
            </div>
        </div>
    </div>
    <Dialog ref="dialog"/>
  </Layout>
</template>

<style>
@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import '../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import "../../../../node_modules/@syncfusion/ej2-vue-buttons/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-inputs/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import '../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import "../../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";

#input-container .e-input-group {
  margin: 30px 0;
}

.e-input-group-icon:before {
  font-family: e-icons;
}

.e-input-group .e-input-group-icon.e-input-clear .e-input-group-icon.e-input-search {
  font-size: 12px;
}

.e-input-group.e-small .e-input-group-icon.e-input-clear .e-input-group-icon.e-input-search {
  font-size: 12px;
}

.e-input-group-icon.e-input-clear:before {
  content: "\e7a7";
}

.e-input-group-icon.e-input-search:before {
  content: "\e993";
}
</style>