import { createApp } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import App from './App.vue';
import router from "./router";
import AOS from 'aos';
import 'aos/dist/aos.css';
// import i18n from './i18n';
import store from "./state/store";
import VueAxios from "vue-axios";
import axios from 'axios';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import "leaflet/dist/leaflet.css";

import BootstrapVue3 from 'bootstrap-vue-3';
import vClickOutside from "click-outside-vue3"
import VueApexCharts from "vue3-apexcharts";
import Maska from 'maska';

import VueFeather from 'vue-feather';
import Particles from "particles.vue3";

import '@/assets/scss/config/modern/app.scss';
import '@vueform/slider/themes/default.css';

import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";

// import { initFirebaseBackend } from './authUtils'
// import { configureFakeBackend } from './helpers/fake-backend';

import { registerLicense } from '@syncfusion/ej2-base';

// registerLicense('ORg4AjUWIQA/Gnt2VVhjQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0VhWH1dcXBVQGBeUUM=');
registerLicense('Mgo+DSMBaFt/QHRqVVhjVFpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF9iS3xVd0dmXXtbcXZQRA==;Mgo+DSMBPh8sVXJ0S0J+XE9HflRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS3xSdEdlWH1bdHBQR2NbUw==;ORg4AjUWIQA/Gnt2VVhkQlFadVdJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0dhX35cdXBRQ2FZUUY=;ODIwMjExQDMyMzAyZTM0MmUzMGNKeXhHRmdPbXE1NFJPTk1TY0VWUTVQNUM2cUJEVlZsdldBZUZRUnRVSkU9;ODIwMjEyQDMyMzAyZTM0MmUzMEplU1I0dG9DdUR0TEh0eVg1cyt4WjJMU2dUWkVrMEs0MTdua0lYWG5YZWs9;NRAiBiAaIQQuGjN/V0Z+WE9EaFxKVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdERhW3lfcnFRQmVeUU10;ODIwMjE0QDMyMzAyZTM0MmUzMGU3VUgxeHIrTnh5b0thcEZEQ202NXNpYmRzOWo1Q1QzOEpLUXJPblNkZkU9;ODIwMjE1QDMyMzAyZTM0MmUzMEVCNzdyL1pmNnNxZmtOcGNPZVhNN3U5cjc4RUFHOUppaDlINHJuaWN6WDQ9;Mgo+DSMBMAY9C3t2VVhkQlFadVdJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0dhX35cdXBRQ2JaVEY=;ODIwMjE3QDMyMzAyZTM0MmUzMFRGK2F5dlJSSk0yRDVRYytVdlBiVGQ2elpCMDBSQ244VERrTGEzNEtZYnc9;ODIwMjE4QDMyMzAyZTM0MmUzME01VTFLUnk3V3B4eHZKd01UMlp5RThjWTV0VlYwT0Z2U0wwTG5TamJlZnM9;ODIwMjE5QDMyMzAyZTM0MmUzMGU3VUgxeHIrTnh5b0thcEZEQ202NXNpYmRzOWo1Q1QzOEpLUXJPblNkZkU9');

const firebaseConfig = {
    apiKey: process.env.VUE_APP_APIKEY,
    authDomain: process.env.VUE_APP_AUTHDOMAIN,
    // databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
    projectId: process.env.VUE_APP_PROJECTId,
    storageBucket: process.env.VUE_APP_STORAGEBUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
    appId: process.env.VUE_APP_APPId,
    measurementId: process.env.VUE_APP_MEASUREMENTID
};

// Initialize Firebase
initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
//     initFirebaseBackend(firebaseConfig);
// } else {
//     configureFakeBackend();
// }


AOS.init({
    easing: 'ease-out-back',
    duration: 1000
})

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App)
app.use(store)
    .use(router)
    .use(VueApexCharts)
    .use(BootstrapVue3)
    .component(VueFeather.type, VueFeather)
    .use(Maska)
    .use(Particles)
    .use(vClickOutside)
    .use(VueSweetalert2)
    .use(VueAxios, axios)
    .use(pinia)
    .use(VueLoading);
    
router.isReady().then(() =>  { app.mount('#app') });
