<script setup>
import { ref, computed, onMounted } from "vue";
import { useRouter, useRoute } from 'vue-router';
import axios from 'axios';
import { useLoading } from 'vue-loading-overlay'
import Layout from "@/layouts/mainfw.vue";
import PageHeader from "@/components/page-header";
import { useFastWay } from "@/state/modules/fastway";
import Dialog from '@/views/components/Dialog.vue';
import Confirm from '@/views/components/Confirm.vue';
import { TextBoxComponent } from "@syncfusion/ej2-vue-inputs";
import { NumericTextBoxComponent } from "@syncfusion/ej2-vue-inputs";

const router = useRouter();
const route = useRoute();
const store = useFastWay();
const loader = useLoading();
let id = ref(0);
let loaded = ref(false);
let editing = ref(false);
let dialog = ref(null);
let dialogDelete = ref(null);
let confirm = ref(null);
let data = ref(null);
let data_local = ref({
  id: 0,
  code: '',
  name: '',
  vatValidationURL: '',
  signURL: '',
  certificationURL: '',
  voidURL: '',
  timeout: 3000,
  providerVAT: '',
  vatValidationUser: '',
  vatValidationKey: '', 
  signUser: '',
  signKey: '',
  certificationUser: '',
  certificationKey: '', 
  token: '',
  apiSection: '',
  comments: ''
});
const title = "Proveedor FEL";
const items = [
  {
    text: "Ventas",
    href: "/",
  },
  {
    text: "Proveedores FEL",
    href: "/sales/catalogs/fel_providers",
  },
  {
    text: "Proveedor FEL",
    active: true,
  }
];

const api = computed({
    get() {
      return store.parameters.apiURL;
    }
});

onMounted(async () => {
  id.value = parseInt(route.params.id);
  getData(id.value);
});

const getData = async (id) => {
  if (id == '0') {
    loaded.value = true;
    editing.value = true;
  } else {
    const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Cargando datos de proveedor FEL'});
    await axios
    .post(`${api.value}/sale/fel_providers/data`, {id, context: store.userInfo})
    .then(response => {
      if (response.data.success) {
        if (response.data.data) {
          data.value = response.data.data;
          data_local.value = JSON.parse(JSON.stringify(response.data.data));
        }
      } else {
        dialog.value.show('error', 'Error interno al cargar proveedor FEL', response.data.message);
      }
      loaded.value = true;
      loading.hide();
    })
    .catch(error => {
      loaded.value = true;
      loading.hide();
      dialog.value.show('error', `Error al cargar proveedor FEL ${id}`, error.message);
    });
  }
};

const resetData = () => {
  if (id.value == '0') {
    router.push({name: 'felProvider'});
  } else {
    data_local.value = JSON.parse(JSON.stringify(data.value));
    editing.value = false;
  }
};

const validateForm = () => {
  let validated = false;
  if (!data_local.value.code || data_local.value.code == '') {
    dialog.value.show('error', 'Error al guardar proveedor FEL', 'Debe especificar un código');
  } else if (!data_local.value.name || data_local.value.name == '') {
    dialog.value.show('error', 'Error al guardar proveedor FEL', 'Debe especificar nombre del proveedor FEL');
  } else {
    validated = true;
  }
  return validated;
};

const save = async () => {
  if (validateForm()) {
    const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Guardando datos de proveedor FEL'});
    data_local.value.id = id.value;
    data_local.value.context = store.userInfo;
    data_local.value.createdBy = store.userInfo.userId;
    await axios
    .post(`${api.value}/sale/fel_providers/save`, data_local.value)
    .then(response => {
      loading.hide();
      if (response.data.success) {
        data.value = JSON.parse(JSON.stringify(data_local.value));
        editing.value = false;
        dialog.value.show('success', 'Información', 'Datos han sido actualizados');
        if (id.value== 0) {
          router.push({
            name: 'felProvider',
            params: {id: response.data.data.id}
          });
        }
      } else {
        dialog.value.show('error', 'Error al actualizar', response.data.message);
      }
    })
    .catch(error => {
      loading.hide();
      dialog.value.show('error',  `Error al actualizar proveedor FEL ${id.value}`, error.message);
    });
  }
};

const remove = async () => {
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Eliminando proveedor FEL'});
  const parms = {
    id: id.value,
    context: store.userInfo,
    createdBy: store.userInfo.userId
  };
  await axios
  .post(`${api.value}/sale/fel_providers/delete`, parms)
  .then(response => {
    loading.hide();
    if (response.data.success) {
      dialogDelete.value.show('success', 'Información', 'Proveedor FEL fue eliminado correctamente');
    } else {
      dialog.value.show('error', 'Error al eliminar', response.data.message);
    }
  })
  .catch(error => {
    loading.hide();
    dialog.value.show('error', `Error al eliminar proveedor FEL ${id.value}`, error.message);
  });
};

const add = () => {
  router.push({
    name: 'felProvider',
    params: {id: 0}
  });
  id.value = 0;
  data_local.value = {
    id: 0,
    code: '',
    name: '',
    vatValidationURL: '',
    signURL: '',
    certificationURL: '',
    voidURL: '',
    timeout: 3000,
    providerVAT: '',
    vatValidationUser: '',
    vatValidationKey: '', 
    signUser: '',
    signKey: '',
    certificationUser: '',
    certificationKey: '', 
    token: '',
    apiSection: '',
    comments: ''
  };
  editing.value = true;
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
     <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center align-middle row ms-0 me-0 mb-2">
                        <div v-if="!editing" class="col d-flex justify-content-start">
                          <b-button variant="success" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Agregar usuario" @click="add"><i class="las la-plus label-icon"></i> Agregar</b-button>
                        </div>
                        <div class="col"/>
                        <div class="input-group col justify-content-end">
                          <b-button v-if="editing" variant="primary" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Guardar cambios efectuados" @click="save"><i class="las la-save label-icon"></i> Guardar</b-button>
                          <b-button v-if="editing" variant="warning" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Descartar cambios efectuados" @click="resetData"><i class="las la-undo label-icon"></i> Cancelar</b-button>
                          <b-button v-if="!editing" variant="primary" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Editar información" @click="editing = true"><i class="las la-edit label-icon"></i> Editar</b-button>
                          <b-button v-if="!editing" variant="danger" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Eliminar proveedor FEL" @click="confirm.show('Confirme', '¿Está seguro de eliminar este proveedor FEL?')"><i class="las la-trash label-icon"></i> Eliminar</b-button>
                        </div>
                    </div>
                    <hr>
                    <div class="row mb-4">
                      <div class="col-4">
                        <div class="form-floating">
                          <input type="text" class="form-control form-control-lg" :class="{'bg-light': !editing, 'bg-white': editing}" id="role" v-model="data_local.code" placeholder="Código" :disabled="!editing"/>
                          <label for="role">Código</label>
                        </div>
                      </div>
                    </div>
                    <div class="mb-1>">
                      <TextBoxComponent v-model:value="data_local.name" floatLabelType="Auto" placeholder="Nombre" :disabled="!editing"/>
                    </div>
                    <div class="row mb-1">
                      <div class="col">
                        <TextBoxComponent v-model:value="data_local.providerVAT" floatLabelType="Auto" placeholder="NIT" :disabled="!editing"/>
                      </div>
                      <div class="col">
                        <NumericTextBoxComponent v-model:value="data_local.timeout" floatLabelType="Auto" placeholder="Timeout en segundos" :enabled="editing" min="1" format="n0"/>
                      </div>
                      <div class="col">
                        <TextBoxComponent v-model:value="data_local.apiSection" floatLabelType="Auto" placeholder="Sección en API" :disabled="!editing"/>
                      </div>
                    </div>
                    <div class="mb-1">
                      <TextBoxComponent v-model:value="data_local.token" floatLabelType="Auto" placeholder="Token" :enabled="editing" :multiline="true"/>
                    </div>
                    <div class="mb-1">
                      <TextBoxComponent v-model:value="data_local.comments" floatLabelType="Auto" placeholder="Comentarios" :enabled="editing" :multiline="true"/>
                    </div>
                    <ul class="nav nav-pills arrow-navtabs nav-success bg-light mt-3 mb-2" role="tablist">
                      <li class="nav-item">
                        <a class="nav-link active" data-bs-toggle="tab" href="#vat-validation" role="tab">
                          Validación de NIT
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#document-sign" role="tab">
                          Firma de documentos
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#document-certification" role="tab">
                          Certificación de documentos
                        </a>
                      </li>
                    </ul>
                    <div class="tab-content text-muted">
                      <div class="tab-pane active px-2" id="vat-validation" role="tabpanel">
                        <div class="mb-1>">
                          <TextBoxComponent v-model:value="data_local.vatValidationURL" floatLabelType="Auto" placeholder="URL para validación de NIT" :disabled="!editing"/>
                        </div>
                        <div class="mb-1>">
                          <TextBoxComponent v-model:value="data_local.vatValidationUser" floatLabelType="Auto" placeholder="Usuario" :disabled="!editing"/>
                        </div>
                        <div class="mb-1>">
                          <TextBoxComponent v-model:value="data_local.vatValidationKey" floatLabelType="Auto" placeholder="Llave" :disabled="!editing"/>
                        </div>
                      </div>
                      <div class="tab-pane px-2" id="document-sign" role="tabpanel">
                        <div class="mb-1>">
                          <TextBoxComponent v-model:value="data_local.signURL" floatLabelType="Auto" placeholder="URL para firma de documentos" :disabled="!editing"/>
                        </div>
                        <div class="mb-1>">
                          <TextBoxComponent v-model:value="data_local.signUser" floatLabelType="Auto" placeholder="Usuario" :disabled="!editing"/>
                        </div>
                        <div class="mb-1>">
                          <TextBoxComponent v-model:value="data_local.signKey" floatLabelType="Auto" placeholder="Llave" :disabled="!editing"/>
                        </div>
                      </div>
                      <div class="tab-pane px-2" id="document-certification" role="tabpanel">
                        <div class="mb-1>">
                          <TextBoxComponent v-model:value="data_local.certificationURL" floatLabelType="Auto" placeholder="URL para certificación de documentos" :disabled="!editing"/>
                        </div>
                        <div class="mb-1>">
                          <TextBoxComponent v-model:value="data_local.voidURL" floatLabelType="Auto" placeholder="URL para anulación de documentos" :disabled="!editing"/>
                        </div>
                        <div class="mb-1>">
                          <TextBoxComponent v-model:value="data_local.certificationUser" floatLabelType="Auto" placeholder="Usuario" :disabled="!editing"/>
                        </div>
                        <div class="mb-1>">
                          <TextBoxComponent v-model:value="data_local.certificationKey" floatLabelType="Auto" placeholder="Llave" :disabled="!editing"/>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Dialog ref="dialog"/>
    <Dialog ref="dialogDelete" @accept="router.push({name: 'felProviders'});"/>
    <Confirm ref="confirm" @accept="remove"/>
  </Layout>
</template>

<style>
@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-inputs/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";

.e-input-group .e-input[disabled], .e-input-group.e-control-wrapper .e-input[disabled], .e-input-group.e-disabled, .e-input-group.e-control-wrapper.e-disabled, .e-float-input input[disabled], .e-float-input.e-control-wrapper input[disabled], .e-float-input textarea[disabled], .e-float-input.e-control-wrapper textarea[disabled], .e-float-input.e-disabled, .e-float-input.e-control-wrapper.e-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.75);
    color: rgba(0, 0, 0, 0.75);
}
</style>