<script setup>
import { toRefs } from "vue";

// eslint-disable-next-line no-undef
const props = defineProps({
    statusId: {
        type: String,
        default: 'BOR'
    },
    statusName: {
        type: String,
        default: 'Borrador'
    },
    statuses: {
        type: Array,
        default: () => []
    }
})

const { statusId, statuses } = toRefs(props);
</script>

<template>
<div class="fw_status">
    <ol class="fw_status_ol">
        <li v-for="item in statuses" :key="item.id"
            :class="{
                'fw_status_li_selected': item.code == statusId,
                'text-white': item.code == statusId,
                'text-muted': item.code != statusId,
                'fw-bold': item.code == statusId
            }"
        >
            <div>{{ item.value }}</div>
        </li>
    </ol>
</div>
</template>

<style>
fw_status {
    margin-right:30px;
}
ol.fw_status_ol > li {        
    display: table-cell;
    height: 30px;        
    position: relative;
    padding: 0px;
    margin: 0px;
    text-align: center;
    border: 1px solid #68a8ce;
}
ol.fw_status_ol > li > div {         
    position:relative;        
    line-height: 30px; /* equal to the list item's height */
    height:100%;
    width: 100%;      
    background-color: #f3f6f9;
}
ol.fw_status_ol > li.fw_status_li_selected > div {
    position:relative;        
    line-height: 30px; /* equal to the list item's height */
    height:100%;
    width: 100%;      
    background-color: #68a8ce;
}
ol.fw_status_ol {
    display: table;
    width: 100%;
    padding: 0px;
    margin: 0px;
    position: relative;
}
ol.fw_status_ol > li > div:after, ol.fw_status_ol > li > div:before {
    content:"";
    display:inline-block;        
    border-width: 16px;
    border-style: solid;
    width: 0px;
    height: 0px;
    left: 100%;
    top: -1px;        
    position: absolute;        
    z-index: 1;
}
ol.fw_status_ol > li > div:after, ol.fw_status_ol > div:before {
    border-color: transparent transparent transparent #68a8ce;
}
ol.fw_status_ol > li > div:before {
    border-width: 14px;
    display: block;
    border-color: transparent transparent transparent #f3f6f9;
    z-index: 2;
    top:1px;
}
ol.fw_status_ol > li.fw_status_li_selected > div:before {
    border-width: 14px;
    display: block;
    border-color: transparent transparent transparent #68a8ce;
    z-index: 2;
    top:1px;
}
</style>