<script setup>
import { ref, onMounted } from "vue";
import { Modal } from 'bootstrap';

let title = ref('title')
let message = ref('message');
let dialog = ref(null);
let dialogObj = null;

onMounted(async () => {
  dialogObj = new Modal(dialog.value);
});

const _show = function(dialogTitle, dialogMessage) {
    title.value = dialogTitle;
    message.value = dialogMessage;
    dialogObj.show()
};

// eslint-disable-next-line no-undef
const emit = defineEmits(['accept']);
const accept = () => { emit('accept') };

// eslint-disable-next-line no-undef
defineExpose({show: _show});
</script>

<template>
  <div
    class="modal fade"
    ref="dialog"
    id="confirmDialog"
    tabindex="-1"
    aria-labelledby="confirmDialogLabel"
    aria-hidden="true"
    style="display: none"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="confirmDialogLabel">{{ title }}</h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <i class="las la-question text-danger fs-1 col-1"></i>
            <p class="col mt-2 ms-3">{{ message }}</p>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="accept">
            Si
          </button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
            No
          </button>
        </div>
      </div>
    </div>
  </div>
</template>