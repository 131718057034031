<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import Layout from "@/layouts/mainfw.vue";
import PageHeader from "@/components/page-header";


export default {
  components: {
    Layout,
    PageHeader,
    flatPickr
  },
  data() {
    return {
      title: "Tablero",
      items: [
        {
          text: "Inicio",
          href: "/",
        },
        {
          text: "Tablero",
          active: true,
        },
      ],
      date: null,
      config: {
        mode: "range",
      }
    };
  },
  computed: {
    greeting() {
      const date = new Date();
      let greeting = 'Buenas noches';
      if (date.getHours() < 19) {
        greeting = 'Buenas tardes';
      } else if (date.getHours() < 12) {
        greeting = 'Buenos días';
      }
      return greeting;
    }
  },
    methods:{
    rightcolumn(){
      if(document.querySelector('.layout-rightside-col').classList.contains('d-none')){
        document.querySelector('.layout-rightside-col').classList.remove('d-none')
      }else{
        document.querySelector('.layout-rightside-col').classList.add('d-none')
      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col">
        <div class="h-100">
          <div class="row mb-3 pb-1">
            <div class="col-12">
              <div class="d-flex align-items-lg-center flex-lg-row flex-column">
                <div class="flex-grow-1">
                  <h4 class="fs-16 mb-1">{{ greeting }}</h4>
                  <p class="text-muted mb-0">
                    Esto tenemos para hoy.
                  </p>
                </div>
                <div class="mt-3 mt-lg-0">
                  <form action="javascript:void(0);">
                    <div class="row g-3 mb-0 align-items-center">
                      <div class="col-sm-auto">
                        <div class="input-group">
                          <flat-pickr
                            v-model="date"
                            :config="config"
                            class="
                              form-control
                              border-0
                              dash-filter-picker
                              shadow
                            "
                          ></flat-pickr>

                          <div
                            class="
                              input-group-text
                              bg-secondary
                              border-primary
                              text-white
                            "
                          >
                            <i class="ri-calendar-2-line"></i>
                          </div>
                        </div>
                      </div>
                      <!--end col-->
                    </div>
                    <!--end row-->
                  </form>
                </div>
              </div>
              <!-- end card header -->
            </div>
            <!--end col-->
          </div>
          <!--end row-->

          <div class="row">
            <div class="col-xl-3 col-md-6">
              <!-- card -->
              <div class="card card-animate">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1 overflow-hidden">
                      <p
                        class="
                          text-uppercase
                          fw-medium
                          text-muted text-truncate
                          mb-0
                        "
                      >
                        Favoritos
                      </p>
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-start mt-4"
                  >
                    <div class="avatar-sm flex-shrink-0 me-2">
                      <span class="avatar-title bg-soft-primary rounded fs-3">
                        <i class="las la-shopping-cart text-primary"></i>
                      </span>
                    </div>
                    <div>
                      <router-link :to="{name: 'pitPOS'}" class="text-decoration-underline">Punto de venta</router-link>
                    </div>
                  </div>
                </div>
                <!-- end card body -->
              </div>
              <!-- end card -->
            </div>
            <!-- end col -->
          </div>
          <!-- end row-->
        </div>
        <!-- end .h-100-->
      </div>
      <!-- end col -->
    </div>
  </Layout>
</template>
