<script setup>
import { ref, computed, onMounted } from "vue";
import { useRouter, useRoute } from 'vue-router';
import axios from 'axios';
import { useLoading } from 'vue-loading-overlay'
import Layout from "@/layouts/mainfw.vue";
import PageHeader from "@/components/page-header";
import { useFastWay } from "@/state/modules/fastway";
import Dialog from '@/views/components/Dialog.vue';
import Confirm from '@/views/components/Confirm.vue';
import {L10n, loadCldr, setCulture} from '@syncfusion/ej2-base';
import numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import gregorian from 'cldr-data/main/es-GT/ca-gregorian.json';
import numbers from 'cldr-data/main/es-GT/numbers.json';
import timeZoneNames from 'cldr-data/main/es-GT/timeZoneNames.json';
import weekData from 'cldr-data/supplemental/weekData.json';
import { TextBoxComponent } from "@syncfusion/ej2-vue-inputs";
import { NumericTextBoxComponent } from "@syncfusion/ej2-vue-inputs";
import { DateTimePickerComponent, DatePickerComponent } from "@syncfusion/ej2-vue-calendars";
import { DropDownListComponent } from "@syncfusion/ej2-vue-dropdowns";
import Pagination from '@/views/components/Pagination.vue';
import PartnerSelect from '@/views/components/PartnerSelect';
import ProductSelect from '@/views/components/ProductSelect';
import StatusDocument from '@/views/components/StatusDocument';
import { Query } from '@syncfusion/ej2-data';
import printJS from 'print-js';

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData);
setCulture('es-GT');
L10n.load({
  'es-GT': {
    datetimepicker: {
      placeholder: 'Seleccione fecha',
      today: 'Hoy'
    },
    datepicker: {
      placeholder: 'Seleccione fecha',
      today: 'Hoy'
    }
  }
});

const router = useRouter();
const route = useRoute();
const store = useFastWay();
const loader = useLoading();
let pagination = ref(null);
let id = ref(0);
let loaded = ref(false);
let editing = ref(false);
let dialog = ref(null);
let dialogDelete = ref(null);
let confirmDelete = ref(null);
let confirmVoid = ref(null);
let confirmCertificate = ref(null);
let data = ref(null);
let currencies = ref([]);
let documentTypes = ref([]);
let saleTypes = ref([]);
let salesmen = ref([]);
let locations = ref([]);
let warehouses = ref([]);
let felProviders = ref([]);
let sbus = ref([]);
let statuses = ref([]);
let data_local = ref({
  id: 0,
  document: '',
  date: new Date(),
  dueDate: new Date(),
  documentTypeId: 0,
  documentType: '',
  documentTypeName: '',
  documentTypeFunction: '',
  documentTypeFEL: false,
  description: '',
  comments: '',
  statusId: 'BOR',
  statusName: 'Borrador',
  partnerId: 0,
  invoiceName: '',
  invoiceAddress: '',
  invoiceCountryId: 0,
  invoiceStateId: 0,
  invoiceCityId: 0,
  invoiceZipCode: '',
  invoicePhones: '',
  shippingName: '',
  shippingAddress: '',
  shippingCountryId: 0,
  shippingStateId: 0,
  shippingCityId: 0,
  shippingZipCode: '',
  shippingPhones: '',
  salesmanId: 0,
  saleTypeId: 'CON',
  currencyId: 1,
  locationId: 0,
  originWarehouseId: 0,
  amount: 0,
  uuid: '',
  relatedInvoices: 0,
  saleOrderId: 0,
  saleOrderName: '',
  saleOrderDate: new Date(),
  saleOrderAmount: 0,
  certificationDate: new Date(),
  voidDate: new Date(),
  products: [],
  payments: [],
  cashPayments: []
});
let partner = ref({
  id: 0,
  name: '',
  partnerId: 0,
  partnerName: '',
  searchData: []
});
let countryInvoiceSelector = ref(null);
let stateInvoiceSelector = ref(null);
let countryShippingSelector = ref(null);
let stateShippingSelector = ref(null);
let queryCountryInvoice = ref(null);
let queryStateInvoice = ref(null);
let queryCountryShipping = ref(null);
let queryStateShipping = ref(null);
let currentPage = ref(1);
let pageSize = ref(10);
const title = "Factura";
const viewParms = computed({
  get() {
    return store.viewParameters.invoices;
  }
});
const items = viewParms.value.breadcrumb ? viewParms.value.breadcrumb : [
{
    text: "Ventas",
    href: "/",
  },
  {
    text: "Facturas",
    href: '/sales/transactions/invoices',
  },
  {
    text: "Factura",
    active: true,
  }
];

const api = computed({
    get() {
      return store.parameters.apiURL;
    }
});

const countries = computed({
    get() {
      return store.countries;
    }
});

const states = computed({
    get() {
      return store.states;
    }
});

const cities = computed({
    get() {
      return store.cities;
    }
});

const warehouseInLines = computed({
    get() {
      let resp = false;
      const param = store.parametersFW.filter(item => item.parameter == 'VENTAS' && item.code == 'BODEGAPORLINEA');
      if (param.length > 0) {
        resp = (param[0].value.toUpperCase() == 'SI');
      }
      return resp;
    }
});

const SBUInLines = computed({
    get() {
      let resp = false;
      const param = store.parametersFW.filter(item => item.parameter == 'VENTAS' && item.code == 'SBUPORLINEA');
      if (param.length > 0) {
        resp = (param[0].value.toUpperCase() == 'SI');
      }
      return resp;
    }
});

const documentFunction = computed({
  get() {
    let documentFunction = '';
    const documentType = documentTypes.value.filter(item => item.id == data_local.value.documentTypeId);
    if (documentType.length > 0) {
      documentFunction = documentType[0].functionId;
    }
    return documentFunction;
  }
});

const initialItem = computed({
  get() {
    let item = currentPage.value * pageSize.value - (pageSize.value - 1);
    if (item > data_local.value.products.length) {
      item = Math.floor(data_local.value.products.length / pageSize.value);
    }
    return item;
  }
});

const finalItem = computed({
  get() {
    return initialItem.value + pageSize.value - 1;
  }
})

const totalPaidCash = computed({
  get() {
   return data_local.value.cashPayments.reduce((n, {amount}) => n + amount, 0);
  }
});

const paidDifference = computed({
  get() {
   return totalPaidCash.value - data_local.value.amount;
  }
});

onMounted(async () => {
  id.value = parseInt(route.params.id);
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Cargando catálogos'});
  const context = {userId: store.userInfo.userId, token: store.userInfo.token};
  await axios
  .post(`${api.value}/lists/get_list`, {
    userId: store.userInfo.userId,
    token: store.userInfo.token,
    list: 'ESTADODOCUMENTOFACTURA'
  })
  .then(response => {
    if (response.data.success) {
      statuses.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar estados de documento', response.data.message);
    }
    return axios.post(`${api.value}/sale/document_types/invoice_list`, {
      userId: store.userInfo.userId,
      token: store.userInfo.token
    });
  })
  .then(response => {
    if (response.data.success) {
      documentTypes.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar tipos de documento', response.data.message);
    }
    return axios.post(`${api.value}/lists/get_list`, {
      userId: store.userInfo.userId,
      token: store.userInfo.token,
      list: 'TIPOVENTA'
    });
  })
  .then(response => {
    if (response.data.success) {
      saleTypes.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar tipos de venta', response.data.message);
    }
    return axios.post(`${api.value}/sale/partners/list`, {
      userId: store.userInfo.userId,
      token: store.userInfo.token,
      partnerType: 'V',
      order: 'name'
    });
  })
  .then(response => {
    if (response.data.success) {
      salesmen.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar vendedores', response.data.message);
    }
    return axios
      .post(`${api.value}/infra/currencies/list`, {
        userId: store.userInfo.userId,
        token: store.userInfo.token
      });
  })
  .then(response => {
    if (response.data.success) {
      currencies.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar monedas', response.data.message);
    }
    return axios
      .post(`${api.value}/locations/list`, context);
  })
  .then(response => {
    if (response.data.success) {
      locations.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar ubicaciones', response.data.message);
    }
    return axios
      .post(`${api.value}/stock/warehouses/list`, context);
  })
  .then(response => {
    if (response.data.success) {
      warehouses.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar bodegas', response.data.message);
    }
    return axios
      .post(`${api.value}/sale/fel_providers/list`, context);
  })
  .then(response => {
    if (response.data.success) {
      felProviders.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar proveedores FEL', response.data.message);
    }
    return axios
      .post(`${api.value}/lists/sbus/list`, context);
  })
  .then(response => {
    if (response.data.success) {
      sbus.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar SBUs', response.data.message);
    }
    loading.hide();
    getData(id.value);
  })
  .catch(error => {
      let errorMessage = '';
      if (error.message) {
        errorMessage = error.message;
      } else if (error.response) {
        errorMessage = error.response.data.ErrorMessage;
      } else {
        errorMessage = 'Error de conectividad al cargar catálogo';
      }
      loading.hide();
      dialog.value.show('error', 'Error al cargar catálogos', errorMessage);
  });
});

const getData = async (id) => {
  if (id == '0') {
    loaded.value = true;
    editing.value = true;
  } else {
    const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Cargando datos de factura'});
    await axios
    .post(`${api.value}/sale/invoices/data`, {id, context: store.userInfo})
    .then(response => {
      if (response.data.success) {
        if (response.data.data) {
          data.value = response.data.data;
          if (typeof data.value.date == "string") {
            data.value.date = new Date(data.value.date);
          }
          if (typeof data.value.dueDate == "string") {
            data.value.dueDate = new Date(data.value.dueDate);
          }
          if (data.value.products == null) {
            data.value.products = [];
          }
          if (data.value.payments == null) {
            data.value.payments = [];
          }
          if (data.value.cashPayments == null) {
            data.value.cashPayments = [];
          }
          data_local.value = JSON.parse(JSON.stringify(data.value));
          partner.value = {
            id: data_local.value.partnerId,
            name: data_local.value.partnerName,
            partnerId: data_local.value.partnerId,
            partnerName: data_local.value.partnerName,
            searchData: []
          };
          pagination.value.setTotalItems(data_local.value.products.length);
        }
      } else {
        dialog.value.show('error', 'Error interno al cargar factura', response.data.message);
      }
      loaded.value = true;
      loading.hide();
    })
    .catch(error => {
      loaded.value = true;
      loading.hide();
      dialog.value.show('error', `Error al cargar factura ${id}`, error.message);
    });
  }
};

const formatDate = date => {
  let d = new Date();
  if (typeof date == 'string') {
    d = new Date(date.replace('Z', ''));
  } else {
    d = date;
  }
  return `${d.getDate()
    .toString()
    .padStart(2, '0')}/${(d.getMonth() + 1).toString()
    .padStart(2, '0')}/${d.getFullYear()} ${d.getHours()
    .toString()
    .padStart(2, '0')}:${d.getMinutes()
    .toString()
    .padStart(2, '0')}:${d.getSeconds()
    .toString()
    .padStart(2, '0')}`;
}

const resetData = () => {
  if (id.value == '0') {
    router.push({name: 'invoices'});
  } else {
    data_local.value = JSON.parse(JSON.stringify(data.value));
    partner.value = {
      id: data_local.value.partnerId,
      name: data_local.value.partnerName,
      partnerId: data_local.value.partnerId,
      partnerName: data_local.value.partnerName,
      searchData: []
    };
    editing.value = false;
  }
};

const onCountryInvoiceChange = () => {
  queryCountryInvoice.value = new Query().where('countryId', 'equal', countryInvoiceSelector.value.ej2Instances.value);
}

const onStateInvoiceChange = () => {
  queryStateInvoice.value = new Query().where('stateId', 'equal', stateInvoiceSelector.value.ej2Instances.value);
}

const onCountryShippingChange = () => {
  queryCountryShipping.value = new Query().where('countryId', 'equal', countryShippingSelector.value.ej2Instances.value);
}

const onStateShippingChange = () => {
  queryStateShipping.value = new Query().where('stateId', 'equal', stateShippingSelector.value.ej2Instances.value);
}

const calculateValue = (quantity, price, discountPercent, discountValue, index) => {
  data_local.value.products[index].amount = Math.round(quantity * price * 100) / 100;
  if (discountPercent > 0) {
    data_local.value.products[index].discountValue = Math.round(data_local.value.products[index].amount * discountPercent) / 100;
  } else if (discountValue > 0) {
    data_local.value.products[index].discountPercent = discountValue / data_local.value.products[index].amount * 100;
  }
  data_local.value.products[index].value = data_local.value.products[index].amount - data_local.value.products[index].discountValue;
  data_local.value.amount = data_local.value.products.reduce((n, {value}) => n + value, 0);
}

const calculateValueFromQ = (event, index) => {
  calculateValue(event.value, data_local.value.products[index].unitPrice, data_local.value.products[index].discountPercent, data_local.value.products[index].discountValue, index);
}

const calculateValueFromP = (event, index) => {
  calculateValue(data_local.value.products[index].quantity, event.value, data_local.value.products[index].discountPercent, data_local.value.products[index].discountValue, index);
}

const calculateValueFromDP = (event, index) => {
  calculateValue(data_local.value.products[index].quantity, data_local.value.products[index].unitPrice, event.value, 0, index);
}

const calculateValueFromDV = (event, index) => {
  calculateValue(data_local.value.products[index].quantity, data_local.value.products[index].unitPrice, 0, event.value, index);
}

const deleteLine = index => {
  if (editing.value) {
    if (index < data_local.value.products.length) {
      data_local.value.products.splice(index, 1);
    }
    pagination.value.setTotalItems(data_local.value.products.length);
    const page = Math.ceil(data_local.value.products.length / pageSize.value);
    if (page < currentPage.value) {
      currentPage.value = page;
    }
    data_local.value.amount = data_local.value.products.reduce((n, {value}) => n + value, 0);
  }
}

const addLine = () => {
  if (editing.value) {
    let id = 1;
    if (data_local.value.products == null) {
      data_local.value.products = [];
    }
    if (data_local.value.products && data_local.value.products > 0) {
      const max = data_local.value.products.reduce((prev, current) => ((prev.id > current.id) ? prev : current));
      if (max) {
        id = max.id + 1;
      }
    }
    data_local.value.products.push({
      id,
      description: '',
      function: documentFunction.value,
      productId: 0,
      productType: '',
      productTypeName: '',
      productCode: '',
      productName: '',
      productSearch: '',
      searchName: '',
      warehouseId: 0,
      sbuId: 0,
      quantity: 1,
      unitPrice: 0,
      amount: 0,
      discountPercent: 0,
      discountValue: 0,
      value: 0,
      stock: 0,
      searchData: [],
      productList: []
    });
    pagination.value.setTotalItems(data_local.value.products.length);
    const page = Math.ceil(data_local.value.products.length / pageSize.value);
    if (page > currentPage.value) {
      currentPage.value = page;
    }
  }
}

const pageSizeChange = ps => {
  currentPage.value = 1;
  pageSize.value = ps;
}

const setPage = page => {
  currentPage.value = page;
}

const onDocumentTypeChange = event => {
  if (editing.value) {
    if (event.itemData.saleTypeId == 'CRE') {
      data_local.value.dueDate = new Date();
      data_local.value.dueDate.setDate(data_local.value.date.getDate() + 30);
    }
    data_local.value.saleTypeId = event.itemData.saleTypeId;
  }
}

const onPartnerChange = partner => {
  if (typeof partner.partnerId == 'number') {
    data_local.value.partnerId = partner.partnerId > 0 ? partner.partnerId : 0;
  }
}

const openPartner = partnerId => {
  store.viewParameters.partners.breadcrumb = [
    {
      text: "Ventas",
      href: "/",
    },
    {
      text: "Facturas",
      active: '/sales/transactions/invoices',
    },
    {
      text: data_local.value.document,
      href: `/sales/transactions/invoices/${id.value}`,
    },
    {
      text: "Cliente",
      active: true,
    }
  ];
  router.push({name: 'customer', params: {id: `${partnerId}`}})
}

const openProduct = productId => {
  store.viewParameters.products.breadcrumb = [
    {
      text: "Ventas",
      href: "/",
    },
    {
      text: "Facturas",
      active: '/sales/transactions/invoices',
    },
    {
      text: data_local.value.document,
      href: `/sales/transactions/invoices/${id.value}`,
    },
    {
      text: "Producto",
      active: true,
    }
  ];
  router.push({name: 'product', params: {id: `${productId}`}})
}

const openSaleOrder = saleOrderId => {
  store.viewParameters.saleOrders.breadcrumb = [
    {
      text: "Ventas",
      href: "/",
    },
    {
      text: "Facturas",
      active: '/sales/transactions/invoices',
    },
    {
      text: data_local.value.document,
      href: `/sales/transactions/invoices/${id.value}`,
    },
    {
      text: "Pedido de venta",
      active: true,
    }
  ];
  router.push({name: 'saleOrder', params: {id: `${saleOrderId}`}})
}

const onProductSelect = async product => {
  data_local.value.products[product.index].description = product.name;
  data_local.value.products[product.index].productType = product.type;
  await axios
  .post(`${api.value}/sale/price_lists/product_price`, {productId: product.id, customerId: data_local.value.partnerId, context: store.userInfo})
  .then(response => {
    if (response.data.success) {
      if (response.data.data) {
        data_local.value.products[product.index].unitPrice = response.data.data.price;
        calculateValueFromP({value: response.data.data.price}, product.index);
      }
    } else {
      dialog.value.show('error', 'Error interno al calcular precio de producto', response.data.message);
    }
  })
  .catch(error => {
    dialog.value.show('error', `Error al calcular precio de producto ${product.productCode} | ${product.productName}`, error.message);
  });
}

const validateForm = () => {
  let message = '';
  if (!data_local.value.documentTypeId || data_local.value.documentTypeId == 0) {
    message = 'Debe seleccionar un tipo de documento';
  } else if (!data_local.value.partnerId || data_local.value.partnerId == 0) {
    message = 'Debe seleccionar un cliente';
  } else if (!data_local.value.salesmanId || data_local.value.salesmanId == 0) {
    message = 'Debe seleccionar un vendedor';
  } else if (!data_local.value.locationId || data_local.value.locationId == 0) {
    message = 'Debe seleccionar una ubicación';
  } else if (!warehouseInLines.value && (!data_local.value.originWarehouseId || data_local.value.originWarehouseId == 0)) {
    message = 'Debe seleccionar una bodega';
  } else if (!data_local.value.saleTypeId == 'CON' && paidDifference.value < 0) {
    message = 'Total pagado debe ser mayor o igual a valor de la factura';
  } else if (data_local.value.products.length == 0) {
    message = 'Debe seleccionar por lo menos un producto en transacción';
  } else {
    let i = 0;
    while (i < data_local.value.products.length && message == '') {
      if (data_local.value.products[i].quantity <= 0) {
        message = `Debe especificar una cantidad en la línea ${i + 1}`;
      } else if (data_local.value.products[i].productId == 0) {
        message = `Debe seleccionar un producto válido en la línea ${i + 1}`;
      } else if (warehouseInLines.value && (!data_local.value.products[i].warehouseId || data_local.value.products[i].warehouseId == 0)) {
        message = `Debe seleccionar una bodega válida en la línea ${i + 1}`;
      } else {
        data_local.value.products[i].warehouseId = data_local.value.originWarehouseId;
      }
      i++;
    }
  }
  if (message != '') {
    dialog.value.show('error', 'Error al guardar factura', message);
  }
  return (message == '');
};

const save = async () => {
  if (validateForm()) {
    const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Guardando datos de factura'});
    data_local.value.id = id.value;
    data_local.value.documentTypeFunction = documentFunction.value;
    data_local.value.context = store.userInfo;
    data_local.value.createdBy = store.userInfo.userId;
    for (let i = 0; i < data_local.value.products.length; i++) {
      if (data_local.value.products[i].productId > 0) {
        data_local.value.products[i].function = documentFunction.value;
      }
    }
    await axios
    .post(`${api.value}/sale/invoices/save`, data_local.value)
    .then(response => {
      loading.hide();
      if (response.data.success) {
        data.value = JSON.parse(JSON.stringify(data_local.value));
        editing.value = false;
        dialog.value.show('success', 'Información', 'Datos han sido actualizados');
        if (id.value == 0) {
          router.push({
            name: 'saleOrder',
            params: {id: response.data.data.id}
          });
          getData(response.data.data.id);
        }
      } else {
        dialog.value.show('error', 'Error interno al actualizar factura', response.data.message);
      }
    })
    .catch(error => {
      loading.hide();
      dialog.value.show('error',  `Error al actualizar factura ${id.value}`, error.message);
    });
  }
};

const print = async () => {
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Generando documento para impresión'});
  const parms = {
    id: id.value,
    context: store.userInfo,
    createdBy: store.userInfo.userId
  };
  await axios
  .post(`${api.value}/sale/invoices/generate_ticket`, parms)
  .then(response => {
    loading.hide();
    if (response.data.success) {
      printJS({printable: response.data.data, type: 'pdf', base64: true});
    } else {
      dialog.value.show('error', 'Error al generar documento', response.data.message);
    }
  })
  .catch(error => {
    loading.hide();
    dialog.value.show('error', `Error al generar documento para factura ${id.value}`, error.message);
  });
};

const remove = async () => {
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Eliminando factura'});
  const parms = {
    id: id.value,
    context: store.userInfo,
    createdBy: store.userInfo.userId
  };
  await axios
  .post(`${api.value}/sale/invoices/delete`, parms)
  .then(response => {
    loading.hide();
    if (response.data.success) {
      dialogDelete.value.show('success', 'Información', 'Factura fue eliminada correctamente');
    } else {
      dialog.value.show('error', 'Error al eliminar', response.data.message);
    }
  })
  .catch(error => {
    loading.hide();
    dialog.value.show('error', `Error al eliminar factura ${id.value}`, error.message);
  });
};

const changeStatus = async (title, statusId, message) => {
  if (statusId == 'CERT') {
    const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Validando existencias de productos en transacción'});
    const parms = {
      id: id.value,
      context: store.userInfo,
      createdBy: store.userInfo.userId
    };
    await axios
    .post(`${api.value}/stock/transactions/validate_stock`, parms)
    .then(response => {
      loading.hide();
      if (response.data.success) {
        if (data_local.value.documentTypeFEL) {
          setStatus(title, statusId, message);
        } else {
          setStatus(title, statusId, message);
        }
      } else {
        let messages = [];
        const errors = response.data.message.split('<br>');
        if (errors.length > 3) {
          messages.push(errors[0])
          messages.push(errors[1])
          messages.push(errors[2])
          messages.push(`${errors.length - 4} líneas más con error`)
        } else {
          errors.forEach(line => {
            messages.push(line);
          });
        }
        dialog.value.show('error', 'Error al validar existencias', messages);
      }
    })
    .catch(error => {
      loading.hide();
      dialog.value.show('error', `Error al actualizar transacción ${id.value}`, error.message);
    });
  } else if (statusId == 'ANU' && data_local.value.documentTypeFEL) {
    const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Anulando transacción'});
    const documentType = documentTypes.value.filter(item => item.id == data_local.value.documentTypeId);
    if (documentType && documentType.length > 0) {
      const felProvider = felProviders.value.filter(item => item.id == documentType[0].felProviderId);
      if (felProvider && felProvider.length > 0) {
        const location = locations.value.filter(item => item.id == data_local.value.locationId);
        if (location && location.length > 0) {
          axios.post(`${api.value}/${felProvider[0].apiSection}/void`, {
            signDate: data_local.value.certificationDate,
            satUuid: data_local.value.satuuid,
            senderVAT: location[0].vat,
            vat: data_local.value.vat,
            signURL: felProvider[0].signURL,
            voidURL: felProvider[0].voidURL,
            felToken: felProvider[0].token 
          })
          .then(response => {
            if (response.data.success) {
              return axios.post(`${api.value}/sale/invoices/fel_void`, {
                id: data_local.value.id,
                satuuid: response.data.data.satVoidUuid,
                voidDate: new Date(),
                sourceXMLVoid: response.data.data.voidResultXml,
                context: store.userInfo
              });
            } else {
              throw `La orden fue colocada pero no pudo ser certificada.   Transacción: ${response.data.data.id} - Error: ${response.data.message}`;
            }
          })
          .then(response => {
            loading.hide();
            if (response.data.success) {
              setStatus(title, statusId, message);
            } else {
              dialog.value.show('error', 'Error al actualizar anulación de documento', response.data.message);
            }
          })
          .catch(error => {
            loading.hide();
            let errorMessage = '';
            if (typeof error == 'string') {
              errorMessage = error
            } else if (error.message) {
              errorMessage = error.message;
            } else if (error.response) {
              errorMessage = error.response.data.ErrorMessage;
            } else {
              errorMessage = 'Error de conectividad'
            }
            dialog.value.show('error', 'Error al anular documento', errorMessage);
          });
        } else {
          dialog.value.show('error', 'Error al anular documento', `No se encontró ubicación ${data_local.value.locationId}`);
        }
      } else {
        dialog.value.show('error', 'Error al anular documento', `No se encontró proveedor FEL ${documentType[0].felProviderId}`);
      }
    } else {
      dialog.value.show('error', 'Error al anular documento', `No se encontró tipo de documento ${data_local.value.documentTypeId}`);
    }
  } else {
    setStatus(title, statusId, message);
  }
};

const setStatus = async (title, statusId, message) => {
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => title});
  const parms = {
    id: id.value,
    statusId,
    context: store.userInfo,
    createdBy: store.userInfo.userId
  };
  await axios
  .post(`${api.value}/stock/transactions/change_status`, parms)
  .then(response => {
    loading.hide();
    if (response.data.success) {
      dialog.value.show('success', 'Transación actualizada', message);
      getData(id.value);
    } else {
      dialog.value.show('error', 'Error interno al actualizar transacción', response.data.message);
    }
  })
  .catch(error => {
    loading.hide();
    dialog.value.show('error', `Error al actualizar transacción ${id.value}`, error.message);
  });
};

const add = () => {
  router.push({
    name: 'invoice',
    params: {id: 0}
  });
  id.value = 0;
  data_local.value = {
    id: 0,
    document: '',
    date: new Date(),
    dueDate: new Date(),
    documentTypeId: 0,
    documentType: '',
    documentTypeName: '',
    documentTypeFunction: '',
    documentTypeFEL: false,
    description: '',
    comments: '',
    statusId: 'BOR',
    statusName: 'Borrador',
    partnerId: 0,
    invoiceName: '',
    invoiceAddress: '',
    invoiceCountryId: 1,
    invoiceStateId: 0,
    invoiceCityId: 0,
    invoiceZipCode: '',
    invoicePhones: '',
    shippingName: '',
    shippingAddress: '',
    shippingCountryId: 1,
    shippingStateId: 0,
    shippingCityId: 0,
    shippingZipCode: '',
    shippingPhones: '',
    salesmanId: 0,
    saleTypeId: 'CON',
    currencyId: 1,
    locationId: 0,
    originWarehouseId: 0,
    amount: 0,
    uuid: '',
    relatedInvoices: 0,
    saleOrderId: 0,
    saleOrderName: '',
    saleOrderDate: new Date(),
    saleOrderAmount: 0,
    products: [],
    payments: [],
    cashPayments: []
  };
  editing.value = true;
};

</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
     <div class="row">
        <div class="col-lg-12">
            <div v-show="loaded" class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center align-middle row ms-0 me-0 mb-2">
                        <div v-if="!editing" class="col d-flex justify-content-start">
                          <b-button variant="success" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Agregar transacción" @click="add"><i class="las la-plus label-icon"></i> Agregar</b-button>
                        </div>
                        <div class="col">
                          <StatusDocument :statusId="data_local.statusId" :statusName="data_local.statusName" :statuses="statuses"/>
                        </div>
                        <div class="input-group col justify-content-end">
                          <b-button v-if="editing" variant="primary" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Guardar cambios efectuados" @click="save"><i class="las la-save label-icon"></i> Guardar</b-button>
                          <b-button v-if="editing" variant="warning" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Descartar cambios efectuados" @click="resetData"><i class="las la-undo label-icon"></i> Cancelar</b-button>
                          <b-button v-if="!editing && data_local.statusId == 'BOR'" variant="success" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Certificar factura" @click="confirmCertificate.show('Confirme', '¿Está seguro de certificar esta factura?')"><i class="las la-check-circle label-icon"></i> Certificar</b-button>
                          <b-button v-if="!editing && data_local.statusId == 'BOR'" variant="primary" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Editar información" @click="editing = true"><i class="las la-edit label-icon"></i> Editar</b-button>
                          <b-button v-if="!editing && data_local.statusId == 'BOR'" variant="danger" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Eliminar factura" @click="confirmDelete.show('Confirme', '¿Está seguro de eliminar esta factura?')"><i class="las la-trash label-icon"></i> Eliminar</b-button>
                          <b-button v-if="!editing && data_local.statusId == 'CERT' && data_local.firstInvoice == 0" variant="warning" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Anular factura" @click="confirmVoid.show('Confirme', '¿Está seguro de anular esta factura?')"><i class="las la-window-close label-icon"></i> Anular</b-button>
                          <b-button v-if="!editing && data_local.statusId == 'CERT'" variant="success" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Imprimir documento" @click="print"><i class="las la-print label-icon"></i> Imprimir</b-button>
                        </div>
                    </div>
                    <hr>
                    <div class="row mb-4">
                      <div class="col-4">
                        <div class="form-floating">
                          <input type="text" class="form-control form-control-lg" :class="{'bg-light': !editing, 'bg-white': editing}" id="role" v-model="data_local.document" placeholder="Número de documento" :disabled="true"/>
                          <label for="role">Número de documento</label>
                        </div>
                      </div>
                      <div class="col">
                        <div class="row justify-content-end">
                          <div class="col-2 me-2">
                            <DropDownListComponent
                              v-model:value="data_local.currencyId"
                              floatLabelType="Auto"
                              placeholder="Moneda"
                              highlight=true
                              :dataSource="currencies"
                              :fields="{text: 'name', value: 'id'}"
                              :enabled="editing"
                            />
                          </div>
                          <h3 class="col-2 p-3 text-end">
                            {{
                              data_local.amount.toLocaleString('en', {
                                style: 'decimal',
                                minimumIntegerDigits: 1,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              })
                            }}
                        </h3>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-1">
                      <div class="col-2 me-1">
                        <DropDownListComponent
                          v-model:value="data_local.documentTypeId"
                          floatLabelType="Auto"
                          placeholder="Tipo de documento"
                          highlight=true
                          :dataSource="documentTypes"
                          :fields="{text: 'name', value: 'id'}"
                          :enabled="editing"
                          @change="onDocumentTypeChange"
                        />
                      </div>
                      <div v-show="false" class="col-2 me-1">
                        <DropDownListComponent
                          v-model:value="data_local.saleTypeId"
                          floatLabelType="Auto"
                          placeholder="Tipo de venta"
                          highlight=true
                          :dataSource="saleTypes"
                          :fields="{text: 'value', value: 'code'}"
                          :enabled="editing"
                        />
                      </div>
                      <div class="col">
                        <DropDownListComponent
                          v-model:value="data_local.salesmanId"
                          floatLabelType="Auto"
                          placeholder="Vendedor"
                          highlight=true
                          :dataSource="salesmen"
                          :fields="{text: 'name', value: 'id'}"
                          :enabled="editing"
                        />
                      </div>
                      <div class="col-2 me-1" width="250">
                        <DateTimePickerComponent v-model:value="data_local.date" floatLabelType="Auto" placeholder="Fecha" locale="es-GT" :enabled="editing"/>
                      </div>
                      <div v-if="data_local.saleTypeId == 'CRE'" class="col-2 me-1" width="250">
                        <DatePickerComponent v-model:value="data_local.dueDate" floatLabelType="Auto" placeholder="Fecha de vencimiento" locale="es-GT" :enabled="editing"/>
                      </div>
                    </div>
                    <div class="row mb-1">
                      <div v-if="editing" class="col">
                        <PartnerSelect
                          :partner="partner"
                          :index="0"
                          :limit="30"
                          floatLabelType="Auto"
                          placeholder="Cliente"
                          :enabled="editing"
                          @change="onPartnerChange"
                        />
                      </div>
                      <div v-else class="col">
                        <small>Cliente:</small>
                        <br>
                        <b-button v-if="data_local.partnerId != 0" class="mt-1" variant="link" size="sm" v-b-tooltip.hover title="Ver cliente" @click="openPartner(data_local.partnerId)">
                          {{ data_local.partnerName }}
                        </b-button>
                        <p v-else>{{ data_local.vat }} - {{ data_local.invoiceName }}</p>
                      </div>
                      <div :class="{'col-4': warehouseInLines, 'col-3': !warehouseInLines}">
                        <DropDownListComponent
                          v-model:value="data_local.locationId"
                          floatLabelType="Auto"
                          placeholder="Ubicación"
                          highlight=true
                          :dataSource="locations"
                          :fields="{text: 'name', value: 'id'}"
                          :enabled="editing"
                        />
                      </div>
                      <div v-if="!warehouseInLines" class="col-2">
                        <DropDownListComponent
                          v-model:value="data_local.originWarehouseId"
                          floatLabelType="Auto"
                          placeholder="Bodega"
                          highlight=true
                          :dataSource="warehouses"
                          :fields="{text: 'name', value: 'id'}"
                          :enabled="editing"
                        />
                      </div>
                    </div>
                    <ul class="nav nav-pills arrow-navtabs nav-uccess bg-light mt-2 mb-3" role="tablist">
                      <li class="nav-item">
                        <a class="nav-link active" data-bs-toggle="tab" href="#products" role="tab">
                          Productos
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#addresses" role="tab">
                          Direcciones
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#other-info" role="tab">
                          Información adicional
                        </a>
                      </li>
                      <li v-show="data_local.saleTypeId == 'CRE'" class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#payments" role="tab">
                          Pagos
                        </a>
                      </li>
                      <li v-show="data_local.saleTypeId == 'CON'" class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#cashPayments" role="tab">
                          Forma de pago
                        </a>
                      </li>
                      <li v-show="data_local.satuuid != ''" class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#felData" role="tab">
                          Datos FEL
                        </a>
                      </li>
                      <li v-show="data_local.satuuidvoid != ''" class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#felVoidData" role="tab">
                          Datos anulación FEL
                        </a>
                      </li>
                    </ul>
                    <div class="tab-content text-muted">
                      <div class="tab-pane active px-2" id="products" role="tabpanel">
                        <div class="row mb-3 border bg-light">
                          <div class="col">
                            <div class="row">
                              <div class="col-1 p-2 fs-13 bg-light fw-medium text-center" style="width: 40px;">#</div>
                              <div class="col p-2 border-start fs-12 bg-light fw-medium">Producto</div>
                              <div v-if="warehouseInLines" class="col-1 p-2 border-start fs-12 bg-light fw-medium">Bodega</div>
                              <div v-if="SBUInLines" class="col-1 p-2 border-start fs-12 bg-light fw-medium" style="width: 200px;">SBU</div>
                              <div class="col-1 p-2 border-start fs-12 bg-light fw-medium text-end" style="width: 100px;">Cantidad</div>
                              <div class="col-1 p-2 border-start fs-12 bg-light fw-medium text-end" style="width: 90px;">Precio</div>
                              <div class="col-1 p-2 border-start fs-12 bg-light fw-medium text-end" style="width: 110px;">Monto</div>
                              <div class="col-1 p-2 border-start fs-12 bg-light fw-medium text-end" style="width: 80px;">% Descto.</div>
                              <div class="col-1 p-2 border-start fs-12 bg-light fw-medium text-end" style="width: 90px;">Valor Descto.</div>
                              <div class="col-1 p-2 border-start fs-12 bg-light fw-medium text-end" style="width: 110px;">Valor</div>
                              <div v-show="editing" class="col-1 border-start border-bottom fs-12 bg-light" style="width: 45px;"></div>
                            </div>
                            <div v-for="(line, index) in data_local.products" :key="line.id">
                              <div v-if="(index + 1) >= initialItem && (index + 1) <= finalItem" class="row">
                                <div class="col-1 pe-2 pt-3 fs-13 bg-light align-middle text-end" style="width: 40px;">{{ index + 1 }}</div>
                                <div v-show="editing" class="col p-2 bg-white border-start">
                                  <div v-if="data_local.products[index].productType == 'S'" class="row">
                                    <div class="col-2">
                                      <ProductSelect
                                        :product="data_local.products[index]"
                                        :index="index"
                                        :limit="20"
                                        :enabled="editing"
                                        @select="onProductSelect"
                                      />
                                    </div>
                                    <div class="col">
                                      <TextBoxComponent v-model:value="line.description" placeholder="Descripción"/>
                                    </div>
                                  </div>
                                  <div v-else class="row">
                                    <div class="col">
                                      <ProductSelect
                                        :product="data_local.products[index]"
                                        :index="index"
                                        :limit="20"
                                        :enabled="editing"
                                        @select="onProductSelect"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div v-show="!editing" class="col p-2 bg-white border-start">
                                  <b-button class="mt-1" variant="link" size="sm" v-b-tooltip.hover title="Ver producto" @click="openProduct(line.productId)">
                                    {{ line.productCode }} | {{ line.description }}
                                  </b-button>
                                </div>
                                <div v-if="warehouseInLines" class="col-1 p-2 bg-white border-start">
                                  <DropDownListComponent
                                    v-model:value="line.warehouseId"
                                    highlight=true
                                    :dataSource="warehouses"
                                    :fields="{text: 'name', value: 'id'}"
                                    :enabled="editing"
                                  />
                                </div>
                                <div v-if="SBUInLines" class="col-1 p-2 bg-white border-start" style="width: 200px;">
                                  <DropDownListComponent
                                    v-model:value="line.sbuId"
                                    highlight=true
                                    :dataSource="sbus"
                                    :fields="{text: 'name', value: 'id'}"
                                    :enabled="editing"
                                  />
                                </div>
                                <div class="col-1 p-2 bg-white border-start" style="width: 100px;">
                                  <NumericTextBoxComponent
                                    v-model:value="line.quantity"
                                    format="N4"
                                    :enabled="editing"
                                    :showSpinButton='false'
                                    textAlign='right'
                                    :min="0.0001"
                                    cssClass="numericalign"
                                    :change="e => calculateValueFromQ(e, index)"
                                  />
                                </div>
                                <div class="col-1 p-2 bg-white border-start" style="width: 90px;">
                                  <NumericTextBoxComponent
                                    v-model:value="line.unitPrice"
                                    format="N2"
                                    :enabled="editing"
                                    :showSpinButton='false'
                                    textAlign='right'
                                    cssClass="numericalign"
                                    :change="e => calculateValueFromP(e, index)"
                                  />
                                </div>
                                <div class="col-1 px-2 pt-3 bg-white border-start fs-13 fw-medium text-end" style="width: 110px;">
                                  {{
                                    line.amount.toLocaleString('en', {
                                      style: 'decimal',
                                      minimumIntegerDigits: 1,
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    })
                                  }}
                                </div>
                                <div class="col-1 p-2 bg-white border-start" style="width: 80px;">
                                  <NumericTextBoxComponent
                                    v-model:value="line.discountPercent"
                                    format="N2"
                                    :enabled="editing"
                                    :showSpinButton='false'
                                    textAlign='right'
                                    cssClass="numericalign"
                                    :change="e => calculateValueFromDP(e, index)"
                                  />
                                </div>
                                <div class="col-1 p-2 bg-white border-start" style="width: 90px;">
                                  <NumericTextBoxComponent
                                    v-model:value="line.discountValue"
                                    format="N2"
                                    :enabled="editing"
                                    :showSpinButton='false'
                                    textAlign='right'
                                    cssClass="numericalign"
                                    :change="e => calculateValueFromDV(e, index)"
                                  />
                                </div>
                                <div class="col-1 px-2 pt-3 bg-light border-start fs-13 fw-bold text-end" style="width: 110px;">
                                  {{
                                    line.value.toLocaleString('en', {
                                      style: 'decimal',
                                      minimumIntegerDigits: 1,
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    })
                                  }}
                                </div>
                                <div v-show="editing" class="col-1 border-start bg-light" style="width: 45px;">
                                  <b-button variant="danger" size="sm" class="btn-icon waves-effect waves-light mt-2" v-b-tooltip.hover title="Eliminar línea" @click="deleteLine(index)"><i class="las la-trash"></i></b-button>
                                </div>
                              </div>
                            </div>
                            <div v-show="editing" class="row border-top justify-content-between">
                              <b-button class="col-1" variant="link" v-b-tooltip.hover title="Agregar línea al final de listado" @click="addLine">
                                Agregar línea
                              </b-button>
                              <div v-show="data_local.products.length > 10">
                                <Pagination class="col mt-1 d-flex justify-content-end" ref="pagination" :pageSize="pageSize" :currentPage="currentPage" @pageSizeChange="pageSizeChange" @pageChange="setPage"/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane px-2" id="addresses" role="tabpanel">
                        <h6 class="bg-light p-3">Dirección para facturación</h6>
                        <div class="mb-1>">
                          <TextBoxComponent v-model:value="data_local.invoiceAddress" floatLabelType="Auto" placeholder="Dirección" :disabled="!editing"/>
                        </div>
                        <div class="row mb-1">
                          <div class="col me-1">
                            <DropDownListComponent
                              ref="countryInvoiceSelector"
                              v-model:value="data_local.invoiceCountryId"
                              floatLabelType="Auto"
                              placeholder="Pais"
                              highlight=true
                              :dataSource="countries"
                              :fields="{text: 'name', value: 'id'}"
                              :enabled="editing"
                              :change="onCountryInvoiceChange"
                            />
                          </div>
                          <div class="col me-1">
                            <DropDownListComponent
                              ref="stateInvoiceSelector"
                              v-model:value="data_local.invoiceStateId"
                              floatLabelType="Auto"
                              placeholder="Departamento"
                              highlight=true
                              :dataSource="states"
                              :fields="{text: 'name', value: 'id'}"
                              :enabled="editing"
                              :query="queryCountryInvoice"
                              :change="onStateInvoiceChange"
                            />
                          </div>
                          <div class="col me-1">
                            <DropDownListComponent
                              v-model:value="data_local.invoiceCityId"
                              floatLabelType="Auto"
                              placeholder="Municipio"
                              highlight=true
                              :dataSource="cities"
                              :fields="{text: 'name', value: 'id'}"
                              :enabled="editing"
                              :query="queryStateInvoice"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-2 me-1">
                            <TextBoxComponent v-model:value="data_local.invoiceZipCode" floatLabelType="Auto" placeholder="Código postal" :disabled="!editing"/>
                          </div>
                          <div class="col">
                            <TextBoxComponent v-model:value="data_local.invoicePhones" floatLabelType="Auto" placeholder="Teléfonos" :disabled="!editing"/>
                          </div>
                        </div>
                        <h6 class="bg-light p-3 mt-4">Dirección para envío</h6>
                        <div class="mb-1>">
                          <TextBoxComponent v-model:value="data_local.shippingAddress" floatLabelType="Auto" placeholder="Dirección" :disabled="!editing"/>
                        </div>
                        <div class="row mb-1">
                          <div class="col me-1">
                            <DropDownListComponent
                              ref="countryShippingSelector"
                              v-model:value="data_local.shippingCountryId"
                              floatLabelType="Auto"
                              placeholder="Pais"
                              highlight=true
                              :dataSource="countries"
                              :fields="{text: 'name', value: 'id'}"
                              :enabled="editing"
                              :change="onCountryShippingChange"
                            />
                          </div>
                          <div class="col me-1">
                            <DropDownListComponent
                              ref="stateShippingSelector"
                              v-model:value="data_local.shippingStateId"
                              floatLabelType="Auto"
                              placeholder="Departamento"
                              highlight=true
                              :dataSource="states"
                              :fields="{text: 'name', value: 'id'}"
                              :enabled="editing"
                              :query="queryCountryShipping"
                              :change="onStateShippingChange"
                            />
                          </div>
                          <div class="col me-1">
                            <DropDownListComponent
                              v-model:value="data_local.shippingCityId"
                              floatLabelType="Auto"
                              placeholder="Municipio"
                              highlight=true
                              :dataSource="cities"
                              :fields="{text: 'name', value: 'id'}"
                              :enabled="editing"
                              :query="queryStateShipping"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-2 me-1">
                            <TextBoxComponent v-model:value="data_local.shippingZipCode" floatLabelType="Auto" placeholder="Código postal" :disabled="!editing"/>
                          </div>
                          <div class="col">
                            <TextBoxComponent v-model:value="data_local.shippingPhones" floatLabelType="Auto" placeholder="Teléfonos" :disabled="!editing"/>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane px-2" id="other-info" role="tabpanel">
                        <div class="row mb-2">
                          <div class="col-3 me-1">
                            <small>Pedido</small><br>
                            <b-button class="mt-1" variant="link" size="sm" v-b-tooltip.hover title="Ver pedido" @click="openSaleOrder(data_local.saleOrderId)">
                              {{ data_local.saleOrderName }}
                            </b-button>
                          </div>
                          <div class="col-2 me-1">
                            <small class="mb-3">Fecha</small><br>
                            {{ formatDate(data_local.saleOrderDate) }}
                          </div>
                          <div class="col-2 me-1">
                            <small class="mb-3">Valor</small><br>
                            {{
                              data_local.saleOrderAmount.toLocaleString('en', {
                                style: 'decimal',
                                minimumIntegerDigits: 1,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              })
                            }}
                          </div>
                        </div>
                        <div class="mb-3">
                          <TextBoxComponent v-model:value="data_local.comments" floatLabelType="Auto" placeholder="Comentarios" :enabled="editing" :multiline="true"/>
                        </div>
                      </div>
                      <div v-show="data_local.saleTypeId == 'CRE'" class="tab-pane px-2" id="payments" role="tabpanel">
                        <div class="row mb-3 border bg-light">
                          <div class="col">
                            <div class="row">
                              <div class="col-1 p-2 fs-13 bg-light fw-medium text-center" style="width: 60px;">#</div>
                              <div class="col-2 p-2 border-start fs-12 bg-light fw-medium">Documento</div>
                              <div class="col-2 p-2 border-start fs-12 bg-light fw-medium">Fecha</div>
                              <div class="col-1 p-2 border-start fs-12 bg-light fw-medium text-end">Moneda</div>
                              <div class="col-1 p-2 border-start fs-12 bg-light fw-medium text-end">Monto</div>
                              <div class="col p-2 border-start fs-12 bg-light fw-medium">Cliente</div>
                              <div class="col-2 p-1 border-start fs-12 bg-light fw-medium">Estado</div>
                            </div>
                            <div v-for="(line, index) in data_local.payments" :key="line.id">
                              <div v-if="(index + 1) >= initialItem && (index + 1) <= finalItem" class="row">
                                <div class="col-1 p-2 fs-13 bg-light align-middle text-end" style="width: 60px;">{{ index + 1 }}</div>
                                <div class="col-2 bg-white border-start">
                                  <b-button class="mt-1" variant="link" size="sm" v-b-tooltip.hover title="Ver producto" @click="openProduct(line.productId)">
                                    {{ line.document }}
                                  </b-button>
                                </div>
                                <div class="col-2 p-2 bg-white border-start">
                                  {{ formatDate(line.date) }}
                                </div>
                                <div class="col-1 p-2 bg-white border-start text-end">
                                  {{ line.currencyCode }}
                                </div>
                                <div class="col-1 p-2 bg-white border-start text-end">
                                  {{
                                    line.amount.toLocaleString('en', {
                                      style: 'decimal',
                                      minimumIntegerDigits: 1,
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    })
                                  }}
                                </div>
                                <div class="col bg-white border-start">
                                  <b-button class="mt-1" variant="link" size="sm" v-b-tooltip.hover title="Ver cliente" @click="openPartner(data_local.partnerId)">
                                    {{ line.partnerName }}
                                  </b-button>
                                </div>
                                <div class="col-1 p-2 bg-white border-start text-center">
                                  <b-badge v-if="line.statusId == 'ANU'" variant="danger" class="text-white">{{ line.statusName }}</b-badge>
                                  <b-badge v-if="line.statusId == 'CERT'" variant="success" class="text-white">{{ line.statusName }}</b-badge>
                                  <b-badge v-if="!['ANU', 'CERT'].includes(line.statusId)" variant="info" class="text-white">{{ line.statusName }}</b-badge>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-show="data_local.saleTypeId == 'CON'" class="tab-pane px-2" id="cashPayments" role="tabpanel">
                        <div class="row mb-3">
                          <div class="col-5">
                            <div class="row">
                              <div class="col-1 p-2 fs-13 bg-light fw-medium text-center border" style="width: 60px;">#</div>
                              <div class="col p-2 border-top border-bottom border-end fs-12 bg-light fw-medium">Tipo de pago</div>
                              <div class="col-3 p-2 border-top border-bottom border-end fs-12 bg-light fw-medium text-end">Monto</div>
                            </div>
                            <div v-for="(line, index) in data_local.cashPayments" :key="line.id">
                              <div v-if="(index + 1) >= initialItem && (index + 1) <= finalItem" class="row">
                                <div class="col-1 p-3 fs-13 bg-light align-middle text-end border-start" style="width: 60px;">{{ index + 1 }}</div>
                                <div class="col px-2 pt-3 bg-white border-start border-bottom">
                                  {{ line.name }}
                                </div>
                                <div class="col-3 p-2 bg-white border-start border-bottom border-end">
                                  <NumericTextBoxComponent
                                    v-model:value="line.amount"
                                    format="N2"
                                    :enabled="editing"
                                    :showSpinButton='false'
                                    textAlign='right'
                                    cssClass="numericalign"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col py-2 fs-9 bg-light fw-bolder text-end border-start border-bottom">Total pagado:</div>
                              <div class="col-3 py-2 fs-9 bg-light fw-bolder text-end border-start border-bottom border-end">
                                {{
                                    totalPaidCash.toLocaleString('en', {
                                      style: 'decimal',
                                      minimumIntegerDigits: 1,
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    })
                                  }}
                              </div>
                            </div>
                            <div class="row">
                              <div class="col py-2 fs-9 bg-light text-end border-start border-bottom">Diferencia:</div>
                              <div class="col-3 py-2 fs-9 bg-light text-end border-start border-bottom border-end" :class="{ 'text-danger': paidDifference < 0 }">
                                {{
                                    paidDifference.toLocaleString('en', {
                                      style: 'decimal',
                                      minimumIntegerDigits: 1,
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    })
                                  }}
                              </div>
                            </div>
                          </div>
                          <div class="col bg-white"/>
                        </div>
                      </div>
                      <div v-show="data_local.satuuid != ''" class="tab-pane px-2" id="felData" role="tabpanel">
                        <div class="row mb-2">
                          <div class="col-3 me-1">
                            <TextBoxComponent v-model:value="data_local.satuuid" floatLabelType="Auto" placeholder="UUID SAT" :disabled="true"/>
                          </div>
                          <div class="col-2 me-1">
                            <TextBoxComponent v-model:value="data_local.certificationSerial" floatLabelType="Auto" placeholder="Serie" :disabled="true"/>
                          </div>
                          <div class="col-2 me-1">
                            <TextBoxComponent v-model:value="data_local.certificationNumber" floatLabelType="Auto" placeholder="Número" :disabled="true"/>
                          </div>
                          <div class="col-2 me-1">
                            <small class="mb-3">Fecha de certificación</small><br>
                            {{ formatDate(data_local.certificationDate) }}
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col">
                            <TextBoxComponent v-model:value="data_local.sourceXML" floatLabelType="Auto" placeholder="XML Fuente" :enabled="false" :multiline="true"/>
                          </div>
                        </div>
                      </div>
                      <div v-show="data_local.satuuidvoid != ''" class="tab-pane px-2" id="felVoidData" role="tabpanel">
                        <div class="row">
                          <div class="col-3 me-1">
                            <TextBoxComponent v-model:value="data_local.satuuidvoid" floatLabelType="Auto" placeholder="UUID anulación SAT" :disabled="true"/>
                          </div>
                          <div class="col-2 me-1">
                            <small class="mb-3">Fecha de anulación</small><br>
                            {{ formatDate(data_local.voidDate) }}
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col">
                            <TextBoxComponent v-model:value="data_local.sourceXMLVoid" floatLabelType="Auto" placeholder="XML Fuente" :enabled="false" :multiline="true"/>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Dialog ref="dialog"/>
    <Dialog ref="dialogDelete" @accept="router.push({path: items[1].href});"/>
    <Confirm ref="confirmDelete" @accept="remove"/>
    <Confirm ref="confirmVoid" @accept="changeStatus('Anulando transacción', 'ANU', `Factura ${data_local.document} ha sido anulada`)"/>
    <Confirm ref="confirmCertificate" @accept="changeStatus('Certificando factura', 'CERT', `Factura ${data_local.document} ha sido certificada`)"/>
  </Layout>
</template>

<style>
@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-buttons/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-inputs/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";

.e-input-group .e-input[disabled], .e-input-group.e-control-wrapper .e-input[disabled], .e-input-group.e-disabled, .e-input-group.e-control-wrapper.e-disabled, .e-float-input input[disabled], .e-float-input.e-control-wrapper input[disabled], .e-float-input textarea[disabled], .e-float-input.e-control-wrapper textarea[disabled], .e-float-input.e-disabled, .e-float-input.e-control-wrapper.e-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.75);
    color: rgba(0, 0, 0, 0.75);
}

.numericalign .e-numerictextbox {
  text-align: right;
}
</style>