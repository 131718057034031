<script setup>
import { ref, computed, onMounted } from "vue";
import { useRouter, useRoute } from 'vue-router';
import axios from 'axios';
import { useLoading } from 'vue-loading-overlay'
import Layout from "@/layouts/mainfw.vue";
import PageHeader from "@/components/page-header";
import { useFastWay } from "@/state/modules/fastway";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { AgGridVue } from "ag-grid-vue3";
import Dialog from '@/views/components/Dialog.vue';
import Confirm from '@/views/components/Confirm.vue';
import CellRendererPermissions from '@/views/components/CRPermissions.vue';
import { Modal } from 'bootstrap';
import { TextBoxComponent } from "@syncfusion/ej2-vue-inputs";

const router = useRouter();
const route = useRoute();
const store = useFastWay();
const loader = useLoading();
let gridApi = null;
let id = ref(0);
let loaded = ref(false);
let editing = ref(false);
let dialog = ref(null);
let dialogDelete = ref(null);
let permissionsDialog = ref(null);
let permissionsDialogObj = null;
let confirm = ref(null);
let data = ref(null);
let permissions = ref([]);
let permissionsOption = ref([]);
let optionId = ref(0);
let optionName = ref('');
let options = ref([]);
let data_local = ref({
  id: 0,
  parameter: '',
  code: '',
  value: ''
});
const title = "Roles";
const items = [
  {
    text: "Infraestructura",
    href: "/",
  },
  {
    text: "Roles",
    href: "/infra/roles",
  },
  {
    text: "Rol",
    active: true,
  }
];
const columnDefs = [
  {
    headerName: 'Acceso a opciones de menú',
    field: 'title',
    width: 400,
    sortable: false,
    cellRenderer: params => {
      let rend = '';
      if (params.data.level <= 1) {
        rend = `<h6>${params.data.title}</h6>`;
      } else if (params.data.level == 2) {
        rend = `&nbsp;&nbsp;&nbsp;<b>${params.data.title}</b>`;
      } else if (params.data.level == 3) {
        rend = `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${params.data.title}`;
      } else {
        rend = `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${params.data.title}`;
      }
      return rend;
    }
  },
  { headerName: 'Permisos asignados', field: 'permissions', flex: 1, sortable: false, cellRenderer: CellRendererPermissions},
];

const api = computed({
    get() {
      return store.parameters.apiURL;
    }
});

onMounted(async () => {
  permissionsDialogObj = new Modal(permissionsDialog.value);
  id.value = parseInt(route.params.id);
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Cargando permisos'});
  await axios
  .post(`${api.value}/users/permissions/list`, {userId: store.userInfo.userId, token: store.userInfo.token})
  .then(response => {
    if (response.data.success) {
      permissions.value = response.data.data;
      getData(id.value);
    } else {
      dialog.value.show('error', 'Error al cargar lista de permisos', response.data.message);
    }
    loading.hide();
  })
  .catch(error => {
      let errorMessage = '';
      if (error.message) {
        errorMessage = error.message;
      } else if (error.response) {
        errorMessage = error.response.data.ErrorMessage;
      } else {
        errorMessage = 'Error de conectividad al cargar permisos';
      }
      loading.hide();
      dialog.value.show('error', 'Error al cargar permisos', errorMessage);
  });
});

const onGridReady = params => {
  gridApi = params.api;
}

const pushOption = (option, level) => {
  const opts = [];
  if (option.children != null) {
    for (let i = 0; i < option.children.length; i++) {
      opts.push(pushOption(option.children[i]), level + 1);
    }
  }
  return {
    id: option.id,
    level: option.level,
    title: option.title,
    slug: option.slug,
    validPermissions: option.validPermissions,
    permissions: option.permissions,
    opts
  };
}

const loadOptions = (baseData) => {
  options.value = [];
  for (let i = 0; i < baseData.options.length; i++) {
    if (baseData.options[i].parentId == 0) {
      options.value.push(pushOption(baseData.options[i], 0));
    }
  }
}

const getData = async (id) => {
  if (id == 0) {
    loaded.value = true;
    editing.value = true;
  } else {
    const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Cargando datos de rol'});
    await axios
    .post(`${api.value}/roles/data`, {id, context: store.userInfo})
    .then(response => {
      if (response.data.success) {
        if (response.data.data) {
          data.value = response.data.data;
          data_local.value = JSON.parse(JSON.stringify(response.data.data));
          loadOptions(data_local.value);
        }
      } else {
        dialog.value.show('error', 'Error al cargar rol', response.data.message);
      }
      loaded.value = true;
      loading.hide();
    })
    .catch(error => {
      loaded.value = true;
      loading.hide();
      dialog.value.show('error', `Error al cargar rol ${id}`, error.message);
    });
  }
};

const resetData = () => {
  if (id.value == 0) {
    router.push({name: 'roles'});
  } else {
    data_local.value = JSON.parse(JSON.stringify(data.value));
    loadOptions(data_local.value);
    editing.value = false;
  }
};

const onRowDoubleClicked = event => {
  if (editing.value) {
    optionId.value = event.data.id;
    optionName.value = event.data.title;
    permissionsOption.value = [];
    permissions.value.forEach(item => {
      if (event.data.validPermissions.includes(item.code)) {
        permissionsOption.value.push({
          text: item.name,
          id: item.code,
          isChecked: event.data.permissions.includes(item.code)
        });
      }
    });
    if (permissionsOption.value.length > 0) {
      permissionsDialogObj.show();
    }
  }
};

const markPermission = (event, index) => {
  if (index >= 0 && index < permissionsOption.value.length) {
    if (typeof event.srcElement.checked == 'boolean') {
      permissionsOption.value[index].isChecked = event.srcElement.checked;
    } else {
      permissionsOption.value[index].isChecked = false;
    }
  }
}

const setOptionPermissions = (id, options, permissions) => {
  for (let i = 0; i < options.length; i++) {
    if (options[i].id == id) {
      options[i].permissions = permissions;
    } else if (options[i].children) {
      setOptionPermissions(id, options[i].children, permissions);
    }
  }
}

const acceptPermissions = () => {
  const index = options.value.findIndex(o => o.id == optionId.value);
  if (index >= 0) {
    let perms = '';
    permissionsOption.value.forEach(item => {
      if (item.isChecked) {
        perms += (perms == '' ? '' : ',') + item.id;
      }
    });
    if (!perms.includes('ACC')) {
      perms = '';
    }
    options.value[index].permissions = perms;
    setOptionPermissions(optionId.value, data_local.value.options, perms);
    gridApi.refreshCells();
  }
}

const validateForm = () => {
  let validated = false;
  if (!data_local.value.role || data_local.value.role == '') {
    dialog.value.show('error', 'Error al guardar lista', 'Debe especificar un código');
  } else if (!data_local.value.description || data_local.value.description == '') {
    dialog.value.show('error', 'Error al guardar lista', 'Debe especificar una descripción');
  } else {
    validated = true;
  }
  return validated;
};

const save = async () => {
  if (validateForm()) {
    const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Guardando datos de rol'});
    data_local.value.id = id.value;
    data_local.value.context = store.userInfo;
    data_local.value.createdBy = store.userInfo.userId;
    await axios
    .post(`${api.value}/roles/save`, data_local.value)
    .then(response => {
      loading.hide();
      if (response.data.success) {
        data.value = JSON.parse(JSON.stringify(data_local.value));
        editing.value = false;
        dialog.value.show('success', 'Información', 'Datos han sido actualizados');
        if (id.value== 0) {
          router.push({
            name: 'role',
            params: {id: response.data.data.id}
          });
        }
      } else {
        dialog.value.show('error', 'Error al actualizar', response.data.message);
      }
    })
    .catch(error => {
      loading.hide();
      dialog.value.show('error',  `Error al actualizar rol ${id.value}`, error.message);
    });
  } 
};

const remove = async () => {
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Eliminando rol'});
  const parms = {
    id: id.value,
    context: store.userInfo
  };
  await axios
  .post(`${api.value}/roles/delete`, parms)
  .then(response => {
    loading.hide();
    if (response.data.success) {
      dialogDelete.value.show('success', 'Información', 'Rol fue eliminado correctamente');
    } else {
      dialog.value.show('error', 'Error al eliminar', response.data.message);
    }
  })
  .catch(error => {
    loading.hide();
    dialog.value.show('error', `Error al eliminar rol ${id.value}`, error.message);
  });
};

const add = () => {
  router.push({
    name: 'role',
    params: {id: 0}
  });
  id.value = 0;
  data_local.value = {
    id: 0,
    parameter: '',
    code: '',
    value: ''
  };
  editing.value = true;
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
     <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center align-middle row ms-0 me-0 mb-2">
                        <div v-if="!editing" class="col d-flex justify-content-start">
                          <b-button variant="success" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Agregar parámetro" @click="add"><i class="las la-plus label-icon"></i> Agregar</b-button>
                        </div>
                        <div class="col"/>
                        <div class="input-group col justify-content-end">
                          <b-button v-if="editing" variant="primary" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Guardar cambios efectuados" @click="save"><i class="las la-save label-icon"></i> Guardar</b-button>
                          <b-button v-if="editing" variant="warning" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Descartar cambios efectuados" @click="resetData"><i class="las la-undo label-icon"></i> Cancelar</b-button>
                          <b-button v-if="!editing" variant="primary" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Editar información" @click="editing = true"><i class="las la-edit label-icon"></i> Editar</b-button>
                          <b-button v-if="!editing" variant="danger" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Eliminar parámetro" @click="confirm.show('Confirme', '¿Está seguro de eliminar este rol?')"><i class="las la-trash label-icon"></i> Eliminar</b-button>
                        </div>
                    </div>
                    <hr>
                    <div class="mb-1>">
                      <TextBoxComponent v-model:value="data_local.role" floatLabelType="Auto" placeholder="Código" :disabled="!editing"/>
                    </div>
                    <div class="mb-1>">
                      <TextBoxComponent v-model:value="data_local.description" floatLabelType="Auto" placeholder="Descripción" :disabled="!editing"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
     <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                  <h5>Permisos</h5>
                </div>
                <div class="card-body">
                  <ag-grid-vue
                      style="width: 100%; height: 500px;"
                      class="ag-theme-balham"
                      :columnDefs="columnDefs"
                      :rowData="options"
                      :accentedSort="true"
                      @gridReady="onGridReady"
                      @rowDoubleClicked="onRowDoubleClicked"
                  >
                  </ag-grid-vue>
                </div>
            </div>
        </div>
    </div>
    <Dialog ref="dialog"/>
    <Dialog ref="dialogDelete" @accept="router.push({name: 'roles'});"/>
    <Confirm ref="confirm" @accept="remove"/>
    <div
      class="modal fade"
      ref="permissionsDialog"
      id="permissionsDialog"
      tabindex="-1"
      aria-labelledby="permissionsDialogLabel"
      aria-hidden="true"
      style="display: none"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="permissionsDialogLabel">Permisos para la opción {{ optionName }}</h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
              <div
              class="form-check form-switch form-switch-md mb-2"
              :class="{'form-switch-danger': 'ELI|ANU'.includes(item.id), 'form-switch-success': item.id == 'ACC', 'form-switch-warning': item.id == 'IMP'}"
              v-for="(item, index) in permissionsOption" :key="item.id"
              >
                <input class="form-check-input" type="checkbox" :checked="item.isChecked" @change="markPermission($event, index)"/>
                <span class="fs-5">{{ item.text }}</span>
              </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="acceptPermissions">
              Aceptar
            </button>
            <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-inputs/styles/material.css";

.e-input-group .e-input[disabled], .e-input-group.e-control-wrapper .e-input[disabled], .e-input-group.e-disabled, .e-input-group.e-control-wrapper.e-disabled, .e-float-input input[disabled], .e-float-input.e-control-wrapper input[disabled], .e-float-input textarea[disabled], .e-float-input.e-control-wrapper textarea[disabled], .e-float-input.e-disabled, .e-float-input.e-control-wrapper.e-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.75);
    color: rgba(0, 0, 0, 0.75);
}
</style>