<script setup>
import { ref, onMounted } from "vue";
import { Modal } from 'bootstrap';

let type = ref('info');
let title = ref('title')
let message = ref('message');
let dialog = ref(null);
let dialogObj = null;

onMounted(async () => {
  dialogObj = new Modal(dialog.value);
});

const _show = function(dialogType, dialogTitle, dialogMessage) {
    type.value = dialogType;
    title.value = dialogTitle;
    message.value = dialogMessage;
    dialogObj.show()
};

// eslint-disable-next-line no-undef
const emit = defineEmits(['accept']);
const accept = () => { emit('accept') };

// eslint-disable-next-line no-undef
defineExpose({show: _show});
</script>

<template>
  <div
    class="modal fade"
    ref="dialog"
    id="dialog"
    tabindex="-1"
    aria-labelledby="dialogLabel"
    aria-hidden="true"
    style="display: none"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="dialogLabel">{{ title }}</h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <i v-if="type == 'error'" class="las la-exclamation text-danger fs-1 col-1"></i>
            <i v-if="type == 'info'" class="las la-info text-primary fs-1 col-1"></i>
            <i v-if="type == 'warning'" class="las la-exclamation-triangle text-warning fs-1 col-1"></i>
            <i v-if="type == 'success'" class="las la-check text-success fs-1 col-1"></i>
            <p v-if="typeof message == 'string'" class="col mt-2 ms-3">{{ message }}</p>
            <p v-else v-for="(line, index) in message" :key="index">{{ line }}</p>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="accept">
            Aceptar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>