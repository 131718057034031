<script setup>
import { ref, onMounted, computed, watch } from "vue";
import { useRouter, useRoute } from 'vue-router';
import axios from 'axios';
import { useLoading } from 'vue-loading-overlay'
import Layout from "@/layouts/mainfw.vue";
import PageHeader from "@/components/page-header";
import { useFastWay } from "@/state/modules/fastway";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { AgGridVue } from "ag-grid-vue3";
import Dialog from '@/views/components/Dialog.vue';
import Pagination from '@/views/components/Pagination.vue';
import CellRendererCheckBox from '@/views/components/CRCheckBox';
import {DropDownListComponent} from "@syncfusion/ej2-vue-dropdowns";

const router = useRouter();
const route = useRoute();
const store = useFastWay();
const loader = useLoading();
watch(
  () => route.fullPath,
  async () => {
    router.go();
  }
);

if ('partnerType' in route.meta) {
  store.viewParameters.partners.partnerType = route.meta.partnerType;
}
let dialog = ref(null);
let pagination = ref(null);
let gridColumnApi = null;
let loaded = ref(false);
let data = ref([]);
let partnerTypes = ref([
  { id: 'c', value: 'Clientes' },
  { id: 'v', value: 'Vendedores' },
  { id: 'p', value: 'Proveedores' }
]);
let totalRowCount = ref(0);
let title = "Personas";
let urlSection = 'partners';
let urlSectionItem = 'partner';
if (store.viewParameters.partners.partnerType == 'c') {
  title = 'Clientes';
  urlSection = 'customers';
  urlSectionItem = 'customer';
} else if (store.viewParameters.partners.partnerType == 'v') {
  title = 'Vendedores';
  urlSection = 'salesmen';
  urlSectionItem = 'salesman';
} else if (store.viewParameters.partners.partnerType == 'p') {
  title = 'Proveedores';
  urlSection = 'suppliers';
  urlSectionItem = 'supplier';
}
const items = [
  {
    text: "Catálogos",
    href: "/",
  },
  {
    text: title,
    active: true,
  },
];

const api = computed({
    get() {
      return store.parameters.apiURL;
    }
});

const linkRenderer = params => {
  return `<a href="/sales/catalogs/${urlSection}/${params.data.id}" role="button">${params.value}</a>`;
}

const viewParms = computed({
  get() {
    return store.viewParameters.partners;
  }
});

viewParms.value.breadcrumb = [
  {
    text: "Catálogos",
    href: "/",
  },
  {
    text: "Vendedores",
    href: '/sales/catalogs/salesmen',
  },
  {
    text: "Vendedor",
    active: true,
  }
];

const gridHeight = computed({
    get() {
      let height = '600px'
      if (viewParms.value.pageSize < 20) {
        height = `${viewParms.value.pageSize * 29 + 25}px`;
      }
      return height;
    }
});

const columnDefs = [
  { headerName: 'ID', field: 'id', width: 120, sortable: true, cellRenderer: linkRenderer},
  { headerName: 'Nombre', field: 'name', flex: 1, sortable: true, cellRenderer: linkRenderer},
  { headerName: 'NIT', field: 'vat', width: 200, sortable: true},
  { headerName: 'Cliente', field: 'isCustomer', width: 90, sortable: true, cellRenderer: CellRendererCheckBox},
  { headerName: 'Vendedor', field: 'isSalesman', width: 90, sortable: true, cellRenderer: CellRendererCheckBox},
  { headerName: 'Proveedor', field: 'isSupplier', width: 90, sortable: true, cellRenderer: CellRendererCheckBox}
];

onMounted(async () => {
 getData();
});

const getData = async () => {
  data.value = [];
  totalRowCount.value = 0;
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Cargando personas'});
  const params = {
    userId: store.userInfo.userId,
    token: store.userInfo.token,
    limit: viewParms.value.pageSize,
    startPage: viewParms.value.currentPage,
    searchPhrase: viewParms.value.search,
    order: viewParms.value.order,
    orderDirection: viewParms.value.orderDirection,
    partnerType: viewParms.value.partnerType
  };
  await axios
  .post(`${api.value}/sale/partners/list`, params)
  .then(response => {
    if (response.data.success) {
      if (response.data.data) {
        data.value = response.data.data;
        if (response.data.data.length > 0) {
          totalRowCount.value = response.data.data[0].totalRowCount;
        }
      }
      if (gridColumnApi && viewParms.value.order != '') {
        gridColumnApi.applyColumnState({
          state: [{ colId: viewParms.value.order, sort: viewParms.value.orderDirection }],
          defaultState: { sort: null }
        });
      }
    } else {
      dialog.value.show('error', 'Error interno al cargar personas', response.data.message);
    }
    pagination.value.setTotalItems(totalRowCount.value);
    loading.hide();
    loaded.value = true;
  })
  .catch(error => {
    loaded.value = true;
    loading.hide();
    dialog.value.show('error', 'Error al cargar personas', error.message);
  });
}

const onFilterChange = () => {
  viewParms.value.currentPage = 1;
  getData();
}

const clearSearchPhrase = () => {
    viewParms.value.currentPage = 1;
    viewParms.value.search = '';
    searchPhrase();
}

const searchPhrase = () => {
    if (loaded.value) {
      viewParms.value.currentPage = 1;
      getData();
    }
}

const postSortRows = params => {
  let sortChanged = false;
  for (const col of params.columnApi.getColumnState()) {
    if (col.sort) {
      if (viewParms.value.order != col.colId) {
        viewParms.value.order = col.colId;
        sortChanged = true;
      }
      if (viewParms.value.orderDirection != col.sort) {
        viewParms.value.orderDirection = col.sort;
        sortChanged = true;
      }
    }
  }
  if (sortChanged && loaded.value) {
    getData();
  }
}

const pageSizeChange = pageSize => {
  viewParms.value.currentPage = 1;
  viewParms.value.pageSize = pageSize;
  getData();
}

const setPage = page => {
  viewParms.value.currentPage = page;
  getData();
}

const onGridReady = params => {
  gridColumnApi = params.columnApi;
}

const add = () => {
  router.push({
    name: urlSectionItem,
    params: {id: 0}
  });
}

</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
     <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center align-middle row mb-2">
                        <div class="col d-flex justify-content-start">
                          <b-button variant="outline-success" class="btn-icon waves-effect waves-light me-1" v-b-tooltip.hover title="Recargar" @click="getData">
                              <i class="las la-sync"></i>
                          </b-button>
                          <b-button variant="success" class="btn-icon waves-effect waves-light" v-b-tooltip.hover title="Agregar" @click="add">
                              <i class="las la-plus"></i>
                          </b-button>
                        </div>
                        <div class="col"/>
                        <div class="d-inline-block mr-1" style="width: 140px;">
                          <DropDownListComponent v-model:value="viewParms.partnerType" placeholder="Tipo" highlight=true :dataSource="partnerTypes" :fields="{text: 'value', value: 'id'}" :showClearButton='true' :change="onFilterChange"/>
                        </div>
                        <div class="d-inline-block" style="width: 500px;">
                          <div class="e-input-group">
                            <input type="text" v-model="viewParms.search" class="e-input" showClearButton="true" placeholder="Buscar" @keyup.enter="searchPhrase"/>
                            <span v-if="viewParms.search != ''" class="e-input-group-icon e-input-clear" @click="clearSearchPhrase"/>
                            <span class="e-input-group-icon e-input-search" @click="searchPhrase"/>
                          </div>
                        </div>
                    </div>
                    <ag-grid-vue
                        style="width: 100%;" :style="{height: gridHeight}"
                        class="ag-theme-balham"
                        :columnDefs="columnDefs"
                        :rowData="data"
                        :accentedSort="true"
                        :postSortRows="postSortRows"
                        @grid-ready="onGridReady"
                    >
                    </ag-grid-vue>
                    <Pagination ref="pagination" class="mt-2" :pageSize="viewParms.pageSize" :currentPage="viewParms.currentPage" @pageSizeChange="pageSizeChange" @pageChange="setPage"/>
                </div>
            </div>
        </div>
    </div>
    <Dialog ref="dialog"/>
  </Layout>
</template>

<style>
@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-inputs/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";

#input-container .e-input-group {
  margin: 30px 0;
}

.e-input-group-icon:before {
  font-family: e-icons;
}

.e-input-group .e-input-group-icon.e-input-clear .e-input-group-icon.e-input-search {
  font-size: 12px;
}

.e-input-group.e-small .e-input-group-icon.e-input-clear .e-input-group-icon.e-input-search {
  font-size: 12px;
}

.e-input-group-icon.e-input-clear:before {
  content: "\e7a7";
}

.e-input-group-icon.e-input-search:before {
  content: "\e993";
}
</style>