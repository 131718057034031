<script setup>
import { ref, computed } from "vue";
import { useFastWay } from "@/state/modules/fastway";
import axios from 'axios';
import Dialog from '@/views/components/Dialog.vue';

const store = useFastWay();
let email = ref('');
let loginEnabled = ref(true);
let accessSent = ref(false);
let dialog = ref(null);

const api = computed({
    get() {
      return store.parameters.apiURL;
    }
});

const sendMagicLink = async () => {
  loginEnabled.value = false;
  accessSent.value = false;
  await axios
  .post(`${api.value}/users/send_access_token`, { email: email.value })
  .then(response => {
    if (response.data.success) {
      if (response.data.data.token == '') {
        dialog.value.show('error', 'Acceso denegado', 'Usuario no registrado');
      } else { 
        accessSent.value = true;
      }
    } else {
      dialog.value.show('error', 'Acceso denegado', response.data.message);
    }
    loginEnabled.value = true;
  })
  .catch(error => {
    loginEnabled.value = true;
    dialog.value.show('error', 'Acceso denegado', error.message);
  });
};
</script>

<template>
  <!-- auth-page wrapper -->
  <!-- auth-page-wrapper auth-bg-cover -->
  <div class="
      auth-page-wrapper 
      py-5
      d-flex
      justify-content-center
      align-items-center
      min-vh-100
    ">
    <div class="bg-overlay"></div>
    <!-- auth-page content -->
    <div v-if="accessSent" class="auth-page-content overflow-hidden pt-lg-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="card overflow-hidden">
              <div class="row g-0">
                <div class="col-lg-6">
                  <div class="p-lg-5 p-2 auth-one-bg h-100">
                    <div class="bg-overlay"></div>
                    <div class="position-relative h-100 d-flex flex-column">
                      <div class="mb-4">
                        <router-link to="/" class="d-block">
                          <img src="@/assets/images/logo.png" alt="" height="70" />
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end col -->

                <div class="col-lg-6">
                  <div class="p-lg-5 p-4">
                    <div>
                      <h2 class="text-primary">FastWay Pro</h2>
                      <h5 class="text-primary">Bienvenido</h5>
                      <p class="text-muted">
                        Se envió un enlace a la dirección <b>{{ email }}</b>, por favor siga las instrucciones en el correo.
                        <br><br>
                        Si ya ha accedido puede cerrar esta pestaña.
                      </p>
                    </div>

                    <div class="mt-4">
                      <div>
                        <div class="mt-4">
                          <button class="btn btn-success w-100" @click="accessSent = false">
                            Enviar de nuevo
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end col -->
              </div>
              <!-- end row -->
            </div>
            <!-- end card -->
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end auth page content -->
    <!-- auth-page content -->
    <div v-else class="auth-page-content overflow-hidden pt-lg-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="card overflow-hidden">
              <div class="row g-0">
                <div class="col-lg-6">
                  <div class="p-lg-5 p-2 auth-one-bg h-100">
                    <div class="bg-overlay"></div>
                    <div class="position-relative h-100 d-flex flex-column">
                      <div class="mb-4">
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end col -->

                <div class="col-lg-6">
                  <div class="p-lg-5 p-4">
                    <div>
                      <div class="mb-5 w-100 d-flex justify-content-center">
                        <img src="@/assets/images/logo.png" alt="" height="90" />
                      </div>
                      <h2 class="text-primary">FastWay Pro</h2>
                      <h5 class="text-primary">Bienvenido</h5>
                      <p class="text-muted">
                        Escriba su dirección de correo electrónico registrada
                      </p>
                    </div>

                    <div class="mt-4">
                      <div>
                        <div class="mb-3">
                          <label for="email" class="form-label">Correo electrónico</label>
                          <input type="text" class="form-control" id="email" placeholder="Correo electrónico de usuario registrado"
                            v-model="email" />
                        </div>

                        <div class="mt-4">
                          <button v-if="loginEnabled" class="btn btn-success w-100" @click="sendMagicLink">
                            Acceder
                          </button>
                          <button v-else class="btn btn-success w-100">
                            <span class="d-flex align-items-center">
                              <span class="spinner-border flex-shrink-0" role="status">
                                <span class="visually-hidden">Validando acceso...</span>
                              </span>
                              <span class="flex-grow-1 ms-2">
                                Validando acceso...
                              </span>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end col -->
              </div>
              <!-- end row -->
            </div>
            <!-- end card -->
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end auth page content -->

    <!-- footer -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0">
                &copy; {{ new Date().getFullYear() }} acentoNET
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- end Footer -->
    <Dialog ref="dialog"/>
  </div>
  <!-- end auth-page-wrapper -->
</template>

<style lang="scss">
.auth-bg-cover {
  background: linear-gradient(-45deg, rgb(220, 220, 220), rgb(0, 0, 50));

  >.bg-overlay {
    background-image: url("~@/assets/images/cover-pattern.png");
    background-position: center;
    background-size: cover;
    opacity: 1;
    background-color: transparent;
  }

  .footer {
    color: rgba(white, .5);
  }
}
</style>