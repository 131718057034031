<script setup>
import { computed } from "vue";

// eslint-disable-next-line no-undef
const props = defineProps({ params: Object })

const permissions = computed({
    get() {
      return (props.params.value ? props.params.value.split(',') : []);
    }
});

const variant = item => {
  let variant = 'info';
  if (item == 'ACC') {
    variant = 'success';
  } else if (item == 'IMP') {
    variant = 'warning';
  } else if ('ELI|ANU'.includes(item)) {
    variant = 'danger';
  }
  return variant;
}
</script>

<template>
  <div>
    <span v-for="item in permissions" :key="item" class="fs-6 me-2"><b-badge :variant="variant(item)">{{ item }}</b-badge></span>
  </div>
</template>
