<script setup>
import { onMounted, computed } from "vue";
import { useFastWay } from "@/state/modules/fastway";
import { useRouter, useRoute } from 'vue-router';
import axios from 'axios';

const store = useFastWay();
const router = useRouter();
const route = useRoute();

const api = computed({
    get() {
      return store.parameters.apiURL;
    }
});

onMounted(() => {
  if (Object.prototype.hasOwnProperty.call(route, 'params')) {
    if (Object.prototype.hasOwnProperty.call(route.params, 'token')) {
      const token = route.params.token;
      if (token.value == '') {
        router.push({name: 'login'});
      } else {
        axios
          .post(`${api.value}/users/validate_token`, { token })
          .then(response => {
            if (response.data.success) {
              if (response.data.data.token == '') {
                router.push({name: 'login'});
              } else { 
                store.userInfo = {
                  uid: response.data.data.id,
                  userId: response.data.data.id,
                  login: response.data.data.login,
                  userName: response.data.data.login,
                  displayName: response.data.data.name,
                  organizationId: response.data.data.organizationId,
                  organizationName: response.data.data.organizationName,
                  token: response.data.data.token,
                };
                router.push({name: 'home'});
              }
            } else {
              router.push({name: 'login'});
            }
          })
          .catch(() => {
            router.push({name: 'login'});
          });
      }
    } else {
      router.push({name: 'login'});
    }
  } else {
    router.push({name: 'login'});
  }
});
</script>

<template>
  <!-- auth-page wrapper -->
  <!-- auth-page-wrapper auth-bg-cover -->
  <div class="
      auth-page-wrapper 
      py-5
      d-flex
      justify-content-center
      align-items-center
      min-vh-100
    ">
    <div class="bg-overlay"></div>
    <div class="auth-page-content overflow-hidden pt-lg-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="card overflow-hidden">
              <div class="row g-0">
                <div class="col-lg-6">
                  <div class="p-lg-5 p-2 auth-one-bg h-100">
                    <div class="bg-overlay"></div>
                    <div class="position-relative h-100 d-flex flex-column">
                      <div class="mb-4">
                        <router-link to="/" class="d-block">
                          <img src="@/assets/images/logo.png" alt="" height="70" />
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end col -->

                <div class="col-lg-6">
                  <div class="p-lg-5 p-4">
                    <div>
                      <div class="mb-5 w-100 d-flex justify-content-center">
                        <img src="@/assets/images/logo.png" alt="" height="90" />
                      </div>
                      <h2 class="text-primary">FastWay Pro</h2>
                      <h5 class="text-primary">Bienvenido</h5>
                      <p class="text-muted">
                        Validando acceso, un momento por favor.
                      </p>
                      <div class="mt-5 w-100 d-flex justify-content-center">
                        <span class="d-flex align-items-center">
                          <span class="spinner-border flex-shrink-0" role="status">
                            <span class="visually-hidden">Validando acceso...</span>
                          </span>
                          <span class="flex-grow-1 ms-2">
                            Validando acceso...
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end col -->
              </div>
              <!-- end row -->
            </div>
            <!-- end card -->
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>

    <!-- footer -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0">
                &copy; {{ new Date().getFullYear() }} acentoNET
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- end Footer -->
  </div>
  <!-- end auth-page-wrapper -->
</template>

<style lang="scss">
.auth-bg-cover {
  background: linear-gradient(-45deg, rgb(220, 220, 220), rgb(0, 0, 50));

  >.bg-overlay {
    background-image: url("~@/assets/images/cover-pattern.png");
    background-position: center;
    background-size: cover;
    opacity: 1;
    background-color: transparent;
  }

  .footer {
    color: rgba(white, .5);
  }
}
</style>