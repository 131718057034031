import { defineStore } from 'pinia';

export const useFastWay = defineStore('FastWayPro', {
  state: () => {
    return {
      user: null,
      userInfo: {
        id: 0,
        uid: 0,
        userId: 0,
        login: '',
        userName: '',
        displayName: '',
        image: '',
        about: '',
        photoURL: '',
        status: '',
        userRole: '',
        organizationId: 0,
        organizationName: '',
        token: ''
      },
      parameters: null,
      modules: [],
      navMenuItems: [],
      parametersFW: null,
      session: null,
      countries: [],
      states: [],
      cities: [],
      viewParameters: {
        parameters: {
          search: '',
          parameterId: 0
        },
        lists: {
          pageSize: 15,
          currentPage: 1,
          order: 'list',
          orderDirection: '',
          search: '',
          listId: 0
        },
        roles: {
          pageSize: 15,
          currentPage: 1,
          order: 'list',
          orderDirection: '',
          search: ''
        },
        users: {
          pageSize: 15,
          currentPage: 1,
          order: 'id',
          orderDirection: '',
          search: '',
          organizationId: 0,
          statusId: ''
        },
        organizations: {
          pageSize: 15,
          currentPage: 1,
          order: 'id',
          orderDirection: '',
          search: '',
          statusId: ''
        },
        locations: {
          pageSize: 15,
          currentPage: 1,
          order: 'id',
          orderDirection: '',
          search: '',
          statusId: ''
        },
        sbus: {
          pageSize: 15,
          currentPage: 1,
          order: 'id',
          orderDirection: '',
          search: ''
        },
        stores: {
          pageSize: 15,
          currentPage: 1,
          order: 'id',
          orderDirection: '',
          search: '',
          statusId: ''
        },
        measureUnits: {
          pageSize: 15,
          currentPage: 1,
          order: 'id',
          orderDirection: '',
          search: ''
        },
        warehouses: {
          pageSize: 15,
          currentPage: 1,
          order: 'id',
          orderDirection: '',
          search: '',
          locationId: 0
        },
        documentTypes: {
          pageSize: 15,
          currentPage: 1,
          order: 'id',
          orderDirection: '',
          search: '',
          functionId: ''
        },
        productCategories: {
          pageSize: 15,
          currentPage: 1,
          order: 'id',
          orderDirection: '',
          search: ''
        },
        products: {
          pageSize: 15,
          currentPage: 1,
          order: 'code',
          orderDirection: '',
          search: '',
          productTypeId: '',
          categoryId: 0,
          statusId: '',
          breadcrumb: null
        },
        stockTransactions: {
          pageSize: 15,
          currentPage: 1,
          order: 'code',
          orderDirection: '',
          search: '',
          period: null,
          warehouseId: 0,
          documentTypeId: 0,
          statusId: '',
          breadcrumb: null
        },
        stockDate: {
          pageSize: 15,
          currentPage: 1,
          order: 'code',
          orderDirection: '',
          search: '',
          date: new Date(),
          warehouseId: 0
        },
        kardex: {
          pageSize: 15,
          currentPage: 1,
          period: [new Date(), new Date()],
          productId: {
            id: 0,
            code: '',
            name: '',
            productId: 0,
            productCode: '',
            productName: ''
          },
          warehouseId: 0
        },
        saleDocumentTypes: {
          pageSize: 15,
          currentPage: 1,
          order: 'id',
          orderDirection: '',
          search: '',
          functionId: ''
        },
        felProviders: {
          pageSize: 15,
          currentPage: 1,
          order: '',
          orderDirection: '',
          search: ''
        },
        paymentMethods: {
          pageSize: 15,
          currentPage: 1,
          order: '',
          orderDirection: '',
          search: '',
          typeId: 0,
          statusId: ''
        },
        partners: {
          pageSize: 15,
          currentPage: 1,
          order: '',
          orderDirection: '',
          search: '',
          partnerType: '',
          breadcrumb: null
        },
        priceLists: {
          pageSize: 15,
          currentPage: 1,
          order: '',
          orderDirection: '',
          search: '',
          currencyId: 0,
          statusId: ''
        },
        saleOrders: {
          pageSize: 15,
          currentPage: 1,
          order: '',
          orderDirection: '',
          search: '',
          period: null,
          saleTypeId: '',
          documentTypeId: 0,
          currencyId: 0,
          salesmanId: 0,
          partnerId: 0,
          partnerName: '',
          statusId: '',
          breadcrumb: null
        },
        invoices: {
          pageSize: 15,
          currentPage: 1,
          order: 'desc',
          orderDirection: '',
          search: '',
          period: null,
          saleTypeId: '',
          documentTypeId: 0,
          currencyId: 0,
          salesmanId: 0,
          partnerId: 0,
          partnerName: '',
          statusId: '',
          breadcrumb: null
        },
        saleOrdersReport: {
          pageSize: 15,
          currentPage: 1,
          order: 'desc',
          orderDirection: '',
          search: '',
          period: [new Date(), new Date()],
          saleTypeId: '',
          documentTypeId: 0,
          currencyId: 0,
          salesmanId: 0,
          partnerId: 0,
          partnerName: '',
          statusId: '',
          breadcrumb: null
        }
      }
    }
  },
  persist: true,
  getters: {
    loggedIn: (state) => (state.userInfo.token != ''),
    getToken: (state) => state.userInfo.token
  },
  actions: {
    updateUserInfo(payload) {
      // eslint-disable-next-line no-restricted-syntax
      for (const property of Object.keys(payload)) {
        if (payload[property] != null) {
          // If some of user property is null - user default property defined in state.userInfo
          state.userInfo[property] = payload[property];
        }
      }
    }
  }
});

export const state = {
  userInfo: {
    id: 0,
    uid: 0,
    userId: 0,
    login: '',
    userName: '',
    displayName: '',
    about: '',
    image: '',
    photoURL: '',
    status: '',
    userRole: '',
    organizationId: 0,
    organizationName: '',
    token: ''
  },
  viewParameters: {
    users: {
      pageSize: 10,
      currentPage: 1,
      order: 'id',
      orderDirection: '',
      search: '',
      storeId: '',
      statusId: ''
    },
    products: {
      pageSize: 10,
      currentPage: 1,
      order: 'code',
      orderDirection: '',
      search: '',
      categoryId: '',
      statusId: ''
    },
    stores: {
      pageSize: 10,
      currentPage: 1,
      order: 'code',
      orderDirection: 'Aescending',
      search: '',
      statusId: ''
    }
  },
  modules: [],
  navMenuItems: [],
  parameters: {
    prefix: 'FastWayPro/'
  },
  parametersFW: null,
  session: null
}

export const mutations = {
  UPDATE_USER_INFO(state, payload) {
    // Get Data localStorage
    // const userInfo = JSON.parse(localStorage.getItem(`${prefix}UserInfo`)) || state.userInfo;
    const userInfo = JSON.parse(localStorage.getItem(`UserInfo`)) || state.userInfo;

    // const userInfo = localForage.getItem(`${state.parameters.prefix}UserInfo`) || state.userInfo;

    // eslint-disable-next-line no-restricted-syntax
    for (const property of Object.keys(payload)) {
      if (payload[property] != null) {
        // If some of user property is null - user default property defined in state.userInfo
        state.userInfo[property] = payload[property];

        // Update key in localStorage
        userInfo[property] = payload[property];
      }
    }
    // Store data in localStorage
    // localForage.setItem(`${state.parameters.prefix}UserInfo`, userInfo);
  },

  UPDATE_MODULES(state, value) {
    state.parameters = value;
  },

  UPDATE_PARAMETERS_FW(state, value) {
    state.parametersFW = value;
  },

  UPDATE_PARAMETERS(state, value) {
    state.parameters = value;
  },

  UPDATE_SESSION(state, value) {
    state.session = { token: value };
  },

  UPDATE_NAVMENUTITEMS(state, value) {
    state.navMenuItems = value;
  },

  UPDATE_VIEWPARAMETERS(state, payload) {
    // const {prefix} = state.parameters;

    // Get Data localStorage
    // const viewParameters = localForage.getItem(`${prefix}ViewParameters`) || state.viewParameters;
    const viewParameters = state.viewParameters;

    // eslint-disable-next-line no-restricted-syntax
    for (const property of Object.keys(payload)) {
      if (payload[property] != null) {
        state.viewParameters[property] = payload[property];

        // Update key in localStorage
        viewParameters[property] = payload[property];
      }
    }
    // Store data in localStorage
    // localForage.setItem(`${prefix}ViewParameters`, (viewParameters));
  }
}
export const actions = {

  clearUserInfo({ commit }) {
    commit('CLEAR_USER_INFO');
  },

  updateParametersFW({ commit }, value) {
    commit('UPDATE_PARAMETERS_FW', value);
  },

  updateUserInfo({ commit }, payload) {
    commit('UPDATE_USER_INFO', payload);
  },

  updateSession({ commit }, payload) {
    commit('UPDATE_SESSION', payload);
  },

  updateParameters({ commit }, value) {
    commit('UPDATE_PARAMETERS', value);
  },

  updateModules({ commit }, value) {
    commit('UPDATE_MODULES', value);
  },

  updateNavMenuItems({ commit }, value) {
    commit('UPDATE_NAVMENUTITEMS', value);
  },

  updateViewParameters({ commit }, value) {
    commit('UPDATE_VIEWPARAMETERS', value);
  }

}

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return (state.userInfo.token != '');
  }
}
