<script setup>
import { ref, computed, toRefs, defineProps, defineEmits } from "vue";
import axios from 'axios';
import { useFastWay } from "@/state/modules/fastway";
import Dialog from '@/views/components/Dialog.vue';
import {AutoCompleteComponent} from '@syncfusion/ej2-vue-dropdowns';
import {Query} from '@syncfusion/ej2-data';
import _ from 'lodash';

const emit = defineEmits(['change', 'select']);

const props = defineProps({
  product: {
      type: Object,
      required: true,
      default: () => ({
        id: 0,
        code: '',
        name: '',
        searchName: '',
        productId: 0,
        productCode: '',
        productName: '',
        productSearch: ''
      })
    },
    id: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: -1
    },
    enabled: {
      type: Boolean,
      default: false
    },
    floatLabelType: {
      type: String,
      default: 'Auto'
    },
    limit: {
      type: Number,
      default: 50
    }
});
const store = useFastWay();
const {product, enabled, limit, index} = toRefs(props);

product.value.id = product.value.productId;
product.value.name = product.value.productName.trim();
if (typeof product.value.code == 'undefined' || product.value.code == '') {
  product.value.code = product.value.productCode.trim();
}
product.value.productSearch = `${product.value.code} | ${product.value.name}`;
if (typeof product.value.searchName == 'undefined' || product.value.searchName == '') {
  if (product.value.code == '' && product.value.name == '') {
    product.value.searchName = '';
  } else {
    product.value.searchName = product.value.productSearch;
  }
}
let autocomplete = ref(null);
let dialog = ref(null);
let query = ref(new Query().select(['searchName', 'id']).take(limit.value));
let data = ref([]);

const api = computed({
    get() {
      return store.parameters.apiURL;
    }
});

const suggestionCount = computed({
    get() {
      return data.value.length;
    }
});

// onMounted(() => {
//   query.value = new Query().select(['searchName', 'id']).take(limit.value);
// });

const filteringData = e => {
  const data = {
    userId: store.userInfo.userId,
    token: store.userInfo.token,
    limit: limit.value,
    startPage: 1,
    searchPhrase: e.text,
    order: 'name'
  }
  product.value.searchData = [];
  axios.post(`${api.value}/stock/products/list`, data)
  .then(response => {
    if (response.data.success) {
      if (response.data.data) {
        product.value.searchData = JSON.parse(JSON.stringify(response.data.data));
      }
      if (e.text == '') {
        e.updateData(product.value.searchData);
      } else {
        let query = new Query().select(['searchName', 'code', 'name', 'id']);
        query = (e.text !== '') ? query.where('searchName', 'contains', e.text, true, true) : query;
        e.updateData(product.value.searchData, query);
      }
      setTimeout(() => {
        autocomplete.value.showPopup();
      }, 100)
    } else {
      dialog.value.show('error', 'Error interno al cargar listado de productos', response.data.message);
    }
  })
  .catch(error => {
    let errorMessage = '';
    if (error.message) {
      errorMessage = error.message;
    } else if (error.response) {
      errorMessage = error.response.data.ErrorMessage
    } else {
      errorMessage = 'Error de conectividad'
    }
    dialog.value.show('error', 'Error al cargar listado de productos', errorMessage);
  });
}
let filtering = ref(_.debounce(filteringData, 400));

const onProductChange = args => {
  if (args.itemData) {
    if ('code' in args.itemData) {
      product.value.productId = args.itemData.id;
      product.value.code = args.itemData.code;
      product.value.productCode = args.itemData.code;
      product.value.name = args.itemData.name;
      product.value.productName = args.itemData.name;
      product.value.productSearch = `${args.itemData.code} | ${args.itemData.name}`;
      product.value.searchName = product.value.productSearch;
    } else if ('id' in args.itemData) {
      let result = [];
      if ('searchData' in product.value) {
        result = product.value.searchData.filter(item => item.id == args.itemData.id);
      }
      if (result.length > 0) {
        product.value.productId = result[0].id;
        product.value.code = result[0].code;
        product.value.productCode = result[0].code;
        product.value.name = result[0].name;
        product.value.productName = result[0].name;
        product.value.productSearch = `${result[0].code} | ${result[0].name}`;
        product.value.searchName = product.value.productSearch;
      }
    }
  } else {
    product.value.productSearch = '';
    product.value.searchName = product.value.productSearch;
    product.value.productId = 0;
    product.value.code = '';
    product.value.productCode = '';
    product.value.name = '';
    product.value.productName = '';
  }
  product.value.index = index.value;
  emit('change', product.value);
}

const onProductSelect = args => {
  let productName = '';
  let productType = '';
  const productData = product.value.searchData.filter(item => item.id == args.itemData.id);
  if (productData.length > 0) {
    productName = productData[0].name;
    productType = productData[0].type;
  }
  emit('select', {
    id: args.itemData.id,
    productId: args.itemData.id,
    index: index.value,
    name: productName,
    type: productType
  });
}
</script>

<template>
  <AutoCompleteComponent
    ref="autocomplete"
    :value="product.searchName"
    placeholder="Seleccione producto"
    :enabled="enabled"
    :dataSource='product.searchData'
    :fields="{text: 'searchName', value: 'id'}"
    :query="query"
    allowFiltering=true
    :filtering='filtering'
    :suggestionCount="suggestionCount"
    highlight=true
    noRecordsTemplate="No se encontraron productos"
    minLength=3
    zIndex=5000
    @change="onProductChange"
    @select="onProductSelect"
  />
  <Dialog ref="dialog"/>
</template>

<style>
@import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import "../../../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
</style>
